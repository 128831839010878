import React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import AddIcon from '@mui/icons-material/Add';
import { Button, ToggleButton, ToggleButtonGroup, Typography, Tabs, Tab } from '@mui/material';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import KioskMap from './KioskMap';
import KioskTree, {Mode} from '../kiosks/KioskTree';
import { createDefaultKiosk, IKiosk, KioskState, StatusParser } from '../kiosks/Kiosks.model'
import KiosksService from '../kiosks/Kiosks.service'
import { withTranslation, WithTranslation } from 'react-i18next'
import hoistStatics from 'hoist-non-react-statics';
import KioskDetail from './KioskDetail';
import { ITag } from '../tags/Tags.model';
import TagsService from '../tags/Tags.service';
import { CRUDForm, IFormConfig } from '../../system/CRUDForm';
import snackNotifications from "../../system/SnackBarUtils";
import { Modal } from '../../system/Modal';
import { CRUDOperation } from '../../system/CRUDOperation';
import { kioskAttributes } from '../kiosks/KioskAttributes.model';
import IModule, { ModuleState } from "../../system/IModule"
import { ControllerDeployment, controllerKeys, hasController, isAuthorized, IUserContext, permissions } from '../../system/User.model'
import { AuthContext } from '../../system/Base'
import { setPageTitle } from '../../App'
import CloneConfig from '../kiosks/CloneConfig';
import { BrandingContext }  from '../../system/BrandingProvider';
import i18n from "i18next";
import LayersIcon from '@mui/icons-material/Layers';

interface IProps extends WithTranslation {

}

interface IState {
	kiosks: IKiosk[]
	tags: ITag[]
	displayedStates: KioskState[]
	displayedKiosks: IKiosk[]
	kiosksByState: Map<KioskState, number>
	selectedKiosk: IKiosk | null
	showCreateKiosk: boolean
	cloneConfigFor: IKiosk | null
	selectedMap : string | null
	areasMenuOpened : boolean
}

@IModule
class Dashboard extends React.Component<IProps, IState, WithTranslation> {
	
	protected reloadInterval: any | null = null;
	
	protected readonly kioskStates = [
		KioskState.Online, KioskState.Offline, KioskState.Warning, KioskState.Error, KioskState.Unknown
	];

	public static menu(t: ((x:string, y:any)=>string)) {
		return {
			title: (t && t(this.getLocale()+".title", {ns:this.getLocale()})) || "???",
			route: "/dashboard",
			icon: <HomeIcon />,
			weight : 0
		};
	}
	
	public static search(input : string) {
		return null;
	}

	public static getLocale(): string {
        return 'module.dashboard';
    }
	
	public static isEnabled(auth: IUserContext) { 
		if (!hasController(auth, controllerKeys.kiosk, ControllerDeployment.Global))
			return ModuleState.DISABLED;
		if (isAuthorized(auth, permissions.kiosk.read) && isAuthorized(auth, permissions.tag.read))
			return ModuleState.ENABLED;
			
		return ModuleState.NO_PERMISSIONS;
	}	
	
	constructor(props: IProps) {
		super(props);

		this.state = {
			selectedKiosk: null,
			kiosks: [],
			tags: [],
			displayedKiosks: [],
			displayedStates: [KioskState.Online, KioskState.Offline, KioskState.Warning, KioskState.Error, KioskState.Unknown],
			kiosksByState: new Map<KioskState, number>(this.kioskStates.map((state) => [state, 0])),
			showCreateKiosk: false,
			cloneConfigFor: null,
			selectedMap: null,
			areasMenuOpened: false
		};

		this.reload = this.reload.bind(this);
		this.changeDisplayedKiosks = this.changeDisplayedKiosks.bind(this);
		this.onKioskCreated = this.onKioskCreated.bind(this);
	}

	async componentDidMount() {
		setPageTitle(this.props.t( Dashboard.getLocale()+".title", { ns: Dashboard.getLocale() }));
		
		await this.reload();

		if (this.reloadInterval === null)
			this.reloadInterval = setInterval(() => this.reload(), 1 * 60 * 1000);
	}

	componentWillUnmount() {
		clearInterval(this.reloadInterval);
		this.reloadInterval = null;
	}

	getCreateKioskFormConfig(auth: IUserContext): IFormConfig<IKiosk> {
		return {
			title: this.props.t("crud.create") + ": " + this.props.t("module.kiosks.entity", { ns: "module.kiosks" }),
			onSubmit: (kiosk: IKiosk) => this.onKioskCreated(auth, kiosk),
		};
	}

	async onKioskCreated(auth: IUserContext, kiosk: IKiosk) {
		const r = await KiosksService.create(kiosk);
		this.setState({ showCreateKiosk: false });
		await this.reload();
		snackNotifications.success(this.props.t('common.request-completed'));

		if (r.id && CloneConfig.isEnabled(auth))
			this.setState({ cloneConfigFor: r });
		return r;
	}

	async reload(): Promise<void> {
		const kiosks = (await KiosksService.getAll()).data;	
		const tags = (await TagsService.getAll()).data;

		const selected = kiosks.find((k) => k.id === this.state.selectedKiosk?.id) ?? null;

		let byState = new Map<KioskState, number>(this.kioskStates.map((state) => [state, 0]));
		for (let k of kiosks) {
			const state = StatusParser.getKioskState(k.status);
			byState.set(state, (byState.get(state) ?? 0) + 1);
		}

		this.setState({
			kiosks: kiosks,
			tags: tags,
			kiosksByState: byState,
			selectedKiosk: selected
		});

		this.changeDisplayedKiosks(kiosks, this.state.displayedStates);
	}

	changeDisplayedKiosks(kiosks: IKiosk[], displayedStates: KioskState[]) {
		const displayedKiosks = kiosks.filter((kiosk) =>
			displayedStates.includes(StatusParser.getKioskState(kiosk.status)));
		
		this.setState({
			displayedStates: displayedStates,
			displayedKiosks: displayedKiosks
		});
	}

	render() {
		const kiosk = this.state.selectedKiosk;
		const t = this.props.t;
		const ns = Dashboard.getLocale();
		let createKioskFormConfig: IFormConfig<IKiosk>;

		return (<BrandingContext.Consumer>{branding => <AuthContext.Consumer>{auth => (<div className="dashboard">
		
		<Box sx={{ flexGrow: 1 }}>
			<Grid container spacing={4}>
				<Grid item container xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >					
					<Typography variant="h2">
						{ t("module.dashboard.title", {ns: "module.dashboard"}) }
					</Typography>

					<Box sx={{ display: { xs: kiosk ? 'none' : 'flex', lg: 'flex' }, flexWrap: 'wrap', justifyContent: 'flex-end', columnGap: '16px', rowGap: '16px', height: 'fit-content' }}>
						<Button disabled={!isAuthorized(auth, permissions.kiosk.create)} variant="contained" sx={{ padding: '11px 11px 12px', height: '100%' }}
							startIcon={<AddIcon />} onClick={() => this.setState({ showCreateKiosk: true })}>
							{t(`${ns}.add-kiosk`, {ns: ns}) + ''}
						</Button>
						<ToggleButtonGroup value={this.state.displayedStates} sx={{ flexWrap: 'wrap' }}
							onChange={(_e, states) => this.changeDisplayedKiosks(this.state.kiosks, states)}>
							{this.kioskStates.map((state) =>
								<ToggleButton key={state} value={state} sx={{ flexGrow: 1 }} className={'kiosk-toggle ' + state}>
									{t(`${ns}.kiosk-${state}`, {ns: ns}) + `: ${this.state.kiosksByState.get(state)}`}
								</ToggleButton>
							)}
						</ToggleButtonGroup>
					</Box>
				</Grid>

				<Grid item xs={12} md={kiosk ? 12 : 8} lg={8} xl={9} sx={{ display: "flex", justifyContent: "center" }}>
					{
						this.state.selectedKiosk === null &&
						(
							<>
							{ branding.currentBranding.maps && branding.currentBranding.maps.length && branding.currentBranding.maps.length > 1 &&
							<div className="sidebar-areas">
							
							<ToggleButton
								value="Open areas"
								selected={this.state.areasMenuOpened}
								onChange={() => {
									this.setState({areasMenuOpened : !this.state.areasMenuOpened});
								}}
							>
							<LayersIcon />
							</ToggleButton>
							
							{ this.state.areasMenuOpened &&
								<Tabs value={this.state.selectedMap || branding.currentBranding.maps[0].id} orientation="vertical"
									onChange={ (e, val)=>this.setState({selectedMap : val}) }>
									{  branding.currentBranding.maps.map((map : any) => {
										return (<Tab key={map.id} value={map.id} label={map["desc["+ i18n.language +"]"] || map["desc"] || map["desc[en]"] || map.id} />);
									
									}) }
								</Tabs>
							}
							</div>
							}
							<KioskMap kiosks={this.state.displayedKiosks} mapId={this.state.selectedMap || undefined}
								onAreaClick={(id: string) => { this.setState({selectedMap : id}) }}/>
							</>
						)
					}
					
					{
						kiosk && <KioskDetail
							kiosk={kiosk}
							kiosks={this.state.displayedKiosks}
							onClose={() => this.setState({selectedKiosk : null})}
							onRequestReload={this.reload} />
					}
				</Grid>
				
				<Grid item xs={12} md={4} xl={3} sx={{ display: { xs: kiosk ? 'none' : 'block', lg: 'block' }}}>
					<KioskTree 
						paged="globalDashboard"
						mode={Mode.Kiosks}
						tags={this.state.tags}
						kiosks={this.state.displayedKiosks}
						onKioskClick={(async id=>{
							let kiosk = await KiosksService.getById(id);
							this.setState({selectedKiosk : kiosk});
						})}
					/>
				</Grid>
			</Grid>
		</Box>

		{this.state.showCreateKiosk && (createKioskFormConfig = this.getCreateKioskFormConfig(auth)) &&
            <Modal title={createKioskFormConfig.title} isOpen={this.state.showCreateKiosk} onClose={() => this.setState({ showCreateKiosk: false })}>
                <CRUDForm {...createKioskFormConfig}
                    entity={createDefaultKiosk()}
                    role={CRUDOperation.Create}
                    attributes={kioskAttributes} />
            </Modal>}

		{this.state.cloneConfigFor &&
            <Modal title={t(`module.kiosks.clone-config`, {ns: 'module.kiosks'})} isOpen={true} onClose={() => this.setState({ cloneConfigFor: null })}>
                <CloneConfig kiosk={this.state.cloneConfigFor} onSubmit={() => this.setState({ cloneConfigFor: null })} />
            </Modal>}
		
		</div>)}</AuthContext.Consumer>}</BrandingContext.Consumer>);
	}
}

export default hoistStatics(withTranslation()(Dashboard), Dashboard)