import React, { HTMLAttributes, ReactNode } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { BrowserRouter, Route, Routes, NavLink, Navigate } from 'react-router-dom';

// Import GUI:
import { styled, alpha, Theme, CSSObject } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MoreIcon from '@mui/icons-material/MoreVert';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItemIcon from '@mui/material/ListItemIcon';
import InvertColorsIcon from '@mui/icons-material/InvertColors';
import LanguageIcon from '@mui/icons-material/Language';
import { Autocomplete } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import SettingsIcon from '@mui/icons-material/Settings';
import Divider from '@mui/material/Divider';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import CheckIcon from '@mui/icons-material/Check';
import KeyIcon from '@mui/icons-material/Key';
import HelpIcon from '@mui/icons-material/Help';
import MiniLogo from "../digipanel-logo.png";

import CssBaseline from '@mui/material/CssBaseline';
import { AppBar, IconButton, Toolbar, Typography, InputBase, Box, Menu, MenuItem, Popper,
	Drawer, List, ListItemButton, ListItemText, AppBarProps as MuiAppBarProps, AutocompleteRenderOptionState } from '@mui/material';
import { CustomThemeContext }  from './CustomThemeProvider';

import Page404 from './Page404';
import Page405 from './Page405';
import { UserContext, IUserContext } from './User.model'
import http, { BE_ROOT } from './Communicator';

import { ISearchResult } from './SearchResult.model';
import { ModuleState } from "./IModule"
import { Modal } from './Modal';
import PasswordChange from '../modules/users/PasswordChange';

import { StatusParser } from '../modules/kiosks/Kiosks.model';

interface IState {
	anchorElement : any
	mobileMoreAnchorElement : any
	languageAnchorElement : any
	sidebarOpened : boolean
	currentLanguage : string
	
	searchResults : any
	searchOpened : boolean,
	navigateFromSearch : string,
	
	passwordFormShown : boolean
	aboutFormShown : boolean

	authUser : IUserContext
}

interface IProps extends WithTranslation {
	modules: any[],
	setUser : (x: any | null) => void,
	setLanguage : (x: string) => void,
	currentLanguage : string,
	children?: React.ReactNode,	
	
	userName : string,
	userID : number,
}

interface AppBarProps extends MuiAppBarProps {
	open?: boolean;	
}

const drawerWidth = 240;
		
const StyledDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
	({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	justifyContent: 'space-between',
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	...(open && {
	...openedMixin(theme),
	'& .MuiDrawer-paper': openedMixin(theme),
	}),
	...(!open && {
	  ...closedMixin(theme),
	  '& .MuiDrawer-paper': closedMixin(theme),
	}),
	}),
);

const StyledMobileDrawer = styled(Drawer, { shouldForwardProp: () => true })(
	{
		width: drawerWidth,
		flexShrink: 0,
		justifyContent: 'space-between',
		whiteSpace: 'nowrap',
		boxSizing: 'border-box',
		'& .MuiDrawer-paper': {
			boxSizing: 'border-box',
			width: drawerWidth
		},
	},
);

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
});
const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: 0,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
});
const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
	easing: theme.transitions.easing.sharp,
	duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
	marginLeft: drawerWidth,
	width: `calc(100% - ${drawerWidth}px)`,
	transition: theme.transitions.create(['width', 'margin'], {
	  easing: theme.transitions.easing.sharp,
	  duration: theme.transitions.duration.enteringScreen,
	}),
  }),
}));

const Search = styled('div')(({ theme }) => ({
	position: 'relative',
	borderRadius: theme.shape.borderRadius,
	backgroundColor: alpha(theme.palette.common.white, 0.15), '&:hover': {
		backgroundColor: alpha(theme.palette.common.white, 0.25),
	},
	marginRight: theme.spacing(1),
	marginLeft: 0,
	[theme.breakpoints.up('sm')]: {
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(2),
		width: 'auto',
	},
	flexGrow: 1,
	flexShrink: 0,
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
	padding: theme.spacing(0, 2),
	height: '100%',
	position: 'absolute',
	pointerEvents: 'none',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
	color: 'inherit', '& .MuiInputBase-input': {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)})`,
		transition: theme.transitions.create('width'),
		[theme.breakpoints.up('md')]: {
			width: '20ch',
		},
	},
}));

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
	justifyContent: 'flex-end',
	alignSelf: 'flex-end',
	position: 'sticky',
	top: 0,
	right: 0,
	width: 'min-content',
	zIndex: 100,
}));

export const AuthContext = React.createContext(new UserContext());

class Base extends React.Component<IProps, IState, WithTranslation> {

	lastSearch : string;
	searchWaitTimer : any; // Can be different type for different browsers. Return type of setTimeout.
	
	constructor(props: IProps){		
		super(props);				
		this.state = {
			anchorElement : null,
			mobileMoreAnchorElement : null,
			languageAnchorElement : null,
			sidebarOpened : false,
			currentLanguage : this.props.currentLanguage || "en",
			searchResults : [],
			searchOpened : false,
			navigateFromSearch : "",

			passwordFormShown : false,
			aboutFormShown : false,
			
			authUser : {
				name : this.props.userName,
				id : this.props.userID,
				controllers : [],
				permissions : []
			}
		};
		this.handleMenuClose = this.handleMenuClose.bind(this);
		this.handleMenuOpen = this.handleMenuOpen.bind(this);
		this.handleMobileMenuOpen = this.handleMobileMenuOpen.bind(this);
		this.handleMobileMenuClose = this.handleMobileMenuClose.bind(this);
		this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
		this.handleDrawerClose = this.handleDrawerClose.bind(this);
		this.handleLangMenuOpen = this.handleLangMenuOpen.bind(this);
		this.handleLanguageChange = this.handleLanguageChange.bind(this);
		this.renderSearchResult = this.renderSearchResult.bind(this);
		this.renderDrawer = this.renderDrawer.bind(this);
		this.searchPopper = this.searchPopper.bind(this);
		
		this.lastSearch = "";
		this.searchWaitTimer = null;
		
			
	}
	
	async loadControllers() {
		// We can't use /api/v1 here, this is the only route without a version so we need to get
		// the path manually:
		let ctrls = await http.get(BE_ROOT + "/api/controllers");
		this.setState({ authUser : { ...this.state.authUser, controllers : ctrls.data  } });
	}
	
	async loadPermissions() {
		let perm = null;
		if (this.props.userID !== 1)
			// Specific permissions
			perm = await http.get("/auth/user-permissions/" + this.props.userID);
		else
			// All permissions
			perm = await http.get("/auth/permissions");
		this.setState({ authUser : { ...this.state.authUser, permissions : perm.data  } });
	}
	
	async componentDidMount() {
		await this.loadControllers(); // We need to await because of race condition.
		await StatusParser.loadServiceTimeFromServer();
		this.loadPermissions();
	}
	
	componentWillUnmount() {
		if (this.searchWaitTimer !== null) {
			clearTimeout(this.searchWaitTimer);
			this.searchWaitTimer = null;
		}
	}
	
	handleMenuClose(e : any, after : any = null) {
		if (typeof(after) === "function")
			this.setState({anchorElement : null, languageAnchorElement : null}, after);
		else
			this.setState({anchorElement : null, languageAnchorElement : null});
		this.handleMobileMenuClose(e);
	};
	handleMenuOpen(e : any) {
		this.setState({anchorElement : e.currentTarget});
	};
	
	handleLangMenuOpen(e: any) {
		this.setState({languageAnchorElement : e.currentTarget});
	}
	
	handleMobileMenuOpen(e: any) {
		this.setState({mobileMoreAnchorElement : e.currentTarget});
	}
	handleMobileMenuClose(e : any) {
		this.setState({mobileMoreAnchorElement : null});
	};
	
	handleDrawerOpen = (e : any) => {
		this.setState({sidebarOpened : true});
	};

	handleDrawerClose = (e : any) => {
		this.setState({sidebarOpened : false});
	};
	
	handleLanguageChange(e: any, lang: string)
	{
		this.props.setLanguage(lang);
		this.setState({ currentLanguage: lang });
		this.handleMenuClose(e);
	}
	
	async setSearchResultsOfModule(name : string, leaveLoading : boolean, newSet : any = [])
	{
		/*let promisedSetState = (newState:any) => new Promise((resolve:any) => this.setState(newState, resolve));
		let arr = [];
		if (leaveLoading) {
			arr = [...(this.state.searchResults.filter((x:any) => x.module != name)), { module : name, isLoading : true, label : "loading" } ];
		}
		else {
			arr = [...(this.state.searchResults.filter((x:any) => x.module != name)), ...newSet];
		}
		
		arr.sort((a, b) : number => { return a.module.localeCompare(b.module) || a.label.localeCompare(b.label) })
		await promisedSetState({ searchResults : arr })*/
		
		this.setState((prevState) => {
			
			let arr = [];
			if (leaveLoading) {
				arr = [...(prevState.searchResults.filter((x:any) => x.module !== name && x.module !== "")), { module : name, isLoading : true, label : "loading" } ];
			}
			else {
				arr = [...(prevState.searchResults.filter((x:any) => x.module !== name && x.module !== "")), ...newSet];
			}
			
			arr.sort((a, b) : number => { return a.module.localeCompare(b.module) || a.label.localeCompare(b.label) })
			
			if (arr.length === 0) {
				arr = [ { module : "", noResults : true, label : "no results" } ];
			}
			
			return { searchResults : arr };
		});
	}
	
	async search(text : string,  authContext : IUserContext, t:any)
	{
		
		for (var index = 0; index < this.props.modules.length; index++) {
			var mod = this.props.modules[index];
			if (mod.isEnabled(authContext) === ModuleState.ENABLED) {
				// Different modules can have different speed. We want to trigger
				// search simultaneously everywhere, it's better than to wait 10 seconds
				// for the first one just to then load the others imidiately, if unlucky.
				let x = mod.search(text);
				let modTitle = mod.menu(t).title;
				
				if (x !== null && x.then) {
					await this.setSearchResultsOfModule(modTitle, true);
					// We have a promise. So we should prepare container for this module and
					// we should indicate that this module is still retrieving something.
					x.then(async (result : ISearchResult[] | null) => {
						let arr = [];
						if (result !== null) {
							for (let i = 0; i < result.length; i++) {
								let r = result[i];
								let obj = {
									module : modTitle,
									label : r.title,
									description : r.description,
									icon : r.icon,
									link : r.link,
									customClick : r.customClick
								}
								arr.push(obj);
							}
						}
						await this.setSearchResultsOfModule(modTitle, false, arr);
					})
				}
			}
		};
	}
	
	searchPreview(text: string, authContext : IUserContext, t:any)
	{
		if (this.lastSearch !== text)
		{
			this.lastSearch = text;
			if (this.searchWaitTimer !== null) {
				clearTimeout(this.searchWaitTimer);
			}
			this.searchWaitTimer = setTimeout(()=>{
				this.search(text, authContext, t);
			}, 500);
		}
	}
	
	renderSearchResult(props : HTMLAttributes<HTMLLIElement>, option : any, state : AutocompleteRenderOptionState) : ReactNode {
		/*
			title : string,
			description? : string,
			icon? : SvgIconComponent,
			link? : string,
			customClick? : ()=>void,
		*/
		
		let comp : ReactNode = null;
		if (option.isLoading) 
			comp = <div key={"shut-the-fuck-up-react-no-error-here"}><CircularProgress /></div>;
		else if (option.noResults)
			comp = <div key={"shut-the-fuck-up-react-no-error-here"}><span className="search-no-results">{this.props && this.props.t("search.no-results")}</span></div>;
		else
			comp = <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.id + "-" + Math.random()}>						
			<div className="search-result">				
				<table><tbody><tr><td>
					<div className="search-icon-box">{option.icon || <SettingsIcon />}</div>
				</td><td>
					<div className="search-result-title">{option.label}</div>
					<div className="search-result-desc">{option.description}</div>
				</td></tr></tbody></table>
			</div>
		</Box>;
	
		return comp;
	}
	
	searchPopper(props : any) {
		return (<Popper {...props} className={"search-dropdown " + (this.state.sidebarOpened ? "sidebar-opened" : "sidebar-closed")} /*placement='bottom-start'*/ />)
	}
	
	searchClick(obj : any) {
		if (obj.customClick)
			obj.customClick();
		if (obj.link) {
			
			this.setState({navigateFromSearch : obj.link}, () => {
				// We should be redirected exactly once so now we need to remove the property
				// so that it doesn't cycle the redirect for no reason.
				this.setState({navigateFromSearch : ""});
			})
		}
	}

	renderDrawer(temporaryDrawer: boolean) {
		const t = this.props.t;
		
		return <>
			<DrawerHeader>
				<IconButton onClick={this.handleDrawerClose}>
					<ChevronLeftIcon />
				</IconButton>
			</DrawerHeader>
			<div style={{ marginBottom: "40px", flexGrow: 1 }}>
			<List>
				{this.props.modules.map((btn : any, index: number) => btn.isEnabled
					&& btn.isEnabled(this.state.authUser) !== ModuleState.DISABLED
					&& (<NavLink className={({ isActive }) => "nav-link" + (isActive ? " activated" : "") + (btn.isEnabled(this.state.authUser) === ModuleState.NO_PERMISSIONS ? " disabled" : "")}
					to={btn.menu(t).route} onClick={temporaryDrawer ? this.handleDrawerClose : () => {}} key={index}>
					
							<ListItemButton sx={{
								minHeight: 48,
								justifyContent: this.state.sidebarOpened ? 'initial' : 'center',
								px: 2.5,
							}}>
								<ListItemIcon sx={{ minWidth: 0, mr: this.state.sidebarOpened ? 3 : 'auto', justifyContent: 'center' }}>
									{ btn.menu(t).icon }
								</ListItemIcon>
								<ListItemText primary={btn.menu(t).title} sx={{ opacity: this.state.sidebarOpened ? 1 : 0 }} />
							</ListItemButton>
						</NavLink>
				)
				)}
			</List>
			</div>
			<div>
			  <Divider />
				<ListItemButton sx={{
					minHeight: 48,
					justifyContent: this.state.sidebarOpened ? 'initial' : 'center',
					px: 2.5,
				}} onClick={()=>{ this.setState({aboutFormShown : true}); }}>
					<ListItemIcon sx={{ minWidth: 0, mr: this.state.sidebarOpened ? 3 : 'auto', justifyContent: 'center' }}>
						<HelpIcon />
					</ListItemIcon>
					<ListItemText primary={t("common.about", {ns:'common'})} sx={{ opacity: this.state.sidebarOpened ? 1 : 0 }} />
				</ListItemButton>
			</div>
		</>;
	}
	
	render() {	
		const t = this.props.t;
		
		const menuId = 'primary-search-account-menu';
		const langMenuId = 'primary-language-menu';
		const mobileMenuId = 'primary-search-account-menu-mobile';		
		
		const firstInSidebar = this.props.modules.find( (page:any) => page.isEnabled && page.isEnabled(this.state.authUser) === ModuleState.ENABLED );

		// ========== ========== ==========
		return(<AuthContext.Provider value={ this.state.authUser }><CustomThemeContext.Consumer>{ theme => (
		<BrowserRouter><Box sx={{ display: 'flex' }}>		
		<CssBaseline />
		
		{ this.state.navigateFromSearch && <Navigate to={this.state.navigateFromSearch} /> }
		
		<StyledAppBar position="fixed" open={this.state.sidebarOpened}>
			<Toolbar>
				<IconButton	size="large" edge="start" color="inherit" aria-label="menu" onClick={this.handleDrawerOpen}	sx={{
					marginRight: 1,
					flexShrink: 0,
					...(this.state.sidebarOpened && { display: 'none' }),
					}}>
					<MenuIcon />
				</IconButton>
				<Typography variant="h6" noWrap component="div" sx={{ display: { xs: 'none', sm: 'block' } }}>
					DigiPanel
				</Typography>
				<Search className="search-main-wrapper">
					
					<SearchIconWrapper><SearchIcon /></SearchIconWrapper>
					{ /* Search is an uncontrolled component because we need to do some shennigans with timeouts anyway.
						 It's easier to react to changes ourselves and trigger or refresh timeouts for when user is not typing. */ }				
						
					<Autocomplete renderInput={(params)=>{
						const {InputLabelProps,InputProps,...rest} = params;
						return <StyledInputBase placeholder={t("search.placeholder")} {...params.InputProps} {...rest}
						onKeyPress={(e ) => { this.searchPreview((e.target as HTMLInputElement).value, this.state.authUser, t) }}
						onKeyUp={(e ) => { this.searchPreview((e.target as HTMLInputElement).value, this.state.authUser, t) }}
						onChange={(e ) => { this.searchPreview((e.target as HTMLInputElement).value, this.state.authUser, t) }}
						/>}}
							className={"search-autocomplete"}
							options={this.state.searchResults}
							
							groupBy={(option) => option.module}
							handleHomeEndKeys={false}
							
							onInputChange={(event, newValue) => {
								if (newValue.length > 0) {
									this.setState({searchOpened : true});
								} else {
									this.setState({searchOpened : false});
								}
							}}
							
							onChange={(event, newValue) => {
								this.searchClick(newValue);
							}}
							
							open={this.state.searchOpened}

							renderOption={ this.renderSearchResult }
							filterOptions={x => x}
							popupIcon={""}
							disableClearable
							freeSolo
							// @ts-ignore
							onClose={() => { this.setState({searchResults : [], searchOpened : false})}}
							PopperComponent={this.searchPopper}
							ListboxProps={ { style: { maxHeight: 'calc(100vh - 150px)' }  } }
							/>
					
				</Search>
				<Box sx={{ display: { xs: 'none', md: 'flex' } }}>
					<IconButton size="large" aria-label="switch theme" color="inherit"
						onClick={ ()=>{theme.setTheme(
										{
											'mode' : theme.currentTheme.mode === "dark" ? "light" : "dark",
											'branding' : theme.currentTheme.branding
										});} }>
							<InvertColorsIcon />
					</IconButton>
					<IconButton size="large" aria-label="switch language" color="inherit" aria-controls={langMenuId} aria-haspopup="true"
						onClick={this.handleLangMenuOpen}>
							<LanguageIcon />
					</IconButton>
					<IconButton size="large" edge="end" aria-label="account of current user" aria-controls={menuId} aria-haspopup="true"
						onClick={this.handleMenuOpen} color="inherit">
						<AccountCircle />
					</IconButton>
				</Box>
				<Box sx={{ display: { xs: 'flex', md: 'none' }, flexShrink: 0 }}>
					<IconButton edge='end' size="large" aria-label="show more" aria-controls={mobileMenuId} aria-haspopup="true" 
						onClick={this.handleMobileMenuOpen} color="inherit">
						<MoreIcon />
					</IconButton>
				</Box>
			</Toolbar>
		</StyledAppBar>
		
		{/* Mobile menu */}
		<Menu anchorEl={this.state.mobileMoreAnchorElement} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} id={mobileMenuId} keepMounted
			transformOrigin={{ vertical: 'top', horizontal: 'right' }} open={Boolean(this.state.mobileMoreAnchorElement)} onClose={this.handleMobileMenuClose}>
			<MenuItem onClick={ (e)=>{theme.setTheme(
						{
							'mode' : theme.currentTheme.mode === "dark" ? "light" : "dark",
							'branding' : theme.currentTheme.branding
						}); this.handleMenuClose(e); } }>
				<IconButton size="large" aria-label="switch theme" color="inherit">
							<InvertColorsIcon />
					</IconButton>
				<p>{t("theme.switch")}</p>
			</MenuItem>
			<MenuItem onClick={this.handleLangMenuOpen}>
				<IconButton size="large" aria-label="switch language" aria-controls="primary-language-menu" aria-haspopup="true" color="inherit">
					<LanguageIcon />
				</IconButton>
				<p>{t("menu.language")}</p>
			</MenuItem>
			<MenuItem onClick={this.handleMenuOpen}>
				<IconButton size="large" aria-label="account of current user" aria-controls="primary-search-account-menu" aria-haspopup="true" color="inherit">
					<AccountCircle />
				</IconButton>
				<p>{t("menu.profile")}</p>
			</MenuItem>
		</Menu>
		
		{/* Classical menu */}
		<Menu anchorEl={this.state.anchorElement} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} id={menuId} keepMounted
			transformOrigin={{ vertical: 'top', horizontal: 'right' }} open={Boolean(this.state.anchorElement)} onClose={this.handleMenuClose}>
			<MenuItem className="menu-label">
				<ListItemIcon><PersonIcon /></ ListItemIcon>
				{this.props.userName}
			</MenuItem>
			<Divider />
			<MenuItem onClick={() => this.setState({ passwordFormShown: true })}>
				<ListItemIcon><KeyIcon /></ListItemIcon>
				{t('module.users.change-password', {ns: 'module.users'})}
			</MenuItem>
			<MenuItem onClick={(e)=>{ this.handleMenuClose(e, ()=>{ setTimeout( () => { this.props.setUser(null); }, 100 ); }); }}>
				<ListItemIcon><LogoutIcon /></ListItemIcon>
				{t('logout.submit')}
			</MenuItem>			
		</Menu>
		<Menu anchorEl={this.state.languageAnchorElement} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} id={langMenuId} keepMounted
			transformOrigin={{ vertical: 'top', horizontal: 'right' }} open={Boolean(this.state.languageAnchorElement)} onClose={this.handleMenuClose}>
			<MenuItem selected={this.state.currentLanguage === "en"} onClick={(e)=>{  this.handleLanguageChange(e, "en"); }} value="en"><ListItemIcon>{this.state.currentLanguage === "en" && <CheckIcon />}</ListItemIcon>English</MenuItem>
			<MenuItem selected={this.state.currentLanguage === "cs"} onClick={(e) => { this.handleLanguageChange(e, "cs"); }} value="cs"><ListItemIcon>{this.state.currentLanguage === "cs" && <CheckIcon />}</ListItemIcon>Čeština</MenuItem>
			<MenuItem selected={this.state.currentLanguage === "sk"} onClick={(e) => { this.handleLanguageChange(e, "sk"); }} value="sk"><ListItemIcon>{this.state.currentLanguage === "sk" && <CheckIcon />}</ListItemIcon>Slovenčina</MenuItem>
		</Menu>

		{this.state.passwordFormShown &&
			<Modal title={t("module.users.change-password", {ns:'module.users'})} isOpen={true} onClose={() => this.setState({ passwordFormShown: false })}>
                <PasswordChange userId={this.state.authUser.id} onDone={() => this.setState({ passwordFormShown: false })}/>
			</Modal>}
		
		{this.state.aboutFormShown &&
			<Modal title={t("common.about", {ns:'common'})} isOpen={true} onClose={() => this.setState({ aboutFormShown: false })}>
				<div className="about-page">
					<div className="login-about">
						<img className="mini-logo-powered-by" src={MiniLogo} alt="DigiPanel logo" style={{ maxWidth: '100%' }} />
						<Typography className="mega-title" color="textSecondary">{ t("login.powered-by") }</Typography>
						<Typography className="mega-title" color="textSecondary">{ t("login.developed-by") }</Typography>
					</div>	
				</div>
			</Modal>}
		
		{/* ================================================= Sidebar ================================================= */}
		<StyledDrawer variant="permanent" open={this.state.sidebarOpened} ModalProps={{ keepMounted: true }} sx={{ display: { xs: 'none', md: 'block' }}}>
			{this.renderDrawer(false)}	
    	</StyledDrawer>
		<StyledMobileDrawer variant="temporary" open={this.state.sidebarOpened} onClose={this.handleDrawerClose} ModalProps={{ keepMounted: true }} sx={{ display: { xs: 'block', md: 'none' }}}>
			{this.renderDrawer(true)}	
    	</StyledMobileDrawer>
	  
	  <Box component="main" sx={{ flexGrow: 1, p: 3, overflow: 'hidden', height: '100%' }}>
        <DrawerHeader />		
			{this.state.authUser.permissions.length > 0 && <Routes>
				{
				this.props.modules.map((page : any) => (( page.isEnabled && page.isEnabled(this.state.authUser) === ModuleState.ENABLED && (
					<Route key={page} path={page.menu(t).route} element={React.createElement(page)} />
				)) || (( page.isEnabled && page.isEnabled(this.state.authUser) === ModuleState.NO_PERMISSIONS && (
					<Route key={page} path={page.menu(t).route} element={(<Page405 />)} />
				)))
				))}
				{ firstInSidebar &&
					<Route path="/" element={<Navigate to={ firstInSidebar.menu(t).route } />} />
				}
				<Route path="*" element={(<Page404 />)} />
			</Routes>}
      </Box>
		
	</Box></BrowserRouter>)}</CustomThemeContext.Consumer></AuthContext.Provider>)
	}
}

export default withTranslation()(Base);