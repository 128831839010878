import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Button, TextField, Typography, Alert, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { BrandingContext }  from './BrandingProvider';
import { CustomThemeContext }  from './CustomThemeProvider';

import UsersService from '../modules/users/Users.service';
import MiniLogo from "../digipanel-logo.png";
import { setPageTitle } from '../App'

import PersonIcon from '@mui/icons-material/Person';
import KeyIcon from '@mui/icons-material/Key';
import LanguageIcon from '@mui/icons-material/Language';
import InvertColorsIcon from '@mui/icons-material/InvertColors';
import { InputAdornment } from '@mui/material';

interface IProps extends WithTranslation {
	setUser : (x: any | null) => void;
	setLanguage : (x: string) => void;
	currentLanguage : string;
	firstTime : boolean;
}

interface IState {
	loginError?: string | null;
	username?: string;
	password?: string;
	loaded: boolean;
	currentLanguage : string;
}

class Login extends React.Component<IProps, IState, WithTranslation> {

	autoFocusElement : any;
	
	constructor(props: IProps){
		super(props);				
		this.state = {
			loginError: null,
			username: "",
			password: "",
			loaded: false,
			currentLanguage : this.props.currentLanguage || "en"
		};
		
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleLanguageChange = this.handleLanguageChange.bind(this);
	}
	
	async loginUser(username: string, password: string) : Promise<any | null> {
	
		var x = await UsersService.login(username, password);
		
		if (x) {
			//return x.token;
			return x;
		} else {
			return null;
		}
	}
	
	async handleSubmit(e : React.FormEvent<HTMLFormElement>)
	{
		e.preventDefault();
		const usr = await this.loginUser(this.state.username!, this.state.password!);
		this.props.setUser(usr);
		if (!usr)
			this.setState({ loginError: "login.incorrect", password: "" });
	}
	
	async handleLanguageChange(lang: string)
	{
		this.props.setLanguage(lang);
		this.setState({ currentLanguage: lang });
	}
	
	componentDidMount() {
		setPageTitle("");
		
		setTimeout(()=>{
			this.setState({loaded: true});
		}, 100);
		setTimeout(()=>{
			this.autoFocusElement.focus();
		}, 500);
	}

	render() {			
		const t = this.props.t;
		
		return (
		<CustomThemeContext.Consumer>{ theme => (
		<BrandingContext.Consumer>{ branding => ( 
			<div className={this.state.loaded?'dp2-login loaded':'dp2-login'}>
				{ /* We are not going to import the image. We need lazy loading for branding. */ }
				<img className="logo" src="./branding/login-logo.png" alt="logo" />
				<div className="dp2-login-animation-container">
				<div className="login-form">
					<div className="main-form">
						<Typography className="mega-title" color="textPrimary" variant="h1">{
						
							this.props.firstTime?
								branding.t("login_title", "login.welcome") :
								branding.t("relog_title", "login.again")
						}</Typography><br />
						<Typography className="instructions" color="textPrimary" variant="h2">{t('login.instructions')}</Typography><br /><br />
						<form onSubmit={this.handleSubmit} className="login-form-c">
							<div>
								<label>
									<TextField InputProps={{
									startAdornment: (
										<InputAdornment position="start">
										<PersonIcon />
										</InputAdornment>
									),
									}}
									className="" type="text" value={this.state.username} label={t('login.username')}
									onChange={e => this.setState({ username: e.target.value, loginError: null })} inputRef={x=>this.autoFocusElement = x} />
								</label>
							</div>
							<div>
								<label>
									<TextField InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<KeyIcon />
										</InputAdornment>
									),
									}}
									type="password" value={this.state.password} label={t('login.password')}
									onChange={e => this.setState({ password: e.target.value, loginError: null })} />
								</label>
							</div>
							<div style={{display: (this.state.loginError)? 'initial' : 'none'}}>
								<Alert severity="error">
									{ t(this.state.loginError!) }
								</Alert>
							</div>
							<div>
								<Button variant="contained" type="submit">
									{ t("login.submit") }
								</Button>
							</div>
						</form>
						
						{ branding.t("login_instructions", "") &&
						<div className="custom-login-instruction">
							<Typography color="textSecondary">
								<div dangerouslySetInnerHTML={{ __html: branding.t("login_instructions", "") }}>
								
								</div>
							</Typography>
						</div>
						}
					</div>
					
					<div className="other-options">						
						<table width="100%"><tbody><tr><td width="50%">
							<FormControl fullWidth className="combo">
								<InputLabel id="lang-label">{t("login.language")}</InputLabel>
								<Select label={t("login.language")} labelId="lang-label" value={this.state.currentLanguage}
									onChange={(e)=>{this.handleLanguageChange(e.target.value);}}
									
									startAdornment={
										<InputAdornment position="start">
											<LanguageIcon />
										</InputAdornment>
									}
								
									MenuProps={{
									  anchorOrigin: {
										vertical: "top",
										horizontal: "left"
									  },
									  transformOrigin: {
										vertical: "bottom",
										horizontal: "left"
									  }
									}}>
									<MenuItem value="en">English</MenuItem>
									<MenuItem value="cs">Čeština</MenuItem>
									<MenuItem value="sk">Slovenčina</MenuItem>
								</Select>
							</FormControl>
						</td><td>
							<FormControl fullWidth className="combo">
								<InputLabel id="theme-label">{t("login.theme")}</InputLabel>
								<Select label={t("login.theme")} labelId="theme-label" value={theme.currentTheme.mode}
									onChange={(e)=>{
									theme.setTheme(
										{
											'mode' : e.target.value,
											'branding' : theme.currentTheme.branding
										}
									);}}
									
									startAdornment={
										<InputAdornment position="start">
											<InvertColorsIcon />
										</InputAdornment>
									}
									
									MenuProps={{
									  anchorOrigin: {
										vertical: "top",
										horizontal: "left"
									  },
									  transformOrigin: {
										vertical: "bottom",
										horizontal: "left"
									  }
									}}>
									<MenuItem value="light">{t("theme.light")}</MenuItem>
									<MenuItem value="dark">{t("theme.dark")}</MenuItem>
								</Select>
							</FormControl>
						</td></tr></tbody></table>						
						<div className="login-about">
							<img className="mini-logo-powered-by" src={MiniLogo} alt="DigiPanel logo" />
							<Typography className="mega-title" color="textSecondary">{ t("login.powered-by") }</Typography>
							<Typography className="mega-title" color="textSecondary">{ t("login.developed-by") }</Typography>
						</div>						
					</div>				
				</div>
				</div>
			</div>
		)}</BrandingContext.Consumer>
		)}</CustomThemeContext.Consumer>
		);
	}
}

export default withTranslation()(Login);