import React, { Fragment } from 'react';
import hoistStatics from 'hoist-non-react-statics';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Button, Typography } from "@mui/material";
import IModule, { ModuleState } from "../../system/IModule"
import { controllerKeys, hasController, isAuthorized, IUserContext, permissions, ControllerDeployment } from '../../system/User.model'
import { setPageTitle } from '../../App'
import SettingsIcon from '@mui/icons-material/Settings';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import ScheduleIcon from '@mui/icons-material/Schedule';
import RuleIcon from '@mui/icons-material/Rule';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { Modal } from "../../system/Modal";
import ScheduleAudio from "../dashboard/ScheduleAudio";
import ScheduleUptime from "../dashboard/ScheduleUptime";
import LogoUploader from '../dashboard/LogoUploader';
import LocalKioskService from "../kiosks/LocalKiosk.service"
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import snackNotifications from "../../system/SnackBarUtils";
import FileEditor from '../files/FileEditor';
import { AuthContext } from '../../system/Base';


interface IState { 
	scheduleUptime: boolean
    scheduleSound: boolean
	uploadLogo: boolean
	showWhitelist: boolean
	kiosk: any
}
interface IProps extends WithTranslation { }

@IModule
class Settings extends React.Component<IProps, IState, WithTranslation> { 

	public static menu(t: ((x:string, y:any)=>string)) {
		return {
			title: (t && t(Settings.getLocale()+".title", {ns:Settings.getLocale()})) || "???",
			route : "/settings", 
			icon : <SettingsIcon />,
			weight : 140
		};
	}
	
	constructor(props: IProps) {
		super(props);

        this.state = {
            scheduleUptime: false,
            scheduleSound: false,
			uploadLogo: false,
			showWhitelist: false,
			kiosk: null
        };
		
		this.rebootKiosk = this.rebootKiosk.bind(this);
		this.restartApp = this.restartApp.bind(this);
	}
	
	public static getLocale() { return "module.settings"; }
	
	async componentDidMount()
	{
		setPageTitle(this.props.t( Settings.getLocale()+".title", { ns: Settings.getLocale() }));

		const kiosk = await LocalKioskService.getLocal(true);
		this.setState({kiosk : kiosk});
	}
	
	async rebootKiosk() {
        await LocalKioskService.reboot(this.state.kiosk);
        snackNotifications.info(this.props.t('common.request-sent', 'common'));
    }

    async restartApp() {
        await LocalKioskService.restartApp(this.state.kiosk);
        snackNotifications.info(this.props.t('common.request-sent', 'common'));
    }
	
	public static async search(input : string) {
		return null;
	}
	
	public static isEnabled(auth: IUserContext) { 
		if (!hasController(auth, controllerKeys.kiosk, ControllerDeployment.Local) ||
			!hasController(auth, controllerKeys.configuration))
			return ModuleState.DISABLED;
		if (isAuthorized(auth, permissions.kiosk.modifyUptimes) ||
			isAuthorized(auth, permissions.kiosk.modifyAudio) ||
			isAuthorized(auth, permissions.configuration.read))
			return ModuleState.ENABLED;
			
		return ModuleState.NO_PERMISSIONS;
	}
	
	render() {
	
		const columnSize = /*{ xs: 12, sm: 12, md: 6, lg: 4, xl: 3 }*/ {};
		const t = this.props.i18n.t;
		const dns = "module.dashboard";
	
		return (<Fragment>
		<Typography variant="h2">
			{ this.props.t(Settings.getLocale()+".title", {ns:Settings.getLocale()}) }
		</Typography>
		
		<AuthContext.Consumer>{(auth) => <><div className="kiosk-option-buttons settings-page">
			<Button variant="contained"  color="primary" startIcon={<VolumeUpIcon />} onClick={() => this.setState({ scheduleSound: true })}
				disabled={!isAuthorized(auth, permissions.kiosk.modifyAudio)}>
				{t(`${dns}.schedule-sound`, { ns: dns }) + ''}
			</Button>
		
			<Button variant="contained" color="primary" startIcon={<ScheduleIcon />} onClick={() => this.setState({ scheduleUptime: true })}
				disabled={!isAuthorized(auth, permissions.kiosk.modifyUptimes)}>
				{t(`${dns}.schedule-uptime`, { ns: dns }) + ''}
			</Button>
		
			<Button variant="contained" color="primary" startIcon={<AddPhotoAlternateIcon />} onClick={() => this.setState({ uploadLogo: true })}
				disabled={!isAuthorized(auth, permissions.configuration.read) || !isAuthorized(auth, permissions.configuration.write)}>
				{t(`${dns}.upload-logo`, { ns: dns }) + ''}
			</Button>

			<Button variant="contained" startIcon={<RuleIcon />} onClick={() => this.setState({ showWhitelist: true })}
				disabled={!isAuthorized(auth, permissions.configuration.read)}>
				{t(`${dns}.show-whitelist`, { ns: dns }) + ''}
			</Button>
			
			<Button variant="contained" color="secondary" startIcon={<RestartAltIcon />} onClick={this.restartApp}
				disabled={!isAuthorized(auth, permissions.kiosk.restart)}>
				{t(`${dns}.restart-app`, { ns: dns }) + ''}
			</Button>
			
			<Button variant="contained" color="secondary" startIcon={<RestartAltIcon />} onClick={this.rebootKiosk}
				disabled={!isAuthorized(auth, permissions.kiosk.reboot)}>
				{t(`${dns}.reboot`, { ns: dns }) + ''}
			</Button>
		</div>
		
		{this.state.scheduleUptime &&
            <Modal title={t(`${dns}.schedule-uptime`, {ns:dns})} isOpen={this.state.scheduleUptime} onClose={() => this.setState({ scheduleUptime: false })}>
                <ScheduleUptime kiosk={this.state.kiosk} kioskService={LocalKioskService} onSubmit={() => this.setState({ scheduleUptime: false })} />
            </Modal>}
			
		{this.state.uploadLogo &&
            <Modal title={t(`${dns}.upload-logo`, {ns:dns})} isOpen={this.state.uploadLogo} onClose={() => this.setState({ uploadLogo: false })}>
                <LogoUploader />
            </Modal>}

        {this.state.scheduleSound &&
            <Modal title={t(`${dns}.schedule-sound`, {ns:dns})} isOpen={this.state.scheduleSound} onClose={() => this.setState({ scheduleSound: false })}>
                <ScheduleAudio kiosk={this.state.kiosk} kioskService={LocalKioskService} onSubmit={() => this.setState({ scheduleSound: false })} />
            </Modal>}
		
		{this.state.showWhitelist &&
            <Modal title={t(`${dns}.show-whitelist`, {ns:dns})} isOpen={this.state.showWhitelist} width={{ xs: '90vw', md: '70vw' }} height={{ xs: '90vh', md: '80vh' }}
				onClose={() => this.setState({ showWhitelist: false })}>
				<FileEditor directory="configuration" path={`/whitelist.txt`} readonly={!isAuthorized(auth, permissions.configuration.write)}
					onSubmit={() => this.setState({ showWhitelist: false })} />
            </Modal>}
		</>}
		</AuthContext.Consumer>

		</Fragment>);
	}

}

export default hoistStatics(withTranslation()(Settings), Settings)