import { CircularProgress } from "@mui/material";
import { Field } from "formik";
import hoistStatics from "hoist-non-react-statics";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { IKiosk } from "../kiosks/Kiosks.model";
import KiosksService from "../kiosks/Kiosks.service";
import { ButtonMount, createDefaultButton, IButton, stripSpan } from "./Button.model";
import { IBoard } from "../boards/Boards.model";
import BoardsService from "../boards/Boards.service";
import ButtonFields from "./ButtonFields";
import { ListEditor } from "../../system/ListEditor";

interface IProps extends WithTranslation {
    kiosk: IKiosk,
    className?: string,
    onSubmit: (buttons: IButton[]) => void | Promise<void>
}

interface IState {
    buttons: IButton[]
    boards: IBoard[]
    initialized: boolean
}

class Buttons extends React.Component<IProps, IState, WithTranslation> {

    kioskService = KiosksService;
    boardService = BoardsService;
    mountTypes = [ ButtonMount.Local, ButtonMount.Global, ButtonMount.Combined, ButtonMount.Url, ButtonMount.LocalBoard, ButtonMount.GlobalBoard ];

    constructor(props: IProps) {
        super(props);

        this.state = {
            buttons: [],
            boards: [],
            initialized: false
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.validate = this.validate.bind(this);
        this.buttonName = this.buttonName.bind(this);
        this.buttonForm = this.buttonForm.bind(this);
    }

    async componentDidMount() {
        const buttonsP = this.kioskService.getButtons(this.props.kiosk);
        const boardsP = this.boardService.getAll();
        const [ buttons, boards ] = await Promise.all([ buttonsP, boardsP ]);

        this.setState({
            buttons: buttons,
            initialized: true,
            boards: boards.data
        });
    }

    async onSubmit(buttons: IButton[]): Promise<void> {
        for (let i = 0; i < buttons.length; ++i)
            buttons[i].position = i;
        
        await this.kioskService.setButtons(this.props.kiosk, buttons);
        await this.props.onSubmit(buttons);
    }

    validate(_buttons: IButton[]): null {
        return null;
    }

    private buttonName(button: IButton) {
        const ns = 'module.dashboard';
        return `${stripSpan(button.label)}: ${this.props.t(`${ns}.${button.mount}`, {ns: ns})}`;
    }

    private buttonForm(_button: IButton, namePath: string) {
        return <Field name={namePath} showPosition={false} boards={this.state.boards} component={ButtonFields} />;
    }

    render() {
        return <>
        {!this.state.initialized && <CircularProgress disableShrink />}
        {this.state.initialized && <ListEditor<IButton> role='update'
            items={this.state.buttons ?? []}
            addLabelKey='module.dashboard.add-button'
            addLabelNs='module.dashboard'
            expandListLabelKey='module.dashboard.expand-buttons'
            collapseListLabelKey='module.dashboard.collapse-buttons'
            listLabelNs='module.dashboard'
            itemForm={this.buttonForm}
            itemName={this.buttonName}
            createItem={createDefaultButton}
            validate={this.validate}
            onSubmit={this.onSubmit} />}
    </>;
    }
}

/* export default hoistStatics(withTranslation()(styled), styled); */
export default hoistStatics(withTranslation()(Buttons), Buttons);