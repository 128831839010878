import http from "../../system/Communicator";
import { createDefaultTag, ITag } from "./Tags.model"
import ICrudService from "../../system/ICrudService"
import { IPagedResponse, PageQuery } from "../../system/Pagination.model";
import { IEntry, ISearchable } from "../../system/SearchResult.model";
import { Sorting } from "../../system/CRUDTable";
import { combineQueries } from "../../system/RequestResponse.model";

class TagService implements ICrudService<ITag>, ISearchable<ITag> {

	async getAll(pageQuery?: PageQuery, sort?: Sorting<ITag>): Promise<IPagedResponse<ITag>> {
		const pq = pageQuery?.toString() ?? '';
		const sq = sort?.toString() ?? '';
		const query = combineQueries(pq, sq);

		return (await http.get<IPagedResponse<ITag>>(`/tag?${query}`)).data;
	}
	
	async create(entity: ITag): Promise<ITag> {
		return (await http.post<ITag>("/tag", entity)).data;
	}
	
	async delete(entity: ITag): Promise<void> {
		await http.delete<void>("/tag/" + entity.id!);
	}
	
	async edit(entity: ITag): Promise<ITag> {
		return (await http.patch<ITag>("/tag/" + entity.id, entity)).data;
	}
	
	async search(text : string) : Promise<IEntry<ITag>[]> {
		let result = (await http.get<IPagedResponse<ITag>>(`/tag`)).data;
		return result.data
			.map((tag, index) => { return { entity: tag, index: index }})
			.filter(x => x.entity.name.toLowerCase().includes(text.toLowerCase()));
	}

	createDefaultEntity = createDefaultTag;
}
export default new TagService();