import React, { Fragment } from 'react';
import hoistStatics from 'hoist-non-react-statics';
import { withTranslation, WithTranslation } from 'react-i18next';
import Dropzone, { IDropzoneProps } from 'react-dropzone-uploader'
import { API_URL } from "../../system/Communicator";
import { Button, IconButton } from '@mui/material';
import { Modal } from '../../system/Modal';
import { FileArray,	FileData } from 'chonky';
import FileService from '../files/Files.service';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

interface IState { 
	showingUpload : boolean,
	lastDropZoneEvent : Date,
	logoFiles : FileArray
}
interface IProps extends WithTranslation { }

class LogoUploader extends React.Component<IProps, IState, WithTranslation> {
	
	constructor(props: IProps) {		
		super(props);
		this.state = {
			showingUpload : false,
			lastDropZoneEvent : new Date(),
			logoFiles : []
		};
	}
	
	getExtension = (fileName : string) => {
		return fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2).toLowerCase();
	}
	
	fileIsLogo(file : FileData) {
		if (file.isDir) return false;
		let ex = this.getExtension(file.name);
		if (ex === "png" && (file.name.startsWith("logo_") || file.name === "logo.png"))
			return true;
		return false;
	}
	
	async getAllLogos() {
		let all = await FileService.getAll("configuration", "");
		let f = all.filter((x : any) => { return this.fileIsLogo(x)});
		return f;
	}
	
	async reloadFiles()	
	{
		let files = await this.getAllLogos();
		this.setState({logoFiles : files});
	}
	
	async removeLogo(file : FileData, index : number)
	{
		let max = this.state.logoFiles.length;
		await FileService.deleteItem("configuration", "/" + file.name);
		
		/*
			We need to shift the names, so that:
				0: logo.png
				1: logo_2.png
				2: logo_3.png <-- deleted
				3: logo_4.png
				4: logo_5.png
			will become this:
				0: logo.png
				1: logo_2.png
				3: logo_3.png <-- renamed
				4: logo_4.png <-- renamed
		*/
		
		for (let i = index + 1; i < max; i++) {
			let frm = "logo_" + (i + 1) + ".png";
			let to = (i === 1) ? "logo.png" : "logo_" + i + ".png"
			await FileService.moveItems("configuration", frm, to);
		}
		
		this.reloadFiles();
	}
	
	componentDidMount()
	{
		this.reloadFiles();
	}
	
	render() {
		
		const t : any = (x : string) => { return this.props.t("module.files." + x, {ns : "module.files"}); };		
		
		const renameFile = (file: File, name : string) => {
			let f = new File([file], name);
			return f;
		}
		
		const getUploadParams: IDropzoneProps['getUploadParams'] = (fileWithMeta : any) => {
			this.setState({ lastDropZoneEvent : new Date() }); // Force refresh
			let num = "";
			if (this.state.logoFiles.length > 0)
				num = "_" + (this.state.logoFiles.length + 1);
			let newName =  "logo" + num + "." + this.getExtension(fileWithMeta.file.name);
			fileWithMeta.file = renameFile(fileWithMeta.file, newName);
			return { 
				url: API_URL + "/file/configuration?subdirectory=",	// root folder
				headers : {
					'Authorization' : sessionStorage.getItem('dp2_token') || ""
				}
			};
		};
		
		const handleChangeStatus = () => {
			this.setState({ lastDropZoneEvent : new Date() }); // Force refresh
		}
		
		const handleSubmit: IDropzoneProps['onSubmit'] = (files, allFiles) => {
			this.setState({showingUpload : false}, this.reloadFiles);
		}
		
		return (<Fragment>
		
		{
			this.state.logoFiles.map((file, i) =>
				(<div key={i} style={{display: "flex", padding: "5px"}}>
					<IconButton aria-label="remove file" onClick={ ()=>{ this.removeLogo(file!, i); } }>
						<RemoveCircleOutlineIcon />
					</IconButton >
					<div style={{width : "64px", height: "64px", display: "flex", justifyContent: "center", alignItems: "center"}}>
						<img alt="logo thumbnail" src={file!.thumbnailUrl} style={{maxWidth : "64px", maxHeight: "64px",}} /></div>
					<span style={{display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "10px"}}>{ file!.name }</span>
				</div>)
			)
		}
		
		<Button onClick={()=>{this.setState({showingUpload : true})}}>{t('upload-logo')}</Button>
		
		<Modal title={ t("upload-item") } isOpen={this.state.showingUpload} onClose={()=>{ handleSubmit([], []); } }>		
			<div className="modal-file-upload"><Dropzone
			accept=".png"
			maxFiles={1}
			getUploadParams={getUploadParams}
			onSubmit={handleSubmit}
			onChangeStatus={handleChangeStatus}
			submitButtonContent = {t("upload-done")}
			inputWithFilesContent = {t("upload-more")}
			inputContent = {t("upload-input-one")}
			/></div>
			
		</Modal>

		</Fragment>);
	}

}

export default hoistStatics(withTranslation()(LogoUploader), LogoUploader)