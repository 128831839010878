import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import { canEditSchedule, canEditSlides, createDefaultSlide, IAddFilesRequest, IPlaylist, isValid, toMedia } from "./Playlists.model";
import hoistStatics from "hoist-non-react-statics";
import { Button, Typography } from "@mui/material";
import DataTable, { Attribute } from "../../system/DataTable";
import PlaylistService from "./Playlist.service";
import SlideshowIcon from '@mui/icons-material/Slideshow';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Modal } from "../../system/Modal";
import SlidesChange from "./SlidesChange";
import ScheduleChange from "./ScheduleChange";
import IModule, { ModuleState } from "../../system/IModule"
import { load, save, setPageTitle } from '../../App'
import { controllerKeys, hasController, isAuthorized, IUserContext, permissions } from '../../system/User.model'
import { IEntry, ISearchResult } from "../../system/SearchResult.model";
import { AuthContext } from "../../system/Base";
import { filterToExtension, hasCorrectExtension, mediaFilter } from "../files/Files.model";

interface IState {
	editedPlaylist: IPlaylist | null,
	editingSlides: boolean,
	editingSchedule: boolean,
	contentAreas: string[],
}
interface IProps extends WithTranslation { }

@IModule
class Playlists extends React.Component<IProps, IState, WithTranslation> {

	dataTableRef: React.RefObject<DataTable<IPlaylist>>;
	playlistService = PlaylistService;

	constructor(props: IProps) {		
		super(props);
		this.state = {
			editedPlaylist: null,
			editingSlides: false,
			editingSchedule: false,
			contentAreas: []
		};

		this.showSlidesForm = this.showSlidesForm.bind(this);
		this.showScheduleForm = this.showScheduleForm.bind(this);
		this.hideSlidesForm = this.hideSlidesForm.bind(this);
		this.hideScheduleForm = this.hideScheduleForm.bind(this);
		this.triggerRefresh = this.triggerRefresh.bind(this);

		this.dataTableRef = React.createRef<DataTable<IPlaylist>>();
	} 
	
	public static getLocale() { return "module.playlists"; }
	
	async componentDidMount()
	{
		setPageTitle(this.props.t( Playlists.getLocale()+".module-title", { ns: Playlists.getLocale() }));
		
		const contentAreas = await this.playlistService.getContentAreas();
		this.setState({ contentAreas });

		let value = load('use-files-in-playlist');
		if (value) {
			save('use-files-in-playlist', null); // Clear
			const request = JSON.parse(value) as IAddFilesRequest;
			if (request && isValid(request))
				await this.addFilesToPlaylist(request);
		}
	}

	async addFilesToPlaylist(request: IAddFilesRequest) {
		let p: IPlaylist | undefined;
		if (request.isNew)
			p = await this.playlistService.create({ name: request.playlist });
		else
			p = (await this.playlistService.getAll()).data.find(p => p.name === request.playlist);

		if (!p) return;
		
		const extensions = filterToExtension(mediaFilter);
		const mediaFiles = request.files.filter(filename => hasCorrectExtension(filename, extensions));

		const newSlides = mediaFiles.map(path => {
			const slide = toMedia(createDefaultSlide());
			slide.url = path;
			return slide;
		});
		p.slides = (p.slides ?? []).concat(newSlides);
		this.setState({ editedPlaylist: p, editingSlides: true, editingSchedule: false });
	}
	
	public static menu(t: ((x:string, y:any)=>string)) {
		return {
			title: (t && t(Playlists.getLocale()+".module-title", {ns:Playlists.getLocale()})) || "???",
			route : "/playlists",
			icon : <PlaylistPlayIcon />,
			weight : 70
		};
	}
	
	public static async search(input : string) {
		const res = await PlaylistService.search(input);		
		return res.map((r: IEntry<IPlaylist>) : ISearchResult => { return {
			title : r.entity.name,
			description : '', // there's not much else to use for description...
			icon : <PlaylistPlayIcon />,
			link : "/playlists?search-result-index=" + r.index
		}});
	}
	
	public static isEnabled (auth: IUserContext) {
		if (!hasController(auth, controllerKeys.playlist))
			return ModuleState.DISABLED;
		if (isAuthorized(auth, permissions.playlist.read))
			return ModuleState.ENABLED;
			
		return ModuleState.NO_PERMISSIONS;
	}

	showSlidesForm(playlist: IPlaylist) {
		this.setState({
			editedPlaylist: playlist,
			editingSlides: true
		});
	}

	async hideSlidesForm() {
		await this.triggerRefresh();
		
		this.setState({
			editedPlaylist: null,
			editingSlides: false
		});
	}

	showScheduleForm(playlist: IPlaylist) {
		this.setState({
			editedPlaylist: playlist,
			editingSchedule: true
		});
	}

	async hideScheduleForm() {
		await this.triggerRefresh();
		
		this.setState({
			editedPlaylist: null,
			editingSchedule: false
		});
	}

	async triggerRefresh() {
		await this.dataTableRef.current?.refresh();
	}
	
	render() {
		const t = this.props.t;
		const ns = Playlists.getLocale();

		const modalWidth = { xs: 'min(90vw, 360px)', md: 'auto' };
		const modalHeight = '90vh';

		const SlidesChangeAction = (p: IPlaylist) => <div>
			<AuthContext.Consumer>{(auth) => {
				return <Button variant="outlined" type="submit" onClick={() => this.showSlidesForm(p)}
					disabled={!canEditSlides(auth)} startIcon={<SlideshowIcon />}>
					{t(`${ns}.edit-slides`, {ns: ns})}
				</Button>}}
			</AuthContext.Consumer>
		</div>;

		const ScheduleChangeAction = (p: IPlaylist) => <div>
			<AuthContext.Consumer>{(auth) => {
				return <Button variant="outlined" type="submit" onClick={() => this.showScheduleForm(p)}
					disabled={!canEditSchedule(auth, this.state.contentAreas)} startIcon={<CalendarMonthIcon />}>
					{t(`${ns}.edit-schedule`, {ns: ns})}
				</Button>}}
			</AuthContext.Consumer>
		</div>;

		return (<>
			<Typography variant="h2">
				{ t(Playlists.getLocale()+".module-title", {ns:Playlists.getLocale()}) }
			</Typography>
			
			<DataTable ref={this.dataTableRef} service={PlaylistService} entityName={t(`${ns}.entity`, {ns: ns})} entityType='playlists' 
				permissions={permissions.playlist} create={true} edit={true} delete={true}>
				<Attribute name="id" type="number" labelKey="id" namespace={ns} hideInCreateForm hideInUpdateForm hideInTable />
				<Attribute name="name" labelKey="name" namespace={ns} />
				<Attribute name="slides" labelKey="slides" namespace={ns} sortable={false} hideInCreateForm hideInUpdateForm
					tableValueResolver={SlidesChangeAction} />
				<Attribute name="schedule" labelKey="schedule" namespace={ns} sortable={false} hideInCreateForm hideInUpdateForm
					tableValueResolver={ScheduleChangeAction} />
			</DataTable>

			{this.state.editedPlaylist && this.state.editingSlides &&
			<Modal title={t(`${ns}.edit-slides`, {ns: ns}) + ': ' + this.state.editedPlaylist.name} isOpen={true} onClose={this.hideSlidesForm} width={modalWidth} height={modalHeight}>
                <SlidesChange entity={this.state.editedPlaylist} onDone={this.hideSlidesForm}/>
			</Modal>}
		
			{this.state.editedPlaylist && this.state.editingSchedule &&
			<Modal title={t(`${ns}.edit-schedule`, {ns: ns}) + ': ' + this.state.editedPlaylist.name} isOpen={true} onClose={this.hideScheduleForm} width={modalWidth} height={modalHeight}>
				<ScheduleChange entity={this.state.editedPlaylist} onDone={this.hideScheduleForm}/>
			</Modal>}
			</>);
    }
}

export default hoistStatics(withTranslation()(Playlists), Playlists)