import { IDuallyIdentifiable } from "../../system/Identifiable.model"
import http from "../../system/Communicator";
import { DateTime } from "luxon"
import { ITag } from "../tags/Tags.model"
import { parseDate } from "../../App"

interface IKiosk extends IDuallyIdentifiable {
	name: string,
	description: string,
	contact?: string,
	notes: string,
	lat: number,
	lon: number,

	tags?: ITag[]
	status?: IStatus
}

function createDefaultKiosk(): IKiosk {
	return {
		identifier: '',
		name: '',
		description: '',
		contact: '',
		notes: '',
		lat: 0,
		lon: 0
	};
}

export interface IPreKioskFilter {
    kiosks?: IKiosk[];
    include?: ITag[];
    exclude?: ITag[];
}
export interface IKioskFilter {
    kiosks?: number[]; // ids
    include?: number[]; // tag ids
    exclude?: number[]; // tag ids
}
export function processKiosks(kf: IPreKioskFilter): IKioskFilter {
    return { kiosks: kf.kiosks?.map(k => k.id!), include: kf.include?.map(t => t.id!), exclude: kf.exclude?.map(t => t.id!) };
}
export function createDefaultKioskFilter(): IKioskFilter {
    return { kiosks: [], include: [], exclude: [] };
}

interface IScheduleInterval {
	start?: string, // "2022-07-19 13:21:28.993357",
	state?: boolean,
	end?: string // "2022-07-19 17:30:00"	
}

interface IStatus {
	date?: string, // "2022-07-19T15:30:52+02:00",
	audio?: {
		volume?: string, // "50%",
		muted?: boolean
	},
	backend?: {
		branch?: string, // "main" or other
		version?: string, // "2023-10-09 13:20:42 88c63e28b32878a3547c91d756b05b61016c2ec2"
		mirror?: boolean
	},
	deploy?: {
		active?: string, // "/home/deploy-archive/2022-07-18_15-45_Jiei",
		count?: number // 1
	},
	digipanel?: {
		running?: boolean,
		branch?: string, // "amos-develop 2022-06-03 10:00:52",
		content?: string // "amos-develop 2022-05-19 11:05:06"
	},
	frontend?: {
		version?: string // "2023-09-06 11:47:08 e1ba87aafc0c865d1cbdd3125c3c5b4ddb4b6d5d"
	},
	id?: {
		"init-date"?: string, // "2022-03-01T18:43:04+01:00",
		hostname?: string // "digipanel-fortes"
	},
	keyboard?: {
		onboard?: boolean,
		visible?: boolean
	},
	licence?: {
		info: {
			id: number,
			identifier: string, // "ik-amos-0113-ss-praha-plzenska-2"
			created: number | string, // ms since epoch or weird string; FIXME after backend is fixed
			enabled: number, // FIXME?
			expires: number, // ms since epoch
			project_name: string,
			project_description: string,
			product_name: string,
			product_description: string,
			client_name: string,
			client_email: string,
			client_description: string,
			hwidentity: string, // "53b95956e7"
			token: string,
			tags: string[]
		},
		status: {
			licensed: boolean,
			message: string // "License is valid"
		}
	},
	network?: {
		local_ip?: string[], // [ "192.168.0.192/24" ],
		zt_ip?: string[], // [ "10.147.20.60/16" ],
		essid?: string // "FORTES"
	},
	options?: any,//{},
	scheduler?: {
		shutdown?: IScheduleInterval[]
	},
	screen?: {
		port?: string, // "-no screen connected-",
		resolution?: string,
		orientation?: string,
		dpms?: true
	},
	sync?: {
		kiosk?: {
			date?: string, // "2022-07-19T15:27:27+02:00",
			elapsed?: number // 0
		},
		content?: {
			date?: string, // "2022-07-19T11:28:42+02:00",
			elapsed?: number // 31
		},
		last?: string, // "2022-07-19T11:28:26.130Z" ISO UTC
		active?: boolean
	},
	system?: {
		uptime?: string, // " 2:09",
		load_avg?: string, // "1.38 1.30 1.17",
		temperature?: string, // "+45.0°C",
		ram_total?: string, // "3,7G",
		ram_free?: string, // "1,2G",
		os_total?: string, // "16G",
		os_free?: string, // "1,9G",
		os_used_percentage?: string, // "87%",
		home_total?: string, // "91G",
		home_free?: string, // "85G",
		home_used_percentage?: string // "3%"
	},
	touch?: {
		connected?: boolean,
		enabled?: boolean
	}
}

enum KioskState {
	Online = "online", Offline = "offline", Warning = "warning", Error = "error", Unknown = "unknown"
}

enum LicenseState {
	Valid, Expired, Invalid, Unknown
}

class TagNode {
	constructor(
		public tag: ITag,
		public kiosks: IKiosk[],
		public children: TagNode[]) {}
}

class TagTree {
	roots: TagNode[] = []

	constructor(tags: ITag[], kiosks: IKiosk[]) {
		let parentedTags = new Set<number>();
		let tagChildren = new Map<number, ITag[]>();
		let kioskChildren = new Map<number, IKiosk[]>();

		for (let t of tags) {
			if (!t.parent)
				continue;
			
			if (!tagChildren.has(t.parent))
				tagChildren.set(t.parent, []);
			tagChildren.get(t.parent)!.push(t);
			parentedTags.add(t.id!);
		}

		for (let k of kiosks) {
			if (!k.tags)
				continue;
			
			for (let t of k.tags) {
				if (!kioskChildren.has(t.id!))
					kioskChildren.set(t.id!, []);
				kioskChildren.get(t.id!)!.push(k);
			}
		}

		for (let t of tags) {
			if (!parentedTags.has(t.id!))
				this.roots.push(this.buildNode(t, tagChildren, kioskChildren));
		}
	}

	private buildNode(tag: ITag, tagChildren: Map<number, ITag[]>, kioskChildren: Map<number, IKiosk[]>): TagNode {
		const children = tagChildren.get(tag.id!)
			?.map(tc => this.buildNode(tc, tagChildren, kioskChildren)) ?? [];
		
		return new TagNode(tag, kioskChildren.get(tag.id!) ?? [], children);
	}
}

class StatusParser {
	// Default values before load:
	static ONLINE_TOLERANCE_SEC = 15 * 60;
	static SCHEDULER_UPDATE_TOLERANCE_SEC = 20 * 60;
	static SHUTDOWN_TOLERANCE_SEC = 5 * 60;
	static SERVICING_START_HOUR = 4;
	static SERVICING_START_MIN = 0;
	static SERVICING_END_HOUR = 4;
	static SERVICING_END_MIN = 15;
	//static SERVICING_LENGHT_SEC = 25 * 60 + this.SHUTDOWN_TOLERANCE_SEC;
	
	static async loadServiceTimeFromServer() {
		await new Promise(r => setTimeout(r, 2000));
		var output = await http.get("/service-time").then(
			(resp : any) => {
				// TODO ...
				try {
					let r = resp.data; //'{ "from": "4:00", "to": "4:15" }';
					console.log("Service time fetched: " + r);
					let y = JSON.parse(r);
					let frm = y.from.split(':');
					let to = y.to.split(':');
					if (frm.length > 1 && to.length > 1) {
						this.SERVICING_START_HOUR = Number(frm[0]);
						this.SERVICING_START_MIN = Number(frm[1]);
						
						this.SERVICING_END_HOUR = Number(to[0]);
						this.SERVICING_END_MIN = Number(to[1]);
						
					} else console.error("Unknown service time.");
				} catch (ex) { console.error("Unknown service time (" + ex + ").") }
			}
		);
		console.log("Loaded service time: " + 
			(this.SERVICING_START_HOUR+"").padStart(2, '0')
			+ ":" + 
			(this.SERVICING_START_MIN+"").padStart(2, '0')
			+ " - " +
			(this.SERVICING_END_HOUR+"").padStart(2, '0')
			+ ":" + 
			(this.SERVICING_END_MIN+"").padStart(2, '0')
			+ " (tolerance: " + this.SHUTDOWN_TOLERANCE_SEC + " sec)");
	}

	static inInterval(datetime: Date, start: string | Date, end: string | Date): boolean {
		const startT: Date = typeof start === 'string' ? parseDate(start) : start;
		const endT: Date = typeof end === 'string' ? parseDate(end) : end;

		return startT < datetime && datetime <= endT;
	}

	private static getErrorState(status: IStatus): KioskState.Warning | KioskState.Error {
		if (status.date) {
			const statusDate = DateTime.fromISO(status.date);
			const today = DateTime.now().startOf('day');

			if (statusDate >= today)
				return KioskState.Warning;
		}
		return KioskState.Error;
	}

	static getKioskState(status?: IStatus): KioskState {
		if (!status)
			return KioskState.Unknown;
		
		const lastOnline = status.date ? parseDate(status.date) : parseDate("1900-01-01");
		const now = new Date();
		const recentlyActive = (now.valueOf() - lastOnline.valueOf()) <= this.ONLINE_TOLERANCE_SEC * 1000;

		const servicingStart = new Date(now.getFullYear(), now.getMonth(), now.getDate(), this.SERVICING_START_HOUR, this.SERVICING_START_MIN, 0, 0);
		//const servicingEnd = new Date(servicingStart.valueOf() + this.SERVICING_LENGHT_SEC * 1000);
		const servicingEnd = new Date(
			(new Date(now.getFullYear(), now.getMonth(), now.getDate(), this.SERVICING_END_HOUR, this.SERVICING_END_MIN, 0, 0)).valueOf()
			+ this.SHUTDOWN_TOLERANCE_SEC);

		if (!status.scheduler || !status.scheduler.shutdown)
			return this.getErrorState(status);

		const shutdown = Array.isArray(status.scheduler.shutdown)
			? status.scheduler.shutdown // new version: array of intervals
			: Object.values(status.scheduler.shutdown) as IScheduleInterval[]; // old version: indexed object
		
		for (let i = 0; i < shutdown.length; ++i) {
			const interval = shutdown[i];
			
			if (!interval || interval.state === undefined || !interval.start || !interval.end)
				return this.getErrorState(status);

			// process interval if it applies to now
			if (this.inInterval(now, interval.start, interval.end)) {

				if (!interval.state) { // Kiosk should be offline now
					
					if (this.inInterval(lastOnline, interval.start, interval.end)) { // and was online in this interval // (check exactly to avoid false errors close to shutdown)
						
						if (this.inInterval(lastOnline, servicingStart, servicingEnd)) { // but it was because of servicing
							if (this.inInterval(now, servicingStart, servicingEnd)) // which is still going on.
								return recentlyActive ? KioskState.Online : KioskState.Offline;
							return KioskState.Offline; // and the servicing is over now.
						}

						const shutdownTimeWasTolerable = lastOnline.valueOf() - parseDate(interval.start).valueOf() <= this.SHUTDOWN_TOLERANCE_SEC * 1000;
						if (shutdownTimeWasTolerable)
							return KioskState.Offline; // but it was because of a short shutdown delay.
						
						return this.getErrorState(status); // and the activity wasn't justified.
					}
					return KioskState.Offline; // and was correctly inactive.
				}

				if (interval.state) { // Kiosk should be online now
					if (recentlyActive)
						return KioskState.Online; // and was recently active.
					return this.getErrorState(status); // and wasn't recently active.
				}
			}
		}

		// all shutdown intervals are outdated;
		// check against the last interval until scheduler sends an update
		if (shutdown.length === 0)
			return this.getErrorState(status);
		const lastInterval = shutdown[shutdown.length - 1];
		const lastIntervalEnd = parseDate(lastInterval.end ?? "");

		// if last last interval ended <tolerance> time ago
		if (now.valueOf() - lastIntervalEnd.valueOf() <= this.SCHEDULER_UPDATE_TOLERANCE_SEC * 1000)
			if (lastInterval.state) // last interval was online, so now should be offline
				return KioskState.Offline;

		return this.getErrorState(status);
	}
	
	static getLocalAddress(status?: IStatus): string {
		let ip = (status?.network?.zt_ip);
		if (ip && ip.length > 0)
			return "http://" + ip[0].split('/')[0] + ":8080";
		else
			return "";
	}

	static getLicenseState(status?: IStatus): LicenseState {
		if (!status || !status.licence)
			return LicenseState.Unknown;

		if (status.licence.status.licensed)
			return LicenseState.Valid;
		return LicenseState.Invalid;
	}

	static getLicenseDuration(status?: IStatus): { from: DateTime, to: DateTime } | undefined {
		if (!status || !status.licence)
			return undefined;

		return {
			from: typeof status.licence.info.created === 'string'
				? DateTime.fromJSDate(new Date(status.licence.info.created)) // FIXME after backend is fixed
				: DateTime.fromMillis(status.licence.info.created),
			to: DateTime.fromMillis(status.licence.info.expires)
		};
	}
}

export type { IKiosk, IStatus }
export { StatusParser, KioskState, LicenseState, TagTree, TagNode, createDefaultKiosk }