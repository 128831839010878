import http from "../../system/Communicator";
import { AxiosError, AxiosResponse } from 'axios';
import { IUser, IValidatedCredentials, ILoginCredentials, IPasswordChange, IPermission, IPermissionChange, createDefaultUser, IPasswordOverwrite } from "./Users.model"
import ICrudService from "../../system/ICrudService"
import { IPagedResponse, PageQuery } from "../../system/Pagination.model";
import { Sorting } from "../../system/CRUDTable";
import { combineQueries } from "../../system/RequestResponse.model";

interface IUserService extends ICrudService<IUser> {
	
	login(user: string, pwd: string): Promise<IValidatedCredentials | null | undefined>;
	setPassword(passwordOverwrite: IPasswordOverwrite): Promise<void>;
	changePassword(passwordChange: IPasswordChange): Promise<void>;
	getUserPermissions(userId: number): Promise<IPermission[]>;
	getAllPermissions(): Promise<IPermission[]>;
	setUserPermissions(permissionChange: IPermissionChange): Promise<void>;
	groupByModule(permissions: IPermission[]): IPermission[][];
}

class UserService implements IUserService {

	async login(user: string, pwd: string) : Promise<IValidatedCredentials | null | undefined> {
		var creds = {} as ILoginCredentials;
		creds.login = user;
		creds.password = pwd;
		var output = await http.post("/auth/login", creds)
			.then((resp : AxiosResponse<IValidatedCredentials>) => {
				return resp.data as IValidatedCredentials;
			})
			.catch((err : AxiosError<{error : string}>) => {
				return null;
			});
		return output;
	}

	async getAll(pageQuery?: PageQuery, sort?: Sorting<IUser>): Promise<IPagedResponse<IUser>> {
		const pq = pageQuery?.toString() ?? '';
		
		// There was a bug with generic object being used instead of an instance of Sorting,
		// with the exact same attributes as Sorting but without the methods (we need custom toString).
		// Don't ask me how that is possible when the parameter is defined as Sorting, not Any.
		// This fixes it by creating the correct instance and re-using the parameters. Without this
		// the serialization would break and we would have [object=Object] in URL, or something.
		// Which is technically not a problem since BE would ignore it, but it's ugly.
		if (sort?.constructor.name == "Object")
			sort = new Sorting<IUser>(sort.field, sort.direction == "ascending");
			
		const sq = sort?.toString() ?? '';
		const query = combineQueries(pq, sq);

		return (await http.get<IPagedResponse<IUser>>(`/user?${query}`)).data;
	}
	
	async create(user: IUser): Promise<IUser> {
		return (await http.post<IUser>("/user", user)).data;
	}
	
	async delete(user: IUser): Promise<void> {
		await http.delete<void>("/user/" + user.id);
	}
	
	async edit(user: IUser): Promise<IUser> {
		let u = (await http.patch<IUser>("/user/" + user.id, this.permissionless(user))).data;

		if (user.permissions !== undefined) {
			await this.setUserPermissions({ id: user.id, permissions: user.permissions });
			u.permissions = user.permissions;
		}
		return u;
	}

	async changePassword(passwordChange: IPasswordChange): Promise<void> {
		await http.post<void, AxiosResponse<void>, IPasswordChange>("/user/change-password", passwordChange);
	}

	async setPassword(passwordOverwrite: IPasswordOverwrite): Promise<void> {
		await http.post<void>("/user/set-password", passwordOverwrite);
	}
	
	async getUserPermissions(userId: number): Promise<IPermission[]> {
		return (await http.get<IPermission[]>("/auth/user-permissions/" + userId)).data;
	}

	async setUserPermissions(permissionChange: IPermissionChange): Promise<void> {
		const data = {...permissionChange, permissions: permissionChange.permissions.map(p => p.permission)};
		await http.post<IPermission[]>("/auth/set-permissions/", data);
	}
	
	async getAllPermissions(): Promise<IPermission[]> {
		return (await http.get<IPermission[]>("/auth/permissions")).data;
	}

	createDefaultEntity = createDefaultUser;

	private permissionless(user: IUser): IUser {
		if (!user.permissions)
			return user;
		let u = {...user};
		delete u.permissions;
		return u;
	}
	
	groupByModule(permissions: IPermission[]): IPermission[][] {
		let grouped = new Map<string, IPermission[]>();

		for (let p of permissions) {
			if (!grouped.has(p.module))
				grouped.set(p.module, []);
			
			grouped.get(p.module)!.push(p);
		}
		return Array.from(grouped.values());
	}
	

  /*getAll() {
    return http.get<Array<ITutorialData>>("/tutorials");
  }
  get(id: string) {
    return http.get<ITutorialData>(`/tutorials/${id}`);
  }
  create(data: ITutorialData) {
    return http.post<ITutorialData>("/tutorials", data);
  }
  update(data: ITutorialData, id: any) {
    return http.put<any>(`/tutorials/${id}`, data);
  }
  delete(id: any) {
    return http.delete<any>(`/tutorials/${id}`);
  }
  deleteAll() {
    return http.delete<any>(`/tutorials`);
  }
  findByTitle(title: string) {
    return http.get<Array<ITutorialData>>(`/tutorials?title=${title}`);
  }*/
}
export default new UserService();
export type { IUserService };