import { Button, Card, CardActions, CardContent, IconButton, Tab, Tabs, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Box } from "@mui/system";
import { Field, FieldArray, Form, Formik, FormikProps, getIn } from "formik";
import hoistStatics from "hoist-non-react-statics";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import MuiField from "../../system/MuiField";
import { createDefaultPeriodicSchedule, createDefaultScheduleException, ISchedule } from "./Schedule.model";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import MuiTimeField, { timeFromIso } from "../../system/MuiTimeField";
import { dateFromIso } from "../../system/MuiDateField";
import { isValidTimeRange } from "../../system/FieldValidators";
import { ILabeledFieldProps } from "../../system/Formik.model";
import ScheduleExceptionFields from "./ScheduleExceptionFields";

function strToBool(value: boolean | string) {
    return JSON.parse('' + value);
}

interface IProps extends WithTranslation {
    onSubmit(values: ISchedule): void | Promise<void>
    values: ISchedule
    className?: string
    children?: React.ReactNode // additional default settings
}

interface IState {
    activeTab: number
}

class Schedule extends React.Component<IProps, IState, WithTranslation> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            activeTab: 0
        };

        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.validate = this.validate.bind(this);
    }

    // This is a temporary solution, so return type is not specified.
    // It's basically a deep version of IErrors<ISchedule>.
    validate(schedule: ISchedule): any {
        const t = this.props.t;

        let configErrs: any = {};
        for (let i = 0; i < schedule.config.length; ++i) {

            const interval = schedule.config[i];
            const e = isValidTimeRange(timeFromIso(interval.timeBegin, false), timeFromIso(interval.timeEnd, false));
            if (e) configErrs[i] = { timeBegin: t(e), timeEnd: t(e) };
        }

        let exceptionErrs: any = {};
        for (let i = 0; i < schedule.exceptions.length; ++i) {

            const exception = schedule.exceptions[i];
            const e = isValidTimeRange(dateFromIso(exception.begin, false), dateFromIso(exception.end, false));
            if (e) exceptionErrs[i] = { begin: t(e), end: t(e) }

            const te = isValidTimeRange(timeFromIso(exception.timeBegin, false), timeFromIso(exception.timeEnd, false));
            if (te) exceptionErrs[i] = { ...exceptionErrs[i], timeBegin: t(te), timeEnd: t(te) }
        }

        let errs: any = {};
        const hasConfigErrs = Object.keys(configErrs).length > 0;
        const hasExceptionErrs = Object.keys(exceptionErrs).length > 0;

        if (hasConfigErrs) errs['config'] = configErrs;
        if (hasExceptionErrs) errs['exceptions'] = exceptionErrs;

        return (hasConfigErrs || hasExceptionErrs) ? errs : undefined;
    }


    async onFormSubmit(values: ISchedule) {
        for (let i = 0; i < values.config.length; ++i) {
            let schedule = values.config[i];
            schedule.position = i;
            schedule.monday = strToBool(schedule.monday);
            schedule.tuesday = strToBool(schedule.tuesday);
            schedule.wednesday = strToBool(schedule.wednesday);
            schedule.thursday = strToBool(schedule.thursday);
            schedule.friday = strToBool(schedule.friday);
            schedule.saturday = strToBool(schedule.saturday);
            schedule.sunday = strToBool(schedule.sunday);
        }

        for (let i = 0; i < values.exceptions.length; ++i) {
            let exception = values.exceptions[i];
            exception.position = i;
            exception.state = strToBool(exception.state);
        }

        await this.props.onSubmit(values);
    }

    render() {
        const t = this.props.t;
        const ns = 'module.dashboard';

        const dayButton = (props: ILabeledFieldProps) => <ToggleButton {...props.field} value={!strToBool(getIn(props.form.values, props.field.name))} selected={strToBool(getIn(props.form.values, props.field.name))}>
            {t(props.labelKey, {ns: props.namespace})}
        </ToggleButton>;

        const form = (props: FormikProps<ISchedule>) => (<Form className={'modal-form ' + this.props.className}>
            {this.state.activeTab === 0 && this.props.children}
            {this.state.activeTab === 0 && <FieldArray
                name="config"
                render={arrayHelpers => (
                    <Box>
                    {props.values.config.map((_schedule, index, _array) => (
                        <Card key={index} variant="outlined" sx={{ minWidth: 275, mb: '1rem', boxShadow: 2 }}>
                            <CardContent>
                                <Field name={`config.${index}.label`} labelKey="schedule-label" namespace={ns} component={MuiField} />
                                <Field name={`config.${index}.timeBegin`} labelKey="schedule-begin" namespace={ns} convertUTC={false} includeZone={false} component={MuiTimeField} />
                                <Field name={`config.${index}.timeEnd`} labelKey="schedule-end" namespace={ns} convertUTC={false} includeZone={false} component={MuiTimeField} />

                                <ToggleButtonGroup sx={{ flexWrap: 'wrap' }}>
                                    <Field name={`config.${index}.monday`} labelKey='time.weekday-1-abbrev' namespace='common' component={dayButton} />
                                    <Field name={`config.${index}.tuesday`} labelKey='time.weekday-2-abbrev' namespace='common' component={dayButton} />
                                    <Field name={`config.${index}.wednesday`} labelKey='time.weekday-3-abbrev' namespace='common' component={dayButton} />
                                    <Field name={`config.${index}.thursday`} labelKey='time.weekday-4-abbrev' namespace='common' component={dayButton} />
                                    <Field name={`config.${index}.friday`} labelKey='time.weekday-5-abbrev' namespace='common' component={dayButton} />
                                    <Field name={`config.${index}.saturday`} labelKey='time.weekday-6-abbrev' namespace='common' component={dayButton} />
                                    <Field name={`config.${index}.sunday`} labelKey='time.weekday-7-abbrev' namespace='common' component={dayButton} />
                                </ToggleButtonGroup>
                            </CardContent>

                            <CardActions disableSpacing>
                                <IconButton onClick={() => arrayHelpers.remove(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            </CardActions>
                        </Card>
                    ))}
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', columnGap: '1rem', rowGap: '8px' }}>
                        <Button variant="contained"
                            startIcon={<AddIcon />}
                            onClick={() => arrayHelpers.push(createDefaultPeriodicSchedule())}>
                            {t(`${ns}.add-schedule-interval`, {ns: ns})}
                        </Button>
                        <Button variant="contained" onClick={props.submitForm} startIcon={<SaveIcon />}>
                            {t('common.save', {ns: 'common'})}
                        </Button>
                    </Box>
                </Box>
                )}
            />}

            {this.state.activeTab === 1 && <FieldArray
                name="exceptions"
                render={arrayHelpers => (
                    <Box>
                    {props.values.exceptions.map((_exception, index, _array) => (
                        <Card key={index} variant="outlined" sx={{ minWidth: 275, mb: '1rem', boxShadow: 2 }}>
                            <CardContent>
                                <Field name={`exceptions.${index}`} component={ScheduleExceptionFields} />
                            </CardContent>

                            <CardActions disableSpacing>
                                <IconButton onClick={() => arrayHelpers.remove(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            </CardActions>
                        </Card>
                    ))}
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', columnGap: '1rem', rowGap: '8px' }}>
                        <Button variant="contained"
                            startIcon={<AddIcon />}
                            onClick={() => arrayHelpers.push(createDefaultScheduleException())}>
                            {t(`${ns}.add-schedule-exception`, {ns: ns})}
                        </Button>
                        <Button variant="contained" onClick={props.submitForm} startIcon={<SaveIcon />}>
                            {t('common.save', {ns: 'common'})}
                        </Button>
                    </Box>
                </Box>
                )}
            />}
            </Form>);

        return <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={this.state.activeTab} onChange={(_e, value) => this.setState({activeTab: value})}>
                    <Tab label={t(`${ns}.default-schedule`, {ns: ns})} />
                    <Tab label={t(`${ns}.schedule-exceptions`, {ns: ns})} />
                </Tabs>
            </Box>

            <br/>

            <Formik
                initialValues={this.props.values}
                onSubmit={this.onFormSubmit}
                component={form}
                validate={this.validate} validateOnChange={false} validateOnBlur={true}>
            </Formik>
      </Box>
    }
}

export default hoistStatics(withTranslation()(Schedule), Schedule)
export { strToBool }