import React, { ForwardedRef, useCallback, useRef } from 'react';
import { Box, Button, Card, CardContent, Grid, Typography, IconButton, debounce } from '@mui/material';
import { FieldArray, FieldArrayRenderProps, Form, Formik, FormikProps, getIn } from 'formik';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import ListIcon from '@mui/icons-material/List';

/**
 * TODO: fix access to translations.
 * Using i18next directly is probably ugly. However:
 * - withTranslation() breaks type parameter checking
 * - useTranslation() can be used only in functional components
 * - <Trans> and <Translation> return Element, not string
 */
import i18next from "i18next";
import { XYCoord, useDrag, useDrop } from 'react-dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DeleteIcon from '@mui/icons-material/Delete';
import composeRefs from '@seznam/compose-react-refs';


interface IListItemProps<TItem> {
    item: TItem,
    acceptType?: string,
    draggable?: boolean,
    index: number,
    id: string,
    selected: boolean,
    touched: boolean,
    error: string,
    name: (item: TItem) => string,
    selectItem: (index: number) => void,
    moveItem: (index: number, newIndex: number) => void,
    removeItem: (index: number) => void,
    dragBegin?: (item: DragItem, monitor: any) => void,
    additionalActions?: React.ReactNode,
}

export interface DragItem {
    index: number,
    id: string,
    name: string,
}

export const ListItem = React.forwardRef((props: IListItemProps<any>, ref: ForwardedRef<HTMLDivElement>) => {
    const draggable = props.draggable ?? true;
    const acceptType = props.acceptType ?? 'ListItem';
    const internalRef = useRef<HTMLDivElement>(null);

    const [{isDragging}, drag] = useDrag(() => ({
        type: acceptType,
        item: () => {
            const item = {
                index: props.index,
                id: props.id,
                name: props.name(props.item)
            };
            if (props.dragBegin)
                props.dragBegin(item, null);
            return item;
        },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    }), [props.index, props.id, props.name, props.dragBegin]);

    const dropHover = useCallback(debounce((item: DragItem, monitor) => {
        if (!internalRef.current)
            return;
        
        // item.index == dragIndex, props.index == dropIndex
        if (item.index === props.index) // don't replace items with themselves
            return;
        
        const hoverBoundingRect = internalRef.current?.getBoundingClientRect();
        if (!hoverBoundingRect) return;
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        const clientOffset = monitor.getClientOffset(); // mouse position
        if (!clientOffset) return;
        const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top; // pixels to the top

        // Dragging downwards: only move when the cursor is below 50%
        if (item.index < props.index && hoverClientY < hoverMiddleY)
            return;
        // Dragging upwards: only move when the cursor is above 50%
        if (item.index > props.index && hoverClientY > hoverMiddleY)
            return;
        
        props.moveItem(item.index, props.index);
        // mutating for the sake of performance to avoid expensive index searches.
        item.index = props.index;
    }, 50), [props.index, props.moveItem]); 

    const [{ handlerId, canDrop }, drop] = useDrop<DragItem, void, { handlerId: any, canDrop: boolean }>(() => ({
        accept: acceptType,
        hover: dropHover,
        collect: (monitor) => ({
            handlerId: monitor.getHandlerId(),
            canDrop: monitor.canDrop(),
        })
    }), [props.index, props.moveItem]);

    if (draggable)
        drag(drop(internalRef));
    return <Box ref={composeRefs(internalRef, ref)} data-handler-id={handlerId}
            className={'title' + (props.selected ? ' selected' : '') + (canDrop ? ' drag-active' : '') + (draggable ? ' draggable' : '')}
            onClick={() => props.selectItem(props.index)}
            sx={{
                opacity: isDragging ? 0 : 1,
                m: 0,
                color: props.touched && Boolean(props.error) ? 'error.main' : 'inherit',
                display: 'inline-flex',
                alignItems: 'center',
                width: '100%',
                minHeight: '40px',
                '& .action, & .drag, &.draggable:hover .index': { display: 'none' },
                '&.drag-active:hover .index': { display: 'block' },
                '&:hover .action, &.draggable:hover .drag': { display: 'block' },
                '&.drag-active .action, &.drag-active .drag, &.drag-active:hover .drag': { display: 'none' },
            }}>
                <DragIndicatorIcon className='drag' fontSize='small'
                    sx={{ opacity: 0.2, ml: '-.4em', width: '1.5em', mr: '.74em', cursor: 'pointer' }} />
                <Typography className='index' sx={{ flexShrink: 0, width: '1.5em', mr: '.8em', opacity: 0.4 }}>
                    {draggable ? props.index : '–'}
                </Typography>
                <Typography sx={{ flexGrow: 1, flexShrink: 1 }}>
                    {props.name(props.item)}
                </Typography>
                <div className='action external-action' style={{ flexShrink: 0, marginLeft: '.3em' }}>
                    {props.additionalActions}
                </div>
                <IconButton className='action delete' sx={{ flexShrink: 0, ml: '.1em' }}
                    onClick={(e) => { e.stopPropagation(); props.removeItem(props.index); return false; }}>
                    <DeleteIcon fontSize='small' />
                </IconButton>
        </Box>;
});

interface IFormList<TItem> {
    items: (TItem & { keyid: string })[]
}

interface IState {
    editedItem: number,
    showList: boolean,
}
interface IProps<TItem> {
    items: TItem[],
    enableDragDrop?: boolean,
    role: 'create' | 'update',
    className?: string,
    addLabelKey: string,
    addLabelNs?: string,
    expandListLabelKey?: string,
    collapseListLabelKey?: string,
    listLabelNs?: string, 
    itemName: (item: TItem) => string,
    itemForm: (item: TItem, itemNamePath: string) => JSX.Element,
    createItem: () => TItem,
    validate: (items: TItem[]) => any,
    onSubmit: (items: TItem[]) => Promise<any>,
    validateOnChange?: boolean,
    validateOnBlur?: boolean,
}

export class ListEditor<TItem> extends React.Component<IProps<TItem>, IState>
{
    static defaultProps = {
        addLabelKey: 'common.add-item',
        addLabelNs: undefined,
        enableDragDrop: true,
        validateOnChange: true,
        validateOnBlur: true,
    };
    
    listGridRef: React.RefObject<HTMLDivElement>;
    selectedItemRef: React.RefObject<HTMLDivElement>;

    constructor(props: IProps<TItem>) {
        super(props);

        this.state = {
            editedItem: this.props.items.length > 0 ? 0 : -1,
            showList: true,
        };

        this.identifyItem = this.identifyItem.bind(this);
        this.editItem = this.editItem.bind(this);
        this.renderList = this.renderList.bind(this);
        this.renderEditor = this.renderEditor.bind(this);
        this.renderForm = this.renderForm.bind(this);
        this.addItem = this.addItem.bind(this);
        this.moveItem = this.moveItem.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.getPropertyPath = this.getPropertyPath.bind(this);
        this.validate = this.validate.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.listGridRef = React.createRef<HTMLDivElement>();
        this.selectedItemRef = React.createRef<HTMLDivElement>();
    }

    // Naive function that generates pseudounique keys for list items.
    // The keys are used for list rendering.
    identifyItem(item: TItem): TItem & { keyid: string } {
        return { ...item, keyid: this.props.itemName(item) + Math.random() };
    }

    validate(list: IFormList<TItem>): any {
        const errors = this.props.validate(list.items);
        if (errors) return { items: errors };
        return {};
    }

    async onSubmit(list: IFormList<TItem>) {
        await this.props.onSubmit(list.items);
    }

    getPropertyPath(boardNamePath: string): string {
        return boardNamePath === '' ? '' : `${boardNamePath}.`;
    }

    editItem(index: number, callback?: () => void) {
        this.setState(() => ({
            editedItem: index,
        }), () => {
            this.selectedItemRef.current?.scrollIntoView({ block: 'nearest'});
            if (callback) callback();
        });
    }

    addItem(item: TItem, arrayHelpers: FieldArrayRenderProps, index: number) {
        arrayHelpers.insert(index, this.identifyItem(item));
        this.editItem(index);
    }
    
    moveItem(arrayHelpers: FieldArrayRenderProps, index: number, newIndex: number) {
        arrayHelpers.move(index, newIndex);
        this.editItem(newIndex);
    }

    removeItem(arrayHelpers: FieldArrayRenderProps, index: number) {
        const length = getIn(arrayHelpers.form.values, 'items').length ?? 0;

        const newEditedItem = Math.min(this.state.editedItem, length - 2);
        this.editItem(newEditedItem, () => arrayHelpers.remove(index));
    }

    renderList(list: IFormList<TItem>): JSX.Element {
        return <FieldArray name='items' render={arrayHelpers => {
            const move = (indexA: number, indexB: number) => this.moveItem(arrayHelpers, indexA, indexB);
            const remove = (index: number) => this.removeItem(arrayHelpers, index);

            return list.items.map((item, index) => {
                const selected = this.state.editedItem === index;
                const touched = getIn(arrayHelpers.form.touched, `items.${index}`);
                const error = getIn(arrayHelpers.form.errors, `items.${index}`);

                return <ListItem key={item.keyid}
                    ref={selected ? this.selectedItemRef : null}
                    item={item}
                    draggable={this.props.enableDragDrop}
                    index={index}
                    id={item.keyid}
                    selected={selected}
                    touched={touched}
                    error={error}
                    name={this.props.itemName}
                    selectItem={(index: number) => this.editItem(index)}
                    moveItem={move}
                    removeItem={remove} />;
            });
        }}/>;
    }

    renderEditor(formikProps: FormikProps<IFormList<TItem>>): JSX.Element | null {
        if (this.state.editedItem === -1)
            return null;
        
        const item: TItem = getIn(formikProps.values, `items.${this.state.editedItem}`);

        console.assert(item !== undefined && item !== null);
        if (!item)
            return null; // shouldn't happen, but return rather than cause a crash

        return <Card /*key={this.state.editedItem}*/ variant="outlined" sx={{ minWidth: 275, maxWidth: 400, mb: '1rem', boxShadow: 2, flexShrink: 0 }}>
            <CardContent>
                {this.props.itemForm(item, `items.${this.state.editedItem}`)}
            </CardContent>
        </Card>;
    }

    renderForm(formikProps: FormikProps<IFormList<TItem>>) {
        const overflowAuto = { height: { md: '100%' }, overflow: 'auto', maxWidth: { md: '40vw' } };
        const formStyle = { height: '100%', overflow: 'hidden', display: 'flex', flexDirection: 'column' };
        const button = { m: '16px 8px 0 8px !important', flexGrow: { xs: 1, md: 0 }, flexShrink: 0 };
        const t = i18next.t;

        // @ts-ignore // there seems to be a bug with CSS properties in Form[style] attribute
        return <Form className='modal-form' style={formStyle}>
            <Grid container columnSpacing={{ xs: 0, md: 2 }} rowSpacing={2}
                sx={{ flexGrow: 1, overflowX: 'hidden', overflowY: { xs: 'scroll', md: 'hidden'}, width: { xs: '100%', md: 'max-content' } }}>
                
                {formikProps.values.items.length > 0 && <Grid item xs={12} sx={{ display: { md: 'none' }}}>
                    <Button variant='outlined' startIcon={<ListIcon />} onClick={() => this.setState({ showList: !this.state.showList })}>
                        {(this.state.showList
                        ? t(this.props.collapseListLabelKey ?? "common.collapse-list", {ns: this.props.listLabelNs})
                        : t(this.props.expandListLabelKey ?? "common.expand-list", {ns: this.props.listLabelNs})) + ''}
                    </Button>
                </Grid>}
                {this.state.showList && <Grid item xs={12} md='auto' ref={this.listGridRef} sx={{ ...overflowAuto, width: { md: 'min(400px, 50vw)' } }} className='list'>
                    {this.renderList(formikProps.values)}
                </Grid>}
                <Grid item xs={12} md='auto' sx={{...overflowAuto, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                    {this.renderEditor(formikProps)}
                </Grid>
            </Grid>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                <FieldArray name='items' render={arrayHelpers =>
                    <Button className="crud-button" variant='contained' type="button" startIcon={<AddIcon />} sx={button}
                    onClick={() => this.addItem(this.props.createItem(), arrayHelpers, this.state.editedItem + 1)}>
                        {t(this.props.addLabelKey, {ns: this.props.addLabelNs}) + ''}
                    </Button>
                } />
                <Button className="crud-button" variant='contained' type="submit" startIcon={<SaveIcon />} sx={button}>
                    {t("common.save") + ''}
                </Button>
            </Box>
        </Form>;
    }

    render() {
        return <Formik style={{ height: '100%', overflow: 'hidden' }} initialValues={{ items: this.props.items.map(i => this.identifyItem(i)) }}
            validate={this.validate} onSubmit={this.onSubmit} component={this.renderForm}
            validateOnChange={this.props.validateOnChange} validateOnBlur={this.props.validateOnBlur} />;
    }
}
