import http from "../../system/Communicator";
import { Sorting } from "../../system/CRUDTable";
import ICrudService from "../../system/ICrudService"
import { IPagedResponse, PageQuery } from "../../system/Pagination.model";
import { combineQueries } from "../../system/RequestResponse.model";
import { IEntry, ISearchable } from "../../system/SearchResult.model";
import { createDefaultBoard, IBoard } from "./Boards.model";

class BoardService implements ICrudService<IBoard>, ISearchable<IBoard> {

	async search(text: string): Promise<IEntry<IBoard>[]> {
		const result = await this.getAll();
		return result.data
			.map((board, index) => { return { entity: board, index: index }})
			.filter(x => x.entity.title.toLocaleLowerCase('cs').includes(text.toLocaleLowerCase('cs')));
	}

	async getAll(pageQuery?: PageQuery, sort?: Sorting<IBoard>): Promise<IPagedResponse<IBoard>> {
		const pq = pageQuery?.toString() ?? '';
		const sq = sort?.toString() ?? '';
		const query = combineQueries(pq, sq);

		return (await http.get<IPagedResponse<IBoard>>(`/board?${query}`)).data;
	}
	
	async getById(id: Number): Promise<IBoard> {
		return (await http.get<IBoard>(`/board/${id}`)).data;
	}
	
	async create(entity: IBoard): Promise<IBoard> {
		return (await http.post<IBoard>("/board", entity)).data;
	}
	
	async delete(entity: IBoard): Promise<void> {
		await http.delete<void>("/board/" + entity.id);
	}
	
	async edit(entity: IBoard): Promise<IBoard> {
		return (await http.patch<IBoard>("/board/" + entity.id, entity)).data;
	}

	createDefaultEntity = createDefaultBoard;
}
export default new BoardService();