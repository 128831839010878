import React from 'react';
import hoistStatics from 'hoist-non-react-statics';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SketchPicker, ColorResult } from 'react-color';
import { IconButton, Popover } from '@mui/material';
import ColorizeIcon from '@mui/icons-material/Colorize';
import { CustomThemeContext }  from './CustomThemeProvider';

interface IState {
	popperOpened : boolean
	colorIsDark : boolean
}

interface IProps extends WithTranslation {
	value : string,
	onChange? : ( value : string ) => void,
}

class ColorPicker extends React.Component<IProps, IState, WithTranslation> {

/*
	Amos colors:
	#6ac97e
	#af242d
	#0084bc
	#5e4d9e
	#8e2f5d
	#c94069
	#ff8644
	#3fb1f5
	#f6bb4f
*/

	parRef = React.createRef();

	constructor(props: IProps) {
		super(props);
		
		this.state = {	
			popperOpened : false,
			colorIsDark : ColorPicker.isDarkColor(props.value)
		}
		
		this.handleChange = this.handleChange.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleOpen = this.handleOpen.bind(this);
	}
	
	static isDarkColor(hex : string) {
		if (hex.substring(0, 1) == "#")
			hex = hex.substring(1);
			
		var rgb = parseInt(hex, 16);   // convert rrggbb to decimal
		var r = (rgb >> 16) & 0xff;  // extract red
		var g = (rgb >>  8) & 0xff;  // extract green
		var b = (rgb >>  0) & 0xff;  // extract blue

		var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
		
		return luma < 128;
	}
	
	handleChange(color : ColorResult, event : any) {
		this.props.onChange?.(color.hex);
	}
	
	handleOpen() {
		this.setState({ popperOpened : !this.state.popperOpened });
	}
	
	handleClose() {
		this.setState({ popperOpened : false });
	}

	render() {
		return <CustomThemeContext.Consumer>{ theme => (<div className="color-picker">
			<div className="content" ref={this.parRef as React.RefObject<HTMLDivElement>}>
				<span className="hex-span" onClick={this.handleOpen}>{this.props.value}</span>
				<IconButton className={"preview-box " + (ColorPicker.isDarkColor(this.props.value) ? "dark" : "light") } style={{background : this.props.value}}
					 onClick={this.handleOpen}>
					 <ColorizeIcon />
				</IconButton>
				<div style={{clear:"both"}}></div>
			</div>
			<Popover
				open={ this.state.popperOpened }
				onClose={ this.handleClose }
				anchorEl={ this.parRef.current as Element }
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				  transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<SketchPicker color={ this.props.value } onChange={ this.handleChange } disableAlpha={true} 
					className={theme.currentTheme.mode}
					presetColors={[
						"#6ac97e",
						"#af242d",
						"#0084bc",
						"#5e4d9e",
						"#8e2f5d",
						"#c94069",
						"#ff8644",
						"#3fb1f5",
						
						"#f6bb4f",						
						"#ffffff",
						"#e2e2e2",
						"#b5b5b5",						
						"#888888",
						"#5a5a5a",
						"#2d2d2d",
						"#000000"
					]}/>
			</Popover>
		</div>)}</CustomThemeContext.Consumer>
	}
}

export default hoistStatics(withTranslation()(ColorPicker), ColorPicker) 