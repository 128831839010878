import React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import { Typography } from '@mui/material';
import { IKiosk } from '../kiosks/Kiosks.model'
import { withTranslation, WithTranslation } from 'react-i18next'
import hoistStatics from 'hoist-non-react-statics';
import KioskDetail from './KioskDetail';
import LocalKioskService from '../kiosks/LocalKiosk.service';
import IModule, { ModuleState } from "../../system/IModule"
import { ControllerDeployment, controllerKeys, hasController, isAuthorized, IUserContext, permissions } from '../../system/User.model'
import { AuthContext } from '../../system/Base'
import { setPageTitle } from '../../App'

interface IProps extends WithTranslation {
}

interface IState {
	kiosk? : IKiosk,
}

@IModule
class Dashboard extends React.Component<IProps, IState, WithTranslation> {

	protected reloadInterval: any | null = null;

	public static menu(t: ((x:string, y:any)=>string)) {
		return {		
			title: (t && t(this.getLocale()+".title", {ns:this.getLocale()})) || "???",
			route: "/dashboard",
			icon: <HomeIcon />,
			weight : 0
		};
	}
	
	public static search(input : string) {
		return null;
	}

	public static getLocale(): string {
        return 'module.dashboard';
    }
	
	public static isEnabled(auth: IUserContext) { 
		if (!hasController(auth, controllerKeys.kiosk, ControllerDeployment.Local))
			return ModuleState.DISABLED;
		if (isAuthorized(auth, permissions.kiosk.read))
			return ModuleState.ENABLED;
			
		return ModuleState.NO_PERMISSIONS;
	}	
	
	constructor(props: IProps) {
		super(props);

		this.state = {
		};

		this.reload = this.reload.bind(this);
	}

	async componentDidMount() {
		setPageTitle(this.props.t( Dashboard.getLocale()+".title", { ns: Dashboard.getLocale() }));
		
		await this.reload();

		if (this.reloadInterval === null)
			this.reloadInterval = setInterval(() => this.reload(), 1 * 60 * 1000);
	}

	componentWillUnmount() {
		clearInterval(this.reloadInterval);
		this.reloadInterval = null;
	}


	async reload(): Promise<void> {
		const kiosk = (await LocalKioskService.getLocal());	
		this.setState({
			kiosk: kiosk
		});
	}

	render() {
		return (<AuthContext.Consumer>{auth => (<div className="dashboard">
		<Typography variant="h2">
			{ this.props.t("module.dashboard.title", {ns: "module.dashboard"}) }
		</Typography>
		
		{ this.state.kiosk &&
			<KioskDetail
				kiosk={this.state.kiosk!}
				onClose={() => {}}
				onRequestReload={() => {}}
			/>		
		}
		
		</div>)}</AuthContext.Consumer>);
	}
}

export default hoistStatics(withTranslation()(Dashboard), Dashboard)