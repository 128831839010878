import { Button } from "@mui/material"
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import React from "react";
import { Translation } from "react-i18next";

interface IState {}

interface IProps {
    variant: 'contained' | 'outlined',
    role: 'create' | 'update' | 'delete',
    disabled?: boolean,
    onClick?: React.MouseEventHandler<HTMLButtonElement>,
}

export class CRUDButton extends React.Component<IProps, IState> {
    static defaultProps = { disabled: false };

	render() {
        const icon = this.props.role === 'create' ? <AddIcon />
        : this.props.role === 'update' ? <EditIcon />
        : <DeleteIcon />;

        return <Button className="crud-button" variant={this.props.variant} type="submit" disabled={this.props.disabled} onClick={this.props.onClick} startIcon={icon}>
            <Translation>{t => t("crud." + this.props.role)}</Translation>
        </Button>;
    }
}