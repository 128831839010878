import React, { Fragment, ReactElement } from 'react';
import GroupIcon from '@mui/icons-material/Group';
import { Typography, Button } from '@mui/material';
import UserService from './Users.service';
import { withTranslation, WithTranslation } from 'react-i18next'
import DataTable, { Attribute } from '../../system/DataTable'
import hoistStatics from 'hoist-non-react-statics';
import KeyIcon from '@mui/icons-material/Key';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import { IErrors } from '../../system/CRUDForm';
import { IUser } from './Users.model';
import { CRUDOperation } from '../../system/CRUDOperation';
import { Modal } from '../../system/Modal';
import PermissionChange from './PermissionChange';
import IModule, { ModuleState } from "../../system/IModule"
import { setPageTitle } from '../../App'
import { controllerKeys, hasController, isAuthorized, IUserContext, permissions } from '../../system/User.model'
import PasswordOverwrite from './PasswordOverwrite';
import { AuthContext } from '../../system/Base';

interface IState {
	editedUserId: number | null,
	editingPassword: boolean,
	editingPermissions: boolean
}
interface IProps extends WithTranslation { }

interface IUserExt extends IUser {
	passwordOverwrite: any, // TODO
	permissionChange: any // TODO
}

@IModule
class Users extends React.Component<IProps, IState, WithTranslation> {

	public constructor(props: IProps) {
		super(props);

		this.state = {
			editedUserId: null,
			editingPassword: false,
			editingPermissions: false
		};

		this.validate = this.validate.bind(this);
		this.onChange = this.onChange.bind(this);
	}

	public static getLocale() { return "module.users";	}
	
	componentDidMount()
	{
		setPageTitle(this.props.t( Users.getLocale()+".title", { ns: Users.getLocale() }));
	}
	
	public static menu(t: ((x:string, y:any)=>string)) {
		return {
			title: (t && t(Users.getLocale()+".title", {ns:Users.getLocale()})) || "???",
			route : "/users",
			icon : <GroupIcon />,
			weight : 150
		};
	}
	
	public static search(input : string) {
		return null;
	}
	
	public static isEnabled(auth: IUserContext) {
		if (!hasController(auth, controllerKeys.user))
			return ModuleState.DISABLED;
		if (isAuthorized(auth, permissions.user.read))
			return ModuleState.ENABLED;
			
		return ModuleState.NO_PERMISSIONS;
	}

	onChange(user: IUser, operation: CRUDOperation, auth: IUserContext) {
		if (operation === CRUDOperation.Create && user.id && isAuthorized(auth, permissions.auth.modifyPermissions))
			this.showPermissionForm(user.id);
	}

	validate(values: IUser, operation: CRUDOperation) {
		let errors = {} as IErrors<IUser>;
		const e = this.props.t('validation.value-empty');

		if (operation !== CRUDOperation.Create && !values.id)
			errors.id = e;
		
		if (operation !== CRUDOperation.Delete) {
			if (!values.name)
				errors.name = e;
			
			if (!values.login)
				errors.login = e;
		}

		if (operation === CRUDOperation.Create && !values.password) {
			errors.password = e;
		}

		return errors;
	}

	showPasswordForm(userId: number | null) {
		this.setState({
			editedUserId: userId,
			editingPassword: userId ? true : false
		});
	}

	showPermissionForm(userId: number | null, auth?: IUserContext) {
		const editedUserId = this.state.editedUserId;

		this.setState({
			editedUserId: userId,
			editingPermissions: userId ? true : false
		}, () => {
			if (!userId && editedUserId === auth?.id) {
				// FIX ME: This is a hack to reload auth permissions.
				// Reload is needed if the current user had changed their own permissions.
				window.location.reload();
			}
		});
	}

	render() {
	
		const t = this.props.t;
		const ns = Users.getLocale();
		
		const PasswordOverwriteAction = (u: IUserExt) => <div>
			<Button variant="outlined" type="submit" onClick={() => this.showPasswordForm(u.id!)} startIcon={<KeyIcon />}>
				{t("module.users.set-password", {ns:'module.users'})}
			</Button>
		</div>;

		const PermissionChangeAction = (u: IUserExt) => <div>
			{u.id === 1 && t("module.users.all-permissions", {ns:'module.users'})}
			{u.id !== 1 &&
			<Button variant="outlined" type="submit" onClick={() => this.showPermissionForm(u.id!)} startIcon={<LocalPoliceIcon />}>
				{t("module.users.change-permissions", {ns:'module.users'})}
			</Button>}
		</div>;
	
		return (
		<Fragment>
		<Typography variant="h2">
			{ t(Users.getLocale()+".title", {ns:Users.getLocale()}) }
		</Typography>
		
		<AuthContext.Consumer>{(auth) => {
			const attributes = (<>
				<Attribute name="id" type="number" labelKey="id" namespace={ns} hideInCreateForm hideInUpdateForm hideInTable />
				<Attribute name="name" labelKey="name" namespace={ns} />
				<Attribute name="login" labelKey="login" namespace={ns} />
				<Attribute name="password" type="password" labelKey="password" namespace={ns} sortable={false} hideInUpdateForm hideInTable />

				{isAuthorized(auth, permissions.user.setPassword) && <Attribute name="passwordOverwrite" labelKey="password" namespace={ns}
					sortable={false} hideInUpdateForm hideInCreateForm tableValueResolver={PasswordOverwriteAction} />}
				
				{isAuthorized(auth, permissions.auth.modifyPermissions) && <Attribute name="permissionChange" labelKey="permissions" namespace={ns}
					sortable={false} hideInUpdateForm hideInCreateForm tableValueResolver={PermissionChangeAction} />}
			</>);
			
			return <DataTable service={UserService} entityName={t("module.users.entity", {ns:'module.users'})} entityType='users'
				permissions={permissions.user} create={true} edit={true} delete={true} validate={this.validate} onChange={(user, operation) => this.onChange(user, operation, auth)}>			
					{attributes.props.children.filter((attr: ReactElement | boolean | null) => attr)}
			</DataTable>;
		}}
		</AuthContext.Consumer>

		{this.state.editedUserId && this.state.editingPassword &&
			<Modal title={t("module.users.set-password", {ns:'module.users'})} isOpen={true} onClose={() => this.showPasswordForm(null)}>
                <PasswordOverwrite userId={this.state.editedUserId} onDone={() => this.showPasswordForm(null)}/>
			</Modal>}
		
		{this.state.editedUserId && this.state.editingPermissions &&
			<Modal title={t("module.users.change-permissions", {ns:'module.users'})} isOpen={true} onClose={() => this.showPermissionForm(null)}>
				<AuthContext.Consumer>{(auth) => (
                	<PermissionChange userId={this.state.editedUserId!} onDone={() => this.showPermissionForm(null, auth)}/>
				)}</AuthContext.Consumer>
			</Modal>}
		
		</Fragment>);
	}
}

export default hoistStatics(withTranslation()(Users), Users)