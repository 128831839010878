import { Field, FieldInputProps, Form, Formik, FormikProps, FormikValues } from "formik"
import React from "react"
import { IAttribute } from "./CRUDAttribute"
import { CRUDButton } from "./CRUDButton"
import { CRUDOperation } from "./CRUDOperation";
import MuiField from "./MuiField";
/**
 * TODO: fix access to translations.
 * Using i18next directly is probably ugly. However:
 * - withTranslation() breaks type parameter checking
 * - useTranslation() can be used only in functional components
 * - <Trans> and <Translation> return Element, not string
 */
import i18next from "i18next";

export interface IEntity extends FormikValues {
}

export type IErrors<TEntity> = {
    [K in keyof TEntity]?: string
};

export interface IFormConfig<TEntity> {
    title: string,
    message?: string,
	onSubmit: (_: TEntity) => Promise<any>,
    validate?: (e: TEntity, o: CRUDOperation) => IErrors<TEntity>
}

export interface IFormProps<TEntity extends IEntity> extends IFormConfig<TEntity> {
    entity: TEntity,
    role: CRUDOperation,
    attributes: IAttribute<TEntity>[]
}

export interface IFieldProps<TEntity extends IEntity, TAttribute> {
    field: FieldInputProps<TAttribute>,
    form: FormikProps<TEntity>,
    entity: TEntity,
    labelKey: string,
    namespace: string 
}

interface IState<TEntity> {
    entity: TEntity
}

export class CRUDForm<TEntity extends IEntity> extends React.Component<IFormProps<TEntity>, IState<TEntity>> {

    constructor(props: IFormProps<TEntity>) {		
		super(props);

		this.state = {
            entity: this.props.entity
		};

        this.shoudlDisplayField = this.shoudlDisplayField.bind(this);
        this.validate = this.validate.bind(this);
	}

    componentDidUpdate(prevProps: IFormProps<TEntity>) {
        if (this.props.entity !== prevProps.entity) {
            this.setState({ entity: this.props.entity });
        }
    }

    shoudlDisplayField(attribute: IAttribute<TEntity>): boolean {
        return (!attribute.hideInCreateForm || this.props.role !== 'create')
            && (!attribute.hideInUpdateForm || this.props.role !== 'update')
            && (!attribute.hideInDeleteForm || this.props.role !== 'delete');
    }

    validate(entity: TEntity): IErrors<TEntity> {
        // if there is a custom validation function provided, use it
        if (this.props.validate)
            return this.props.validate(entity, this.props.role);

        // otherwise validate each attribute separately using its validator
        let errors = { } as IErrors<TEntity>;

        for (let attr of this.props.attributes) {
            if (attr.validator) {
                const error = attr.validator(entity, attr.name, this.props.role);
                if (error) errors[attr.name] = i18next.t(error);
            }
        }
        
        return errors;
    }

    render() {
        const submit = <CRUDButton variant="contained" role={this.props.role} />;

        const form = <Form className="modal-form">
            {this.props.message && <div className="message">{this.props.message}</div>}

            {this.props.role !== 'delete' &&
            this.props.attributes.filter(a => this.shoudlDisplayField(a)).map(a =>
                <div key={a.name.toString()} className="field-container">
                    <Field name={a.name}
                        labelKey={a.labelKey}
                        namespace={a.namespace}
                        type={a.type ?? "text"}
                        component={!a.formComponent || (typeof a.formComponent === 'string') ? MuiField : a.formComponent}
                        variant={(typeof a.formComponent === 'string') ? a.formComponent : undefined}
                        entity={this.state.entity}
                        readOnly={a.readOnly} />
                </div>
            )}
            {submit}
        </Form>;

        return <>
                <Formik
                    initialValues={this.state.entity}
                    validate={this.validate}
                    onSubmit={this.props.onSubmit}>
                {form}
                </Formik>
            </>; 
    }
}