import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import hoistStatics from "hoist-non-react-statics";
import { Pagination as MUIPagination, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Typography, Grid, Skeleton, Button, CircularProgress } from "@mui/material";
// import { IconButton, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import IModule, { ModuleState } from "../../system/IModule"
import { setPageTitle } from '../../App'
import { ControllerDeployment, controllerKeys, hasController, isAuthorized, IUserContext, permissions } from '../../system/User.model'
import GlobalStatisticsService, { BaseStatisticService } from "./Statistics.service";
import LocalStatisticsService from "./LocalStatistics.service";
import { Chart as ChartJS, TimeScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartData, TooltipItem, Chart } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { ReportType, ITimeFilter, createDefaultTimeFilter, IRemoteActivity, IButtonLabelRow, IButtonPositionRow, isButtonLabelRow, isButtonPositionRow, ActivityDomain, processContent, IContentFilter, createDefaultContentFilter, getReportMeta, IReportMeta } from "./Statistics.model";
import { DateTime } from "luxon";
import 'chartjs-adapter-luxon';
import MuiField from "../../system/MuiField";
import { Field, Form, Formik, FormikProps } from "formik";
import { Box } from "@mui/system";
import MuiDateTimeField, { datetimeFromIso } from "../../system/MuiDateTimeField";
import { PageQuery, Pagination } from "../../system/Pagination.model";
import BarChartIcon from '@mui/icons-material/BarChart';
// import LaunchIcon from '@mui/icons-material/Launch';
import ReplayIcon from '@mui/icons-material/Replay';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import DescriptionIcon from '@mui/icons-material/Description';
import { IPreKioskFilter, processKiosks } from "../kiosks/Kiosks.model";
import { CustomThemeContext } from "../../system/CustomThemeProvider";
import KioskSelect from "../kiosks/KioskSelect";
import { AuthContext } from "../../system/Base";
import { ILabeledFieldProps } from "../../system/Formik.model";
import { stripSpan } from "../dashboard/Button.model";
import FilesService from "../files/Files.service";
import { Modal } from '../../system/Modal';
import { dataToRecord } from "../files/Files.model";

ChartJS.register(
    TimeScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);


const initialOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        x: {
            type: 'time',
            time: {
                unit: 'day',
                isoWeekday: true,
                displayFormats: {
                    week: 'W',
                }
            },
            title: {
                display: true,
                text: ''
            },
            adapters: {
                date: {
                    locale: 'cs'
                }
            },
            bounds: 'data'
        },
        y: {
            title: {
                display: true,
                text: ''
            },
            type: 'linear',
        }
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: 'Přehled',
      },
      tooltip: {
        mode: 'nearest',
        displayColors: false,
        callbacks: {
            title: function(context: TooltipItem<'bar'>[]) { return ''; },
            afterLabel: function(context: TooltipItem<'bar'>) { return ''; }
        }
      }
    },
  };


interface IReportParams {
    reportType: ReportType;
    timeFilter: ITimeFilter;
    kioskFilter: IPreKioskFilter;
    contentFilter: IContentFilter;
    export: boolean;
}

interface IState {
    refreshed: boolean;
    options: any; //: ChartOptions<"bar">; // FIX ME
    data: ChartData<"bar", Object[]>;
    reportParams: IReportParams;
    allEntries?: IRemoteActivity[];
    allPagination: Pagination;
    buttonLabelEntries?: IButtonLabelRow[];
    buttonPositionEntries?: IButtonPositionRow[];
    sort: { key: string, desc: boolean };
    isLoading: boolean;
    isChangingPage: boolean;
    downloadingReport: string | null;
    prevReportsShown: boolean;
    prevReports: IReportMeta[];
}
interface IProps extends WithTranslation {
}

@IModule
class Statistics extends React.Component<IProps, IState, WithTranslation> {

	static contextType: React.Context<IUserContext> = AuthContext;
	context!: React.ContextType<React.Context<IUserContext>>;

    reportTypes = [ ReportType.All, ReportType.ButtonLabel, ReportType.ButtonPosition ];
    domainTypes = [ ActivityDomain.Any, ActivityDomain.Banner, ActivityDomain.Board, ActivityDomain.ExternalWebsite, ActivityDomain.FileExplorer, ActivityDomain.HomepageButton, ActivityDomain.PdfViewer ];

    formikRef: React.RefObject<FormikProps<IReportParams>>;

    fileService = FilesService;

	constructor(props: IProps) {
		super(props);

        const ns = Statistics.getLocale();
        const options = initialOptions;
        options.plugins.tooltip.callbacks.afterLabel = (context: TooltipItem<'bar'>) => {
            // @ts-ignore // FIX ME: pass datapoint type to chart
            return context.raw['completeData'] ? '' : this.props.t(`${ns}.incomplete-data`, {ns});
        }

        this.state = {
            refreshed: false,
            options,
            data: {
                datasets: []
            },
            reportParams: {
                reportType: ReportType.All,
                timeFilter: createDefaultTimeFilter(),
                kioskFilter: {
                    kiosks: [],
                    include: [],
                    exclude: []
                },
                contentFilter: createDefaultContentFilter(),
                export: false
            },
            allEntries: undefined,
            allPagination: new Pagination(0, null, 20),
            buttonLabelEntries: undefined,
            buttonPositionEntries: undefined,
            sort: { key: 'label', desc: false },
            isLoading: false,
            isChangingPage: false,
            downloadingReport: null,
            prevReportsShown: false,
            prevReports: [],
        };

        this.formikRef = React.createRef<FormikProps<IReportParams>>();

        this.setChartTexts = this.setChartTexts.bind(this);
        this.submit = this.submit.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.searchPath = this.searchPath.bind(this);
        this.openPath = this.openPath.bind(this);
        this.changePage = this.changePage.bind(this);
        this.sortBy = this.sortBy.bind(this);
        this.domainField = this.domainField.bind(this);
        this.form = this.form.bind(this);
        this.renderDetail = this.renderDetail.bind(this);
        this.setColors = this.setColors.bind(this);
        this.showPrevReports = this.showPrevReports.bind(this);
        this.deleteReport = this.deleteReport.bind(this);
        this.downloadReport = this.downloadReport.bind(this);
	}

	relevantStatService(): BaseStatisticService {
		if (hasController(this.context, controllerKeys.statistics, ControllerDeployment.Global))
			return GlobalStatisticsService;
        return LocalStatisticsService;
	}
	
	public static getLocale() { return "module.statistics"; }
	
	public static menu(t: ((x:string, y:any)=>string)) {
		return {
			title: (t && t(Statistics.getLocale()+".module-title", {ns:Statistics.getLocale()})) || "???",
			route : "/statistics",
			icon : <BarChartIcon />,
			weight : 100
		};
	}
	
	public static async search(input : string) {
		return null; // TODO?
	}
	
	public static isEnabled (auth: IUserContext) {
		if (!hasController(auth, controllerKeys.statistics))
			return ModuleState.DISABLED;
		if (isAuthorized(auth, permissions.statistics.read) && isAuthorized(auth, permissions.kiosk.read))
			return ModuleState.ENABLED;
			
		return ModuleState.NO_PERMISSIONS;
	}

    async componentDidMount() {
		setPageTitle(this.props.t( Statistics.getLocale()+".module-title", { ns: Statistics.getLocale() }));
        
        this.setChartTexts(this.props.i18n.language);
        this.props.i18n.on('languageChanged', this.setChartTexts);

        // refresh straight away on local -- it is not time consuming
        if (!hasController(this.context, controllerKeys.statistics, ControllerDeployment.Global))
            this.refresh();
    }

    componentWillUnmount(): void {
        this.props.i18n.off('languageChanged', this.setChartTexts);
    }

    setColors(mode: string) {
        const c = mode === 'dark' ?
            '255, 255, 255'
            : '0, 0, 0';
        Chart.defaults.backgroundColor = `rgba(${c}, 0.1)`;
        Chart.defaults.borderColor = `rgba(${c}, 0.1)`;
        Chart.defaults.color = `rgba(${c}, 1)`;
    }

    async submit(shouldExport: boolean) {
        const formik = this.formikRef.current;
        if (!formik) return;

        formik.setFieldValue('export', shouldExport, false);
        await formik.submitForm();
    }

    onSubmit(values: IReportParams) {
        const typeChanged = values.reportType !== this.state.reportParams.reportType;
        const pagination = typeChanged ?
            new Pagination(0, null, this.state.allPagination.perPage)
            : this.state.allPagination;

        this.setState({
            reportParams: values,
            allPagination: pagination
        }, async () => {
            const promises = [];
            if (values.export)
                promises.push(this.export());
            promises.push(this.refresh());
            await Promise.all(promises);
        });
    }

    async searchPath(path: string) {
        const formik = this.formikRef.current;
        if (!formik) return;

        formik.setFieldValue('path', path);
        await formik.submitForm();
    }

    openPath(path: string) {
        window.open(path, '_blank', 'noreferrer');
    }

    changePage(_event: React.ChangeEvent<unknown>, newPage: number) {
        this.setState({ isChangingPage: true }, async () => {
            await this.refreshTableData(new PageQuery(newPage, this.state.allPagination.perPage));
            this.setState({ isChangingPage: false });
        });
    }

    sortBy(key: keyof IButtonLabelRow, data: IButtonLabelRow[], desc?: boolean): void;
    sortBy(key: keyof IButtonPositionRow, data: IButtonPositionRow[], desc?: boolean): void;
    sortBy(key: string, data: any[], desc?: boolean) {
        if (data.length === 0)
            return;
        
        const isLabel = isButtonLabelRow(data[0]);
        const isPosition = isButtonPositionRow(data[0]);
        if (!isLabel && !isPosition) return;

        desc = desc ?? (this.state.sort.key === key ? !this.state.sort.desc : false);
        const sign = desc ? -1 : 1;

        data = data.sort((a, b) => {
            if (typeof a[key] === 'string')
                return sign * String(a[key]).localeCompare(String(b[key]), this.props.i18n.language);
            if (typeof a[key] === 'number')
                return sign * (Number(a[key]) - Number(b[key]));
            return 0;
        });

        if (isLabel)
            this.setState({ buttonLabelEntries: data, sort: { key, desc } });
        if (isPosition)
            this.setState({ buttonPositionEntries: data, sort: { key, desc } });
    }

    async export() {
        const rp = this.state.reportParams;
        const statService = this.relevantStatService();

        if (rp.reportType === ReportType.All)
            await statService.getAllExport(rp.timeFilter, processKiosks(rp.kioskFilter), processContent(rp.contentFilter));
        
        if (rp.reportType === ReportType.ButtonLabel)
            (await statService.getButtonLabelExport(rp.timeFilter, processKiosks(rp.kioskFilter)));
        
        if (rp.reportType === ReportType.ButtonPosition)
            (await statService.getButtonPositionExport(rp.timeFilter, processKiosks(rp.kioskFilter)));
    }

    refresh() {
        this.setState({ isLoading: true, refreshed: true }, async () => {
            const chartPromise = this.refreshChart();
            const tablePromise = this.refreshTableData();
            await Promise.all([ chartPromise, tablePromise ]);
            this.setState({ isLoading: false });
        });
    }

    async refreshChart() {
        const rp = this.state.reportParams;
        const statService = this.relevantStatService();

        const chartData = rp.reportType === ReportType.All ?
            await statService.getAllNavigation(rp.timeFilter, processKiosks(rp.kioskFilter), processContent(rp.contentFilter))
            : await statService.getButtonClicks(rp.timeFilter, processKiosks(rp.kioskFilter));

        const { unit } = statService.getBestTimeStep(rp.timeFilter);
        const options = {...this.state.options};
        options.scales.x.time.unit = unit;
        
        this.setState({
            options: options,
            data: {
                labels: chartData.map(c => c.time),
                datasets: [
                    {
                        data: chartData.map(c => ({ x: c.time, y: c.rate, completeData: c.completeData }))
                    }
                ]
            }
        }, () => this.setChartTexts(this.props.i18n.language));
	}

    async refreshTableData(pageQuery?: PageQuery) {
        const rp = this.state.reportParams;
        const statService = this.relevantStatService();

        const allPaged = rp.reportType === ReportType.All ?
            await statService.getAll(rp.timeFilter, processKiosks(rp.kioskFilter), processContent(rp.contentFilter), pageQuery ?? this.state.allPagination.getQuery())
            : undefined;
        
        let label = rp.reportType === ReportType.ButtonLabel ?
            (await statService.getButtonLabel(rp.timeFilter, processKiosks(rp.kioskFilter))).data
            : undefined;
        
        const position = rp.reportType === ReportType.ButtonPosition ?
            (await statService.getButtonPosition(rp.timeFilter, processKiosks(rp.kioskFilter))).data
            : undefined;
        
        if (allPaged) {
            this.setState({
                allEntries: allPaged?.data ?? this.state.allEntries,
                allPagination: allPaged ? new Pagination(allPaged.total, allPaged.current, this.state.allPagination.perPage) : this.state.allPagination,
            });
        }
        else if (label) {
            label = label.map(bl => ({label: stripSpan(bl.label), clicks: bl.clicks }));
            const unique = new Map<string, IButtonLabelRow>();
            const duplicit = new Array<string>();

            for (let bl of label) {
                if (unique.has(bl.label)) {
                    unique.get(bl.label)!.clicks += bl.clicks;
                    duplicit.push(bl.label);
                }
                else unique.set(bl.label, bl);
            }
            console.log(`Merging ${label.length - unique.size} entries with ~same labels.`, duplicit);
            label = Array.from(unique.values());
            this.sortBy('label', label, false);
        }
        else if (position)
            this.sortBy('position', position, false);
    }

    private setChartTexts(lang: string) {
        const t = this.props.t;
        const ns = Statistics.getLocale();
        const options = {...this.state.options};
        options.scales.x.adapters.date.locale = lang;

        const rp = this.state.reportParams;
        options.plugins.title.text = rp.reportType === ReportType.All ?
            t(`${ns}.total-activity`, {ns})
            : t(`${ns}.total-clicks`, {ns});
        options.scales.y.title.text = rp.reportType === ReportType.All ?
            '' : t(`${ns}.clicks-number`, {ns});
            
        options.scales.x.title.text = t(`time.${options.scales.x.time.unit}`)

        this.setState({ options });
    }

    private domainField(props: ILabeledFieldProps<ActivityDomain>) {
        return <MuiField variant='select' {...props}>
            {this.domainTypes.map((type) =>
                <MenuItem key={type} value={type}>
                    {this.props.t(`${props.namespace}.${type.toLowerCase()}`, {ns: props.namespace})}
                </MenuItem>
            )}
        </MuiField>
    }

    async showPrevReports(show: boolean) {
        this.setState({ prevReportsShown: show });
        if (!show) return;

        const reports = await this.fileService.getAll('statistics');
        const parsed = reports.filter(r => r).map(r => getReportMeta(dataToRecord(r!), this.props.i18n.language));

        this.setState({ prevReports: parsed });
    }

    async deleteReport(report: IReportMeta) {
        await this.fileService.deleteItem('statistics', '/' + report.name);
        await this.showPrevReports(true);
    }

    async downloadReport(report: IReportMeta) {
        this.setState({ downloadingReport: report.name }, async () => {
            await this.fileService.download(report.url, report.name);
            this.setState({ downloadingReport: null });
        });
    }

    private form(props: FormikProps<IReportParams>) {
        const t = this.props.t;
        const ns = Statistics.getLocale();
        const buttonStyles = { flexShrink: 0, flexGrow: 1 };

        return <Form>
            <Field name='reportType' labelKey='report-type' namespace={ns} sx={{display: 'flex'}} variant='select' component={MuiField}>
                {this.reportTypes.map((type) =>
                    <MenuItem key={type} value={type}>
                        {t(`${ns}.report-${type.toLowerCase()}`, {ns: ns})}
                    </MenuItem>
                )}
            </Field>

            <Grid container columnSpacing={2}>
                <Grid item xs={12} lg={6}>
                    <Field name='timeFilter.from' labelKey='report-from' namespace={ns} convertUTC={true} component={MuiDateTimeField} />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Field name='timeFilter.to' labelKey='report-to' namespace={ns} convertUTC={true} component={MuiDateTimeField} />
                </Grid>
            </Grid>
            <AuthContext.Consumer>{auth =>
                hasController(auth, controllerKeys.kiosk, ControllerDeployment.Global)
                && <Field name='kioskFilter' component={KioskSelect} />
            }</AuthContext.Consumer>
            
            {props.values.reportType === ReportType.All
                && <>
                <Grid container columnSpacing={2}>
                    <Grid item xs={12} sm={6} lg={6}>
                        <Field name='contentFilter.source' labelKey='report-source' namespace={ns} component={this.domainField} />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6}>
                        <Field name='contentFilter.sourceDetail' labelKey='report-source-detail' namespace={ns} component={MuiField} />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6}>
                        <Field name='contentFilter.target' labelKey='report-target' namespace={ns} component={this.domainField} />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6}>
                        <Field name='contentFilter.targetDetail' labelKey='report-target-detail' namespace={ns} component={MuiField} />
                    </Grid>
                </Grid>
            </>}

            <Box sx={{display: 'flex', flexDirection: 'row', columnGap: '1em', rowGap: '1em', flexWrap: 'wrap'}}>
                <Button variant='contained' disabled={this.state.isLoading} sx={buttonStyles}
                    onClick={async () => await this.submit(false)} startIcon={<ReplayIcon />}>{t('common.reload')}</Button>
                <Button variant='contained' disabled={this.state.isLoading} sx={buttonStyles}
                    onClick={async () => await this.submit(true)} startIcon={<FileDownloadIcon />}>{t('common.export')}</Button>
                {/* TODO: disable export when form dirty */}
                <Button variant='contained' sx={buttonStyles}
                    onClick={async () => this.showPrevReports(true)} startIcon={<DescriptionIcon />}>{t(`${ns}.previous-reports`, {ns})}</Button>
            </Box>
        </Form>;
    }

    private renderDetail(detail: object) {
        return <Table size="small" className="inner-table">
            <TableBody>
            {Object.entries(detail).map(([key, value]) =>
                <TableRow key={key}>
                    <TableCell align="left" sx={{ width: '7em' }}>{this.props.t(key)}</TableCell>
                    <TableCell align="left" sx={{ wordBreak: 'break-word' }}>{stripSpan(value.toString())}</TableCell>
                </TableRow>
            )}
            </TableBody>
        </Table>
    }

    public render() {
        const t = this.props.t;
        const ns = Statistics.getLocale();
        const borderColorStyle = {borderColor: 'divider'};
        const reportButtonStyle = { m: '.5em 1em .5em 0', '&:last-of-type': { mr: 0 }};
        const locale = this.props.i18n.language;

        const sorting = (field: string) => this.state.sort.key === field &&
                        <span>{this.state.sort.desc ? '▼' : '▲'}</span>;

        const header = (type: ReportType) => 
            (<TableHead sx={{backgroundColor: 'divider', opacity: 0.6}}><TableRow sx={borderColorStyle}>
            {type === ReportType.All && (<>
                <AuthContext.Consumer>{auth => hasController(auth, controllerKeys.kiosk, ControllerDeployment.Global)
                && <TableCell sx={borderColorStyle}>{t(`${ns}.kiosk`, {ns})}</TableCell>
                }</AuthContext.Consumer>
                <TableCell sx={borderColorStyle}>{t(`${ns}.date`, {ns})}</TableCell>
                <TableCell sx={borderColorStyle}>{t(`${ns}.time`, {ns})}</TableCell>
                <TableCell sx={borderColorStyle}>{t(`${ns}.source`, {ns})}</TableCell>
                <TableCell sx={borderColorStyle}>{t(`${ns}.source-detail`, {ns})}</TableCell>
                <TableCell sx={borderColorStyle}>{t(`${ns}.target`, {ns})}</TableCell>
                <TableCell sx={borderColorStyle}>{t(`${ns}.target-detail`, {ns})}</TableCell>
            </>)}

            {type === ReportType.ButtonLabel && <>
                <TableCell sx={borderColorStyle} onClick={() => this.sortBy('label', this.state.buttonLabelEntries ?? [])}>
                    {sorting('label')}
                    {t(`${ns}.button-label`, {ns})}
                </TableCell>
                <TableCell sx={borderColorStyle} onClick={() => this.sortBy('clicks', this.state.buttonLabelEntries ?? [])}>
                    {sorting('clicks')}
                    {t(`${ns}.clicks-number`, {ns})}
                </TableCell>
            </>}
            
            {type === ReportType.ButtonPosition && <>
                <TableCell sx={borderColorStyle} onClick={() => this.sortBy('position', this.state.buttonPositionEntries ?? [])}>
                    {sorting('position')}
                    {t(`${ns}.button-position`, {ns})}
                </TableCell>
                <TableCell sx={borderColorStyle} onClick={() => this.sortBy('clicks', this.state.buttonPositionEntries ?? [])}>
                    {sorting('clicks')}
                    {t(`${ns}.clicks-number`, {ns})}
                </TableCell>
            </>}
        </TableRow></TableHead>);

        let body = <TableBody className="even-odd">
        {this.state.reportParams.reportType === ReportType.All
            && this.state.allEntries?.map((e, i) => 
            <TableRow key={(e.identifier ?? 0) + i} sx={borderColorStyle}>
                    <AuthContext.Consumer>{auth => hasController(auth, controllerKeys.kiosk, ControllerDeployment.Global)
                    && <TableCell sx={borderColorStyle}>{e.name}</TableCell>
                    }</AuthContext.Consumer>
                    <TableCell sx={borderColorStyle}>{datetimeFromIso(e.date, true)?.toLocal().toLocaleString(DateTime.DATE_FULL, { locale })}</TableCell>
                    <TableCell sx={borderColorStyle}>{datetimeFromIso(e.date, true)?.toLocal().toLocaleString(DateTime.TIME_SIMPLE, { locale })}</TableCell>
                    <TableCell sx={borderColorStyle}>{t(`${ns}.${e.source.toLowerCase()}`, {ns})}</TableCell>
                    <TableCell sx={borderColorStyle}>{this.renderDetail(e.sourceDetail)}</TableCell>
                    <TableCell sx={borderColorStyle}>{t(`${ns}.${e.target.toLowerCase()}`, {ns})}</TableCell>
                    <TableCell sx={borderColorStyle}>{this.renderDetail(e.targetDetail)}</TableCell>

                    {/* <TableCell sx={borderColorStyle}>{e.source === 'EXTERNAL_WEBSITE' ? t(`${ns}.address`, {ns}) : e.sourceDetail}</TableCell>
                    <TableCell sx={{...borderColorStyle, wordBreak: 'break-word', padding: '0 !important' }}>
                        <Box sx={{ display: 'flex' }}>
                            <IconButton onClick={() => this.searchPath(e.path)} sx={{ alignSelf: 'baseline'}}><BarChartIcon /></IconButton>
                            <IconButton onClick={() => this.openPath(e.path)} sx={{ alignSelf: 'baseline'}}><LaunchIcon /></IconButton>
                            <Accordion disableGutters square sx={{ border: 'none', boxShadow: 'none', flexGrow: 1 }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ minHeight: '40px', maxHeight: '40px'}}>
                                    {new URL(e.path).hostname}
                                </AccordionSummary>
                                <AccordionDetails>
                                    {e.path}
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    </TableCell> */}
            </TableRow>
        )}
        {this.state.reportParams.reportType === ReportType.ButtonLabel
            && this.state.buttonLabelEntries?.map((e, i) => 
            <TableRow key={e.label} sx={borderColorStyle}>
                    <TableCell sx={borderColorStyle}>{e.label}</TableCell>
                    <TableCell sx={borderColorStyle}>{e.clicks}</TableCell>
            </TableRow>
        )}
        {this.state.reportParams.reportType === ReportType.ButtonPosition
            && this.state.buttonPositionEntries?.map((e, i) => 
            <TableRow key={e.position} sx={borderColorStyle}>
                    <TableCell sx={borderColorStyle}>{e.position}</TableCell>
                    <TableCell sx={borderColorStyle}>{e.clicks}</TableCell>
            </TableRow>
        )}
        </TableBody>;

        return (<>
        <Typography variant="h2">
            { t(Statistics.getLocale()+'.module-title', {ns:Statistics.getLocale()}) }
        </Typography>

        <Grid className="stats" container spacing={4} sx={{width: '100%', maxWidth: '100%'}}>
            <Grid item xs={12} md={6} lg={5} xl={4} sx={{mt: '2em'}}>
                <Formik innerRef={this.formikRef} initialValues={this.state.reportParams} onSubmit={this.onSubmit} validateOnBlur={true} component={this.form} />
            </Grid>
            {this.state.refreshed && <Grid item xs={12} md={6} lg={7} xl={8} sx={{width: '100%', minHeight: '400px', overflow: 'hidden'}}>
                {this.state.isLoading && <Skeleton animation="wave" variant="rectangular" sx={{height: '100%'}} />}

                {/* FIX ME: CustomThemeContext is a half-baked hack */}
                {!this.state.isLoading && <CustomThemeContext.Consumer>{ theme =>
                    {
                        this.setColors(theme.currentTheme.mode);
                        // unpack options to cause a prop change and rerender
                        return <Bar style={{position: 'relative'}} options={{...this.state.options}} data={this.state.data} />;
                    }}
                </CustomThemeContext.Consumer>}
            </Grid>}

            {this.state.refreshed && <Grid item xs={12} sx={{width: '100%', height: '100%'}}>
                {(this.state.isLoading || this.state.isChangingPage) && <Skeleton animation="wave" variant="rectangular" sx={{height: '100%', minHeight: '400px'}} />}
                {!this.state.isLoading && !this.state.isChangingPage && <>
                    {this.state.reportParams.reportType === ReportType.All
                    && <MUIPagination className="crud-pagination"
                        count={this.state.allPagination.totalPages}
                        page={this.state.allPagination.currentPage}
                        onChange={this.changePage} />}

                    <Table className="crud-table" sx={{width: '100%', maxWidth: '100%', overflowHidden: 'hidden', borderColor: 'divider', boxShadow: 4}}>
                        {header(this.state.reportParams.reportType)}
                        {body}
                    </Table>

                    {this.state.reportParams.reportType === ReportType.All
                    && <MUIPagination className="crud-pagination"
                        count={this.state.allPagination.totalPages}
                        page={this.state.allPagination.currentPage}
                        onChange={this.changePage} />}
                    </>}
            </Grid>}
        </Grid>

        {this.state.prevReportsShown &&
        <Modal isOpen={this.state.prevReportsShown} onClose={() => this.showPrevReports(false)} title={t(`${ns}.previous-reports`, {ns})} width='90vw'>
            <Table className="crud-table" sx={{width: '100%', maxWidth: '100%', overflowHidden: 'hidden', borderColor: 'divider', boxShadow: 4}}>
                <CustomThemeContext.Consumer>{ theme => {
                    const dark = theme.currentTheme.mode === 'dark';
                    return <TableHead sx={{backgroundColor: dark ? 'divider' : 'unset', opacity: dark ? 0.6 : 0.8}}>
                    <TableRow sx={borderColorStyle}>
                        <TableCell sx={borderColorStyle}>{t(`${ns}.report-type`, {ns})}</TableCell>
                        <TableCell sx={borderColorStyle}>{t(`${ns}.report-from`, {ns})}</TableCell>
                        <TableCell sx={borderColorStyle}>{t(`${ns}.report-to`, {ns})}</TableCell>
                        <TableCell sx={borderColorStyle}>{t(`${ns}.report-source`, {ns})}</TableCell>
                        <TableCell sx={borderColorStyle}>{t(`${ns}.report-target`, {ns})}</TableCell>
                        <TableCell sx={borderColorStyle}>{t('common.size')}</TableCell>
                        <TableCell sx={borderColorStyle}>{t('crud.actions')}</TableCell>
                    </TableRow>
                </TableHead>}}
                </CustomThemeContext.Consumer>

                <TableBody className="even-odd">
                {this.state.prevReports.filter(r => r).map((r, i) => {
                    if (typeof r === 'string')
                        return <p key={i}>{r}</p>;
                    // return <p>{JSON.stringify(r)}</p>;

                    return <TableRow key={i} sx={borderColorStyle}>
                        <TableCell sx={borderColorStyle}>{r.type ? t(`${ns}.report-${r.type?.toLowerCase()}`, {ns: ns}) : '?'}</TableCell>
                        <TableCell sx={borderColorStyle}>{datetimeFromIso(r.timeFilter?.from, true)?.toLocal().toLocaleString(DateTime.DATETIME_SHORT, { locale }) ?? '?'}</TableCell>
                        <TableCell sx={borderColorStyle}>{datetimeFromIso(r.timeFilter?.to, true)?.toLocal().toLocaleString(DateTime.DATETIME_SHORT, { locale }) ?? '?'}</TableCell>
                        <TableCell sx={borderColorStyle}>{r.source ? t(`${ns}.${r.source?.toLowerCase()}`, {ns}) : '?'}</TableCell>
                        <TableCell sx={borderColorStyle}>{r.target ? t(`${ns}.${r.target?.toLowerCase()}`, {ns}) : '?'}</TableCell>
                        <TableCell sx={borderColorStyle}>{r.size}</TableCell>
                        <TableCell sx={borderColorStyle}>
                            <Button variant='contained' disabled={this.state.downloadingReport === r.name}
                                startIcon={this.state.downloadingReport === r.name
                                    ? <CircularProgress size={20} /> 
                                    : <FileDownloadIcon />}
                                sx={reportButtonStyle} onClick={async () => await this.downloadReport(r)}>
                                    {t('common.download')}
                            </Button>
                            <Button variant='contained' startIcon={<DeleteIcon />} sx={reportButtonStyle}
                                onClick={async () => await this.deleteReport(r)}>
                                    {t('common.delete')}
                            </Button>
                        </TableCell>
                    </TableRow>;
                })}
            </TableBody>
            </Table>
        </Modal>}
    </>);
    }
}

export default hoistStatics(withTranslation()(Statistics), Statistics)