import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next'
import { Field, FieldProps, getIn } from 'formik';
import { FormControlLabel, MenuItem, Switch } from '@mui/material';
import hoistStatics from 'hoist-non-react-statics';
import { canManageAreas, createDefaultScheduleRecord, IPlaylist, IScheduleRecord, ITaggedScheduleRecord } from './Playlists.model';
import PlaylistService from "./Playlist.service";
import Playlists from './Playlists';
import MuiField from '../../system/MuiField';
import MuiDateTimeField, { datetimeFromIso } from '../../system/MuiDateTimeField';
import { isValidTimeRange } from '../../system/FieldValidators';
import { AuthContext } from '../../system/Base';
import TagSelect from '../tags/TagSelect';
import { DateTime } from 'luxon';
import { ILabeledFieldProps, triggerFormChange } from '../../system/Formik.model';
import { controllerKeys, hasController, isAuthorized, IUserContext, permissions } from '../../system/User.model';
import { ListEditor } from '../../system/ListEditor';

interface IState {
    contentAreas: string[]
}
interface IProps extends WithTranslation {
    entity: IPlaylist,
    onDone: () => void | Promise<void>
}

class ScheduleChange extends React.Component<IProps, IState, WithTranslation> {
	
	playlistService = PlaylistService;

	public constructor(props: IProps) {
		super(props);
		this.state = {
            contentAreas: []
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.validate = this.validate.bind(this);

        this.getEpochLimits = this.getEpochLimits.bind(this);
        this.playsForever = this.playsForever.bind(this);
        this.playForever = this.playForever.bind(this);

        this.recordForm = this.recordForm.bind(this);
        this.recordName = this.recordName.bind(this);
	}

	async onSubmit(records: (IScheduleRecord | ITaggedScheduleRecord)[]) {
		await this.playlistService.setSchedule(this.props.entity, records);
        await this.props.onDone();
	}

    async componentDidMount() {
        const areas = await this.playlistService.getContentAreas();
        this.setState({ contentAreas: areas });
    }

    // This is a temporary solution, so return type is not specified.
    // It's basically a deep version of IErrors<(IScheduleRecord | ITaggedScheduleRecord)[]>.
    validate(records: (IScheduleRecord | ITaggedScheduleRecord)[]): any {
        const t = this.props.t;

        let recordErrs: any = {};
        for (let i = 0; i < records.length; ++i) {

            const record = records[i];
            const e = isValidTimeRange(datetimeFromIso(record.begin), datetimeFromIso(record.end));
            if (e) recordErrs[i] = { begin: t(e), end: t(e) }
        }
        return Object.keys(recordErrs).length > 0 ? recordErrs : undefined;
    }

    getEpochLimits() {
        return {
            begin: DateTime.fromSeconds(0).toUTC().toISO(),
            end: DateTime.fromSeconds(Math.pow(2, 31) - 1).toUTC().toISO()
        };
    }

    playsForever(record: IScheduleRecord): boolean {
        const epoch = this.getEpochLimits();
        return record.begin === epoch.begin && record.end === epoch.end;
    }

    playForever(auth: IUserContext, props: FieldProps<string, IPlaylist>, forever: boolean) {
        const defaultSchedule = createDefaultScheduleRecord(auth, this.state.contentAreas);
        const epoch = this.getEpochLimits();

        triggerFormChange(props.form, props.field.name + '.begin', forever ? epoch.begin : defaultSchedule.begin);
        triggerFormChange(props.form, props.field.name + '.end', forever ? epoch.end : defaultSchedule.end);
    }

    private recordName(record: IScheduleRecord) {
        const t = this.props.t;
        const ns = Playlists.getLocale();
        const locale = this.props.i18n.language;

        return t(`${ns}.${record.area}`, record.area, {ns}) + ': ' + (this.playsForever(record)
            ? t('time.forever')
            : (
                DateTime.fromISO(record.begin).toLocaleString(DateTime.DATETIME_SHORT, {locale})
                + '–' + DateTime.fromISO(record.end).toLocaleString(DateTime.DATETIME_SHORT, {locale})
            ));
    }

    recordForm(record: IScheduleRecord, recordNamePath: string) {
        const t = this.props.t;
        const ns = Playlists.getLocale();

        const PlayForever = (props: ILabeledFieldProps<string, IPlaylist>) => <AuthContext.Consumer>{(auth) => 
            <FormControlLabel sx={{ width: '100%', mb: '1rem' }} label={t(`${props.namespace}.${props.labelKey}`, {ns: props.namespace})}
                control={<Switch checked={this.playsForever(getIn(props.form.values, props.field.name))}
                    onChange={(_e, checked) => this.playForever(auth, props, checked)} />}
            />
        }</AuthContext.Consumer>;

        return <AuthContext.Consumer>{auth => <>
            <Field name={`${recordNamePath}.area`}  labelKey="area" namespace={ns} variant='select' component={MuiField}
                disabled={!canManageAreas(auth, this.state.contentAreas)}>
                
                {this.state.contentAreas.map((area) =>
                    <MenuItem key={area} value={area} disabled={!isAuthorized(auth, permissions.playlist.manageArea + area)}>
                        {t(`${ns}.${area}`, area, {ns: ns})}
                    </MenuItem>
                )}
            </Field>

            <Field name={`${recordNamePath}`} labelKey='forever' namespace={ns} component={PlayForever} />
            {!this.playsForever(record) && <>
                <Field name={`${recordNamePath}.begin`} labelKey='begin' namespace={ns} component={MuiDateTimeField} />
                <Field name={`${recordNamePath}.end`} labelKey='end' namespace={ns} component={MuiDateTimeField} />
            </>}

            {hasController(auth, controllerKeys.tag) && <>
                <Field name={`${recordNamePath}.include`} labelKey='include' namespace={ns} component={TagSelect} />
                <Field name={`${recordNamePath}.exclude`} labelKey='exclude' namespace={ns} component={TagSelect} />
            </>}
        </>}
        </AuthContext.Consumer>;
    }
                            
    render() {
        return <AuthContext.Consumer>{(auth) =>
            <ListEditor<IScheduleRecord | ITaggedScheduleRecord> role='update'
                items={this.props.entity.schedule ?? []}
                enableDragDrop={false}
                addLabelKey='module.playlists.add-record'
                addLabelNs={Playlists.getLocale()}
                expandListLabelKey='module.playlists.expand-records'
                collapseListLabelKey='module.playlists.collapse-records'
                listLabelNs={Playlists.getLocale()}
                itemForm={this.recordForm}
                itemName={this.recordName}
                createItem={() => createDefaultScheduleRecord(auth, this.state.contentAreas)}
                validate={this.validate}
                onSubmit={this.onSubmit}
                validateOnChange={false} validateOnBlur={true}
            />
        }</AuthContext.Consumer>;
	}
}

export default hoistStatics(withTranslation()(ScheduleChange), ScheduleChange)