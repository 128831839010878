import React, { Fragment } from 'react';
import { ITag } from '../tags/Tags.model';
import { withTranslation, WithTranslation } from 'react-i18next'
import hoistStatics from 'hoist-non-react-statics';
import { CustomThemeContext }  from '../../system/CustomThemeProvider';
import CircularProgress from '@mui/material/CircularProgress';
import { IKiosk, StatusParser, TagNode, TagTree } from './Kiosks.model';
import { InputAdornment, TextField, Box, Tabs, Tab } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { load, save } from '../../App';

interface IState {
	tree?: TagTree,
	searchTerm: string,
	page: number
}

interface IProps extends WithTranslation {
	kiosks?: IKiosk[]
	tags: ITag[]
	onTagClick? : (id: number) => void
	onKioskClick? : (id: number) => void
	mode? : Mode
	paged? : string
}

export enum Mode {
	Tags = 1,
	Kiosks = 2
}

interface Branches {
	components: JSX.Element[] | null,
	totalKioskCount: number
}

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

class KioskTree extends React.Component<IProps, IState, WithTranslation> {
	
	public static defaultProps = {
		mode : Mode.Kiosks
	}
	
	constructor(props : IProps) {
		super(props);

		this.state = {
			searchTerm: '',
			page: 0
		};
				
		this.drawTree = this.drawTree.bind(this);
		this.drawKiosks = this.drawKiosks.bind(this);
		this.search = this.search.bind(this);
		this.matchesSearch = this.matchesSearch.bind(this);
	}
	
	componentDidMount()
	{
		if (this.props.paged)
		{
			let last = load("lastTreeIndex_" + this.props.paged);
			if (last !== null)
				this.setState({ page : Number(last) });
		}
	}

	componentDidUpdate(prevProps: IProps) {
		if (this.props.kiosks === prevProps.kiosks
			&& this.props.tags === prevProps.tags)
			return;
		
		const tree = new TagTree(this.props.tags, this.props.kiosks ?? []);
		this.setState({
			tree: tree
		});
	}

	search(term: string) {
		this.setState({
			searchTerm: term
		})
	}

	matchesSearch(kiosk: IKiosk) {
		const term = this.state.searchTerm.trim().toLocaleLowerCase('cs');
		if (!term)
			return true;
		
		return kiosk.name.toLocaleLowerCase('cs').includes(term)
			|| kiosk.identifier.toLocaleLowerCase('cs').includes(term)
			|| kiosk.description.toLocaleLowerCase('cs').includes(term);
	}
	
	// Vykreslení stromu (rekurzivně):
	drawTree(nodes: TagNode[]): Branches {
		var branches = [];
		var totalKioskCount = 0;

		for (let node of nodes) {
			let tag = node.tag;
			let btn = (<input type="checkbox" />);
			let subBranches = this.drawTree(node.children);
			let sub = subBranches.components;
			let kiosks = this.props.mode === Mode.Kiosks ? this.drawKiosks(node.kiosks) : null;
			let len = 0;
			if (kiosks && kiosks.length)
				len = kiosks.length;
			let thisKioskCount = subBranches.totalKioskCount + len;

			var comp = (<div key={tag.id} className={"branch" + (sub === null && kiosks === null ?" childless":"")}>
				{btn}
				<span
					className={"title" + (this.props.onTagClick ? " clickable" : "")}
					onClick={() => this.props.onTagClick?.(tag.id!)}>
					<span>{tag.name}</span>
					<span className="desc">
						{ (thisKioskCount > 0 ? " ("+ thisKioskCount +")" : "")}
					</span>
				</span>
				{sub}
				{kiosks && (<div className='kiosks'>
					{kiosks}
				</div>)}
			</div>);
			
			totalKioskCount += thisKioskCount;
			branches.push(comp);
		}
		return { components: (branches.length > 0? branches : null), totalKioskCount: totalKioskCount };
	}
	
	a11yProps(index: number) {
		return {
			id: `tag-tree-paged-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		};
	}
	
	tabPanel(props: TabPanelProps) {
	  const { children, value, index, ...other } = props;

	  return (<div
		  className="tree-content paged"
		  role="tabpanel"
		  hidden={value !== index}
		  id={`simple-tabpanel-${index}`}
		  aria-labelledby={`simple-tab-${index}`}
		  {...other}
		>
		  {value === index && children}
		</div>);
	}
	
	drawTreePaged(nodes: TagNode[]) { 
		return <>
		<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
			<Tabs value={this.state.page} onChange={(e, v)=>{ this.setState({page: v}); save("lastTreeIndex_" + this.props.paged, v); }}
				variant="scrollable"
				scrollButtons="auto"
				allowScrollButtonsMobile
			>
				{ nodes.map((node, index)=>{
					return <Tab key={index} label={node.tag.name} {...this.a11yProps(index)} />
				})}
			</Tabs>		
			{ nodes.map((node, index)=>{
				return (<this.tabPanel key={index} value={this.state.page} index={index}>
					{ this.drawTree(node.children).components }					
				{ this.props.mode === Mode.Kiosks && (<div className='kiosks'>{this.drawKiosks(node.kiosks)}</div>) }					
				</this.tabPanel>)
			})}
		</Box>
		</>;
	}

	drawKiosks(kiosks: IKiosk[]): JSX.Element[] | null {
		if (!kiosks)
			return null;
			
		kiosks.sort((a, b) => a.name > b.name ? 1 : -1);
		
		let components = Array<JSX.Element>();
		for (const kiosk of kiosks)
		{
			if (!this.matchesSearch(kiosk))
				continue;

			let comp = (<div key={kiosk.id} className={"kiosk"}>
				<span
					className={"kiosk-title" + (this.props.onKioskClick ? " clickable" : "")}
					onClick={() => {
						if (kiosk.id && this.props.onKioskClick)
							this.props.onKioskClick?.(kiosk.id);
					}}>
					<span className={`kiosk-status kiosk-color ${StatusParser.getKioskState(kiosk.status)}`}></span>
					<span>{kiosk.name}</span>
				</span>
			</div>);
			components.push(comp);
		}
		return components.length > 0 ? components : null;
	}

	render() {
	
		if (this.props.paged && this.state.tree && this.state.tree.roots && this.state.page > this.state.tree!.roots!.length - 1 && this.state.page !== 0)
			this.setState({page : 0});
	
		const t = this.props.t;
		// const l = this.props.i18n.language;

		return (<CustomThemeContext.Consumer>{theme =>
			(<Fragment>
				{/* Máme už data: */}
				{ this.state.tree && (<div className="tree">
						{this.props.mode !== Mode.Tags && <TextField value={this.state.searchTerm} onChange={(e) => this.search(e.target.value)}
							variant="outlined"
							className="tree-search"
							label={t('module.kiosks.search', {ns: 'module.kiosks'})}
							InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<SearchIcon />
								</InputAdornment>
							)}}/>}
						{ this.props.paged && this.drawTreePaged(this.state.tree.roots) }
						{ !this.props.paged && <div className="tree-content"> { this.drawTree(this.state.tree.roots).components } </div> }
					</div>)}
				
				{/* Nemáme ještě data: */}
				{ !this.props.tags && (
					<CircularProgress />
				)}
				
			</Fragment>)}
		</CustomThemeContext.Consumer>);
	}
}

export default hoistStatics(withTranslation()(KioskTree), KioskTree)