import React, { HTMLInputTypeAttribute } from "react";
import { IEntity, IFieldProps } from "./CRUDForm";
import { CRUDOperation } from "./CRUDOperation";

interface IState {}

export interface IAttribute<TEntity extends IEntity, TComponentProps extends IFieldProps<TEntity, any> = IFieldProps<TEntity, any>> {
	name: keyof TEntity,
    labelKey: string,
    namespace: string,
    sortable: boolean,
    validator?: (e: TEntity, k: keyof TEntity, o: CRUDOperation) => string | undefined,
    type?: HTMLInputTypeAttribute,
    formComponent?: string | React.ComponentType<TComponentProps>,
    tableValueResolver?: (e: TEntity, k: keyof TEntity) => string | JSX.Element,
    hideInCreateForm?: boolean,
    hideInUpdateForm?: boolean,
    hideInDeleteForm?: boolean,
    hideInTable?: boolean,
    readOnly?: boolean
}

export class CRUDAttribute<TEntity extends IEntity> extends React.Component<IAttribute<TEntity>, IState> {

    static defaultProps = {
        sortable: true,
    };

    render(): JSX.Element {
        return <></>;
    }
}