import React from "react";
import hoistStatics from "hoist-non-react-statics";
import { withTranslation, WithTranslation } from "react-i18next";
import { Field, FieldInputProps, FormikProps } from "formik";
import MuiField from "../../system/MuiField";
import MuiDateField from "../../system/MuiDateField";
import MuiTimeField from "../../system/MuiTimeField";
import { FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { ILabeledFieldProps } from "../../system/Formik.model";
import { IScheduleException } from "./Schedule.model";


interface IState {}

interface IProps extends WithTranslation {
    field: FieldInputProps<IScheduleException>,
    form: FormikProps<any>
}

class ScheduleExceptionFields extends React.Component<IProps, IState, WithTranslation> {
    
    constructor(props: IProps) {
        super(props);

        this.state = {};

        this.exceptionEffect = this.exceptionEffect.bind(this);
    }

    exceptionEffect(props: ILabeledFieldProps) {
        const ns = "module.dashboard";
        const t = this.props.t;

        return <>
            <FormLabel>{t(`${ns}.${props.labelKey}`, {ns: ns})}</FormLabel>
            <RadioGroup {...props.field}>
                <FormControlLabel value='true' control={<Radio />} label={t(`${ns}.force-on`, {ns: ns})} />
                <FormControlLabel value='false' control={<Radio />} label={t(`${ns}.force-off`, {ns: ns})} />
            </RadioGroup>
        </>;
    }

    render() {
        const ns = "module.dashboard";
        const namePrefix = this.props.field.name ? this.props.field.name + '.' : '';

        return <>
            <Field name={`${namePrefix}label`} labelKey="exception-label" namespace={ns} component={MuiField} />
            <Field name={`${namePrefix}begin`} labelKey="exception-begin" namespace={ns} convertUTC={false} component={MuiDateField} />
            <Field name={`${namePrefix}end`} labelKey="exception-end" namespace={ns} convertUTC={false} component={MuiDateField} />
            <Field name={`${namePrefix}timeBegin`} labelKey="exception-time-begin" namespace={ns} convertUTC={false} includeZone={false} component={MuiTimeField} />
            <Field name={`${namePrefix}timeEnd`} labelKey="exception-time-end" namespace={ns} convertUTC={false} includeZone={false} component={MuiTimeField} />
            <Field name={`${namePrefix}state`} labelKey="exception-effect" namespace={ns} component={this.exceptionEffect} />
        </>;
    }
}

export default hoistStatics(withTranslation()(ScheduleExceptionFields), ScheduleExceptionFields);