import React, { Fragment } from 'react';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { MenuItem, TextField, Typography } from '@mui/material';
import TagsService from './Tags.service';
import { withTranslation, WithTranslation } from 'react-i18next'
import DataTable, { Attribute } from '../../system/DataTable'
import { ITag } from './Tags.model'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import hoistStatics from 'hoist-non-react-statics';
import { IFieldProps } from '../../system/CRUDForm';
import { IIdentifiable } from '../../system/Identifiable.model';
import {IEntry, ISearchResult} from "../../system/SearchResult.model"
import SellIcon from '@mui/icons-material/Sell';
import IModule, { ModuleState } from "../../system/IModule"
import { controllerKeys, hasController, isAuthorized, IUserContext, permissions } from '../../system/User.model'
import KioskTree, { Mode } from '../kiosks/KioskTree';
import { setPageTitle } from '../../App'

interface IRuntimeTag extends IIdentifiable {
	name: string,
	parent: number | null | string
}

interface IState {
	tags : ITag[]
}
interface IProps extends WithTranslation { }

@IModule
class Tags extends React.Component<IProps, IState, WithTranslation> {
	
	dataTableRef: React.RefObject<DataTable<ITag>>;

	constructor(props : IProps) {
		super(props);
		this.state = {
			tags : []
		};

		this.reloadTags = this.reloadTags.bind(this);
		this.correctParentValue = this.correctParentValue.bind(this);

		this.dataTableRef = React.createRef<DataTable<ITag>>();
	}
	
	public static getLocale() { return "module.tags"; }
	
	public static async search(input : string): Promise<ISearchResult[]> {
		let res = await TagsService.search(input);		
		return res.map((r: IEntry<ITag>) : ISearchResult => { return {
			title : r.entity.name,
			description : r.entity.identifier, // there's not much else to use for description...
			icon : <SellIcon />,
			link : "/tags?search-result-index=" + r.index
		}});
	}
	
	public static menu(t: ((x:string, y:any)=>string)) {
		return {
			title: (t && t(Tags.getLocale()+".title", {ns:Tags.getLocale()})) || "???",
			route : "/tags",
			icon : <LocalOfferIcon />,
			weight : 40
		};
	}
	
	public static isEnabled(auth: IUserContext) {
		if (!hasController(auth, controllerKeys.tag))
			return ModuleState.DISABLED;
		if (isAuthorized(auth, permissions.tag.read))
			return ModuleState.ENABLED;
			
		return ModuleState.NO_PERMISSIONS;
	}

	// The parent value obtained from select cannot be null (neither select nor React support it),
	// so it is necessary to manually set it to null before submitting the form
	private correctParentValue(runtimeEntity: IRuntimeTag) {
		if (runtimeEntity.parent === '' || runtimeEntity.parent === '-')
			runtimeEntity.parent = null;
	}
	
	async reloadTags() {
		const tags = (await TagsService.getAll()).data;
		this.setState({ tags : tags });
	}
	
	async componentDidMount() {
		setPageTitle(this.props.t( Tags.getLocale()+".title", { ns: Tags.getLocale() }));
		await this.reloadTags();
	}
	
	render() {
		const t = this.props.t;
		const ns = Tags.getLocale();
		
		const SelectRenderer = (props: IFieldProps<ITag, string>) => <TextField
			fullWidth select
			value={props.field.value ?? '-'} name={props.field.name} onChange={props.field.onChange} onBlur={props.field.onBlur}
			label={t(`${props.namespace}.${props.labelKey}`, {ns: props.namespace})}>
			
			<MenuItem key='none' value='-'>-</MenuItem>
			{ this.state.tags.filter(tag => tag.id !== props.entity.id).map(tag => {
				return (<MenuItem value={tag.id!} key={tag.id!}>{tag.name}</MenuItem>)
			})}
		</TextField>;
	
		return (<Fragment>
		<Typography variant="h2">
			{ t(Tags.getLocale()+".title", {ns:Tags.getLocale()}) }
		</Typography>
		
		<Box sx={{ flexGrow: 1 }}>
		  <Grid container spacing={2}>
			<Grid item xs={12} md={8}>
			  <DataTable ref={this.dataTableRef} service={TagsService} entityType='tags' entityName={t("module.tags.entity", {ns:'module.tags'})} 
				permissions={permissions.tag} create={true} edit={true} delete={true} beforeSubmit={this.correctParentValue} onChange={this.reloadTags}>
				<Attribute name="id" type="number" labelKey="id" namespace={ns} hideInCreateForm hideInUpdateForm hideInTable />
				<Attribute name="name" labelKey="name" namespace={ns} />
				<Attribute name="parent" type="number" labelKey="parent" namespace={ns} 
					tableValueResolver={(elem : any)=>{ 
						if (elem.parent === null) return "";
						var par = this.state.tags.find(x => x.id === elem.parent);
						return par ? par.name : "???"; }}
					formComponent={SelectRenderer}/>
		</DataTable>
			</Grid>
			<Grid item xs={12} md={4}>
			  <KioskTree mode={Mode.Tags} tags={this.state.tags} onTagClick={(id) => {
					const index = this.state.tags.findIndex((tag) => tag.id === id);
					this.dataTableRef.current?.selectEntry(index);
				}} />
			</Grid>
		  </Grid>
		</Box>
		
		</Fragment>);
	}
}

export default hoistStatics(withTranslation()(Tags), Tags)