import { IIdentifiable } from "../../system/Identifiable.model"

interface ILoginCredentials {
	login: string
	password: string
}

interface IValidatedCredentials {
	user: IUser
	token: string
}

interface IUser extends IIdentifiable {
	name: string
	login: string
	password: string

	permissions?: IPermission[]
}

function createDefaultUser(): IUser {
	return {
		name: '',
		login: '',
		password: ''
	};
}

interface IPasswordChange extends IIdentifiable {
	// id: string: user's id
	password: string,
	newPassword: string,
	newPasswordCheck?: string
}

interface IPasswordOverwrite extends IIdentifiable {
	// id: string: user's id
	newPassword: string
	newPasswordCheck?: string
}

interface IPermission {
    module: string,
	permission: string,
	name: string,
	description: string
}

interface IPermissionChange extends IIdentifiable {
	// id: string: user's id
	permissions: IPermission[]
}

export type { IUser, IValidatedCredentials, ILoginCredentials, IPasswordChange, IPasswordOverwrite, IPermission, IPermissionChange }
export { createDefaultUser }