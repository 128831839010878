import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next'
import MapLight from './map_cz2w.png'
import { CustomThemeContext }  from '../../system/CustomThemeProvider';
import { BrandingContext }  from '../../system/BrandingProvider';
import './map.css'
import { IKiosk, StatusParser } from '../kiosks/Kiosks.model';
import { Box } from '@mui/system';
import { Button } from '@mui/material';
import i18n from "i18next";

interface IState { 
	winWidth : number,
	winHeight : number
	rotScale : number,
	drawnOnce : boolean
}

interface IProps extends WithTranslation {
	kiosks: IKiosk[],
	selectedKiosk?: IKiosk | null,
	mapId?: string,
	onAreaClick?: (id: string) => void
}

class KioskMap extends React.Component<IProps, IState, WithTranslation> {

	parseGPS(str : string) {
		var x = 0, y = 0;
		var val = 0, buf = "", conv = 1, state = 1, parts = 0;
		const sep = ["°", "′", "'", "\"", "″"], defY = 1, defX = -1;
		for (var i = 0; i < str.length; i++) {
			var c = str[i];
			if (state === 1) {
				// Reading number
				var nm = (c >= '0' && c <= '9') || c === ".";
				if (nm) {
					buf += c;
				}
				if (!nm || i >= str.length - 1) {
					val = val + (Number(buf) * conv);
					conv = conv / 60;
					if (sep.includes(c)) {
						buf = "";
						state = 2;
					} else {
						buf = "";
						state = 3;
					}
				}
			}
			else if (state === 2) {
				// Reading separator characters
				if (c >= '0' && c <= '9') {
					state = 1;
					buf = c;
				} else {
					if (sep.includes(c) || c === " ")
						buf += c;
					else {
						state = 3;
					}
				}
			}
			if (state === 3 || i >= str.length - 1) {
				// Reading description characters
				if ((c >= '0' && c <= '9') || i >= str.length - 1) {
					var t = buf.toLowerCase();
					if (t === "sš" || t === "n" || t === "nl")
						y = val;
					else if (t === "jš" || t === "s" || t === "sl")
						y = val * -1;
					else if (t === "vd" || t === "e" || t === "el")
						x = val * -1;
					else if (t === "zd" || t === "w" || t === "wl")
						x = val;
					else if (parts === 0)
						y = val * defY;
					else if (parts === 1)
						x = val * defX;
					state = 1;
					conv = 1;
					buf = c;
					val = 0;
					parts++;
				} else {
					if (c !== " " && c !== "." && c !== ";" && c !== ",")
					buf += c;
				}
			}
		}
		return { x: x, y: y };
	}

	// We don't need this in State. We are just switching opacity manually:
	lastHoveredCanvas : HTMLCanvasElement | null = null;
	
	lastMapSettings : any = null;
	
	redrawInterval : any = null;
	themedGradientFrom : string = "";
	themedGradientTo : string = "";
	
	constructor(props : IProps)
	{
		super(props);
		this.state = {
			winWidth : -1,
			winHeight : -1,
			rotScale : 1,
			drawnOnce : false
		}
		
		this.updateDimensions = this.updateDimensions.bind(this);
		this.redrawIfNeeded = this.redrawIfNeeded.bind(this);
		this.goto = this.goto.bind(this);
		this.drawBackgroundImageMap = this.drawBackgroundImageMap.bind(this);
		this.realScale = this.realScale.bind(this);
		this.computeUnrotatedBoundingBox = this.computeUnrotatedBoundingBox.bind(this);
	}
	
	positionFromGPS(latitude: number, longitude: number)
	{
		return { x: -longitude, y: latitude };
	}
	
	parseX(str: any)
	{
		let f = parseFloat(str);
		if (!isNaN(str * 1))
			return -f;
		else
			return this.parseGPS(str).x;
	}
	parseY(str: any)
	{
		let f = parseFloat(str);
		if (!isNaN(str * 1))
			return f;
		else
			return this.parseGPS(str).y;
	}
	
	relativePositionOfKiosk(kiosk: IKiosk, mapSettings: any)
	{
			const n = mapSettings?.n ? this.parseY(mapSettings.n) : this.parseGPS("51°3′20″ s. š., 14°18′58″ v. d.").y;
			const e = mapSettings?.e ? this.parseX(mapSettings.e) : this.parseGPS("49°33′1″ s. š., 18°51′32″ v. d.").x;
			const s = mapSettings?.s ? this.parseY(mapSettings.s) : this.parseGPS("48°33′9″ s. š., 14°19′59″ v. d.").y;
			const w = mapSettings?.w ? this.parseX(mapSettings.w) : this.parseGPS("50°15′7″ s. š., 12°5′29″ v. d.").x;
			
			const h = e - w;
			const v = n - s;

			let c = this.positionFromGPS(kiosk.lat, kiosk.lon);
			let x = (c.x - e) / h * -100;
			let y = (c.y - n) / v * -100;
			
			return { x: x, y: y }
	}
	
	rotatePointInRectangle(position : { x: number, y: number }, angle : number) {
		// Convert angle from degrees to radians
		angle = angle * Math.PI / 180;

		// Get the center point of the rectangle
		const centerX = 50; // Assume the rectangle is centered horizontally
		const centerY = 50; // Assume the rectangle is centered vertically

		// Convert position from percentage to decimal
		const x = position.x;
		const y = position.y;

		// Translate the point to the origin
		const translatedX = x - centerX;
		const translatedY = y - centerY;

		// Rotate the point around the origin
		const rotatedX = translatedX * Math.cos(angle) - translatedY * Math.sin(angle);
		const rotatedY = translatedX * Math.sin(angle) + translatedY * Math.cos(angle);

		// Translate the point back to its original position
		const finalX = rotatedX + centerX;
		const finalY = rotatedY + centerY;

		// Return the rotated point
		return { x: finalX, y: finalY };
	}
	
	rgbToHex(r : any, g : any, b : any) {
		if (r > 255 || g > 255 || b > 255)
			throw new Error("Invalid color component");
		return ((r << 16) | (g << 8) | b).toString(16);
	}
	
	updateDimensions(force : boolean) {
		let areaCanvases = Array.prototype.slice.call(document.getElementsByClassName("map-area-canvas"));
		let mcanv = document.getElementById("map-hover-canvas");
		let bcanv = document.getElementById("map-background");
		let bcanv2 = document.getElementById("map-background-mask");
		areaCanvases.push(mcanv);
		areaCanvases.push(bcanv);
		areaCanvases.push(bcanv2);
		for (let i = 0; i < areaCanvases.length; i++)
		{
			let canvas = areaCanvases[i] as HTMLCanvasElement;
			if (canvas && canvas.parentNode) {
				canvas.width  = (canvas.parentNode! as HTMLElement).clientWidth;
				canvas.height = (canvas.parentNode! as HTMLElement).clientHeight;
			}
		}
		
		this.drawBackgroundImageMap();
		if (force || this.state.winWidth !== window.innerWidth || this.state.winHeight !== window.innerHeight)
		{
			this.setState({ winWidth: window.innerWidth, winHeight: window.innerHeight });
		}
	};
	
	scaleAfterRotation(element : HTMLElement, parent: HTMLElement, angleInDegrees : number) {
		// Get the dimensions of the element
		const width = element.clientWidth;
		const height = element.clientHeight;

		// Convert the angle from degrees to radians
		const angleInRadians = angleInDegrees * Math.PI / 180;

		// Calculate the dimensions of the box after rotation
		const rotatedWidth = Math.abs(width * Math.cos(angleInRadians)) + Math.abs(height * Math.sin(angleInRadians));
		const rotatedHeight = Math.abs(height * Math.cos(angleInRadians)) + Math.abs(width * Math.sin(angleInRadians));

		// Calculate the scale needed to fit the rotated box into the original bounding box
		const scaleX = parent.clientWidth / rotatedWidth;
		const scaleY = parent.clientHeight / rotatedHeight;
		const scale = Math.min(scaleX, scaleY);

		// Apply the transform to the element
		return scale;
	}
	
	redrawIfNeeded() 
	{
		let areaCanvases = document.getElementsByClassName("map-area-canvas");
		let wrapper = document.getElementsByClassName("mapWrapperSub")[0] as HTMLElement;
		let wrapperPar = document.getElementsByClassName("mapWrapperPar")[0] as HTMLElement;
		let bck = document.getElementById("map-background") as HTMLCanvasElement;
		let d = wrapper.getBoundingClientRect();
		let e0 = wrapperPar.getBoundingClientRect();
		let e = { top: e0.top, left: e0.left, width: e0.width, height: e0.height };
		if (this.lastMapSettings && this.lastMapSettings.scale)
		{
			e.width = e.width * this.lastMapSettings.scale; // Not the "real" scale. Only the branding one.
			e.height = e.height * this.lastMapSettings.scale; // Not the "real" scale. Only the branding one.
		}	
		let err = (Math.round(d.width) !== Math.round(e.width) &&
				   Math.round(d.height) !== Math.round(e.height));
		
		let func = () => {
			console.log("Updating dimensions...");
			this.updateDimensions(true);
			
			if (this.lastMapSettings || true /* FIXME */) {						
				let scale = this.scaleAfterRotation(wrapper, wrapperPar, (this.lastMapSettings?.rotation || 0));
				if (this.state.rotScale !== scale)
				{
					this.setState({ rotScale : scale });
				}
			}
			
			if (!this.state.drawnOnce)
			{
				this.setState({ drawnOnce : true });
			}
		}
		
		if (err || !bck?.width || !bck?.height) {
			func();
			return;
		}
		
		for (let i = 0; i < areaCanvases.length; i++)
		{
			let canvas = areaCanvases[i] as HTMLCanvasElement;
			if (canvas && canvas.parentNode)
			{
				let b = canvas.parentElement!.getBoundingClientRect();
				let c = canvas.getBoundingClientRect();				
				//let b = this.computeUnrotatedBoundingBox(canvas.parentElement!);
				/*console.log(Math.round(b.width), Math.round(b.height));
				console.log(Math.round(canvas.width * this.realScale()), Math.round(canvas.height * this.realScale()));*/
				if (!canvas.width || !canvas.height ||
					/*Math.round(canvas.width * this.realScale()) != Math.round(b.width) ||
					Math.round(canvas.height * this.realScale()) != Math.round(b.height) || !this.state.drawnOnce*/
					Math.round(b.width) !== Math.round(c.width) ||
					Math.round(b.height) !== Math.round(c.height) || err)
				{					
					func();
					return;
				}
			}
		}

		this.drawBackgroundImageMap();
	}
	
	componentDidMount() {
		window.addEventListener('resize', ()=>{this.updateDimensions(false);} );
		setTimeout(() => { this.updateDimensions(false); }, 10);
		this.redrawInterval = setInterval( this.redrawIfNeeded, 1000);
	}
	
	componentDidUpdate(prevProps : IProps, prevState: IState) {
		if (prevProps.mapId !== this.props.mapId)
			for (let i = 0; i < 10; i++)
				setTimeout(() => { this.updateDimensions(true); }, 100 * i);
	}
	
	componentWillUnmount() {
		window.removeEventListener('resize', ()=>{this.updateDimensions(false);} );
		clearInterval(this.redrawInterval);
	}
	
	rotatePointInBox(box : {left: number, top: number, width: number, height: number}, point : {x: number, y: number}, degrees : number) {
		// Get the center of the box
		var centerX = box.width / 2;
		var centerY = box.height / 2;

		// Convert degrees to radians
		var radians = degrees * Math.PI / 180;

		// Compute the sine and cosine of the angle
		var cos = Math.cos(radians);
		var sin = Math.sin(radians);

		// Translate the point so that the center of the box is the origin
		var translatedX = point.x - centerX;
		var translatedY = point.y - centerY;

		// Rotate the point using the rotation matrix
		var rotatedX = translatedX * cos - translatedY * sin;
		var rotatedY = translatedX * sin + translatedY * cos;

		// Translate the point back to its original position
		var x = rotatedX + centerX;
		var y = rotatedY + centerY;

		// Return the rotated point
		return { x: x, y: y };
	}
	
	computeUnrotatedBoundingBox(element: HTMLElement)
	{
		// This is absolute magic, do not touch it.
		// It will compute the "un-rotated" bounding box of the canvas:
		let bb = element.getBoundingClientRect();
		let w0 = element.clientWidth * this.realScale();
		let h0 = element.clientHeight * this.realScale();
		let b = {
			width: w0,
			height: h0,
			left: bb.x + ((bb.width - w0) / 2),
			top: bb.y + ((bb.height - h0) / 2)
		}
		return b;
	}
	
	findCanvasFromMouseEvent(e: any) {
		let areaCanvases = document.getElementsByClassName("map-area-canvas");
		for (let i = 0; i < areaCanvases.length; i++)
		{
			let canvas = areaCanvases[i] as HTMLCanvasElement;
			
			// This is absolute magic, do not touch it.
			// It will compute the "un-rotated" bounding box of the canvas:
			/*let bb = canvas.getBoundingClientRect();
			let w0 = canvas.clientWidth * this.realScale();
			let h0 = canvas.clientHeight * this.realScale();
			let b = {
				width: w0,
				height: h0,
				left: bb.x + ((bb.width - w0) / 2),
				top: bb.y + ((bb.height - h0) / 2)
			}*/
			let b = this.computeUnrotatedBoundingBox(canvas);
			
			// Rotated point:
			let x = e.clientX - b.left;
			let y = e.clientY - b.top;
			
			// Un-rotated point:
			let r = { x: x, y : y };
			if (this.lastMapSettings)
				r = this.rotatePointInBox(b, r, (this.lastMapSettings.rotation || 0) * -1);
			
			r.x = r.x / this.realScale();
			r.y = r.y / this.realScale();			
		
			let ctx = canvas.getContext('2d', {willReadFrequently: true})!;
			let p = ctx.getImageData(r.x, r.y, 1, 1).data;
			let hex = "#" + ("000000" + this.rgbToHex(p[0], p[1], p[2])).slice(-6);
			if (hex === canvas.getAttribute("data-color"))
			{
				return canvas;
			}
		}
		return null;
	}
	
	goto(id : string)
	{
		if (id && this.props.onAreaClick)
		{
			if (this.lastHoveredCanvas)
			{
				this.lastHoveredCanvas!.parentElement!.style.opacity = "0";						
				this.lastHoveredCanvas!.parentElement!.parentElement!.style.cursor = "initial";
			}
			this.lastHoveredCanvas = null;
			this.props.onAreaClick(id);
		}
	}
	
	hexToRgb(hex: string) {
	  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	  return result ? {
		r: parseInt(result[1], 16),
		g: parseInt(result[2], 16),
		b: parseInt(result[3], 16)
	  } : { r: 0, g: 0, b: 0};
	}
	
	drawBackgroundImageMap()
	{
		let map = (document.getElementById("map-background") as HTMLCanvasElement)!;
		let mask = (document.getElementById("map-background-mask") as HTMLCanvasElement)!;
		let image = (document.getElementById("map-image-original") as HTMLImageElement)!;
		
		if (!this.themedGradientFrom || !this.themedGradientTo || map.width === 0 || map.height === 0)
			{ setTimeout(this.drawBackgroundImageMap, 100); return; } // Not ready yet.
		
		let ctxMap = map.getContext('2d', {willReadFrequently: true})!;
		let ctxMask = mask.getContext('2d', {willReadFrequently: true})!;		
		ctxMask.clearRect(0, 0, map.width, map.height);
		ctxMask.drawImage(image, 0, 0, mask!.width, mask!.height);			
		ctxMap.clearRect(0, 0, map.width, map.height);
		
		let c1 = this.themedGradientFrom;
		let c2 = this.themedGradientTo;
		
		let grd = ctxMap.createLinearGradient(0, 0, map.width, map.height);
		grd.addColorStop(0, c2);
		grd.addColorStop(1, c1);
		ctxMap.fillStyle = grd;
		ctxMap.fillRect(0, 0, map.width, map.height);
		
		let data = ctxMap.getImageData(0, 0, map.width, map.height);
		let datam = ctxMask.getImageData(0, 0, mask.width, mask.height).data;
		
		for (let i = 0; i < datam.length; i += 4) {
            data.data[i + 3] = datam[i + 3];
        }
		ctxMap.putImageData(data, 0, 0);
	}
	
	maskCanvasHover(maskCanvas : HTMLCanvasElement | null, color: string | null,
		theme: any)
	{
		let mcanv = (document.getElementById("map-hover-canvas") as HTMLCanvasElement)!;
		let ctx = mcanv.getContext('2d', {willReadFrequently: true})!;
		ctx.clearRect(0, 0, mcanv.width, mcanv.height);
		if (!maskCanvas || !color) return; // Un-hover.
		
		let c1 = this.themedGradientFrom;
		let c2 = this.themedGradientTo;
		
		let grd = ctx.createLinearGradient(0, 0, mcanv.width, mcanv.height);
		grd.addColorStop(0, c2);
		grd.addColorStop(1, c1);
		ctx.fillStyle = grd;
		ctx.fillRect(0, 0, mcanv.width, mcanv.height);
		
		let ctxm = maskCanvas!.getContext('2d', {willReadFrequently: true})!;
		if (mcanv.width === 0 || mcanv.height === 0) return; // Not ready.
		let data = ctx.getImageData(0, 0, mcanv.width, mcanv.height);
		let datam = ctxm.getImageData(0, 0, maskCanvas.width, maskCanvas.height).data;	
		
		// make all pixels on the display canvas transparent
        // except for pixels matching the selected mask
		let pixel = this.hexToRgb(color)!;
        for (let i = 0; i < datam.length; i += 4) {
            let isInMask=(
				datam[i + 0] === pixel.r &&
				datam[i + 1] === pixel.g &&
				datam[i + 2] === pixel.b &&
				datam[i + 3] > 15
            );
            data.data[i + 3] = (isInMask) ? 128 : 0;
        }
        // put the masked image back to the display canvas
        ctx.putImageData(data, 0, 0);
	}
	
	realScale()
	{
		let mapScale = 1;
		//if (this.lastMapSettings.rotation || (this.lastMapSettings.scale && this.lastMapSettings.scale != 1)) {
			let sc = this.lastMapSettings?.scale || 1;
			if (sc > 20) sc = sc / 100; // User is probably using percentages.
			mapScale = (this.state.rotScale * sc);
		//}
		return mapScale;
	}
	
	render() {
		console.log("Rendering...");
		return (<BrandingContext.Consumer>{branding => {

			let mapSettingsAll = branding.currentBranding.maps || [ branding.currentBranding.map ];
			let mapSettings : any = null;
			
			if (!this.props.mapId && mapSettingsAll && mapSettingsAll.length)
				mapSettings = mapSettingsAll[0];
				
			if (this.props.mapId && mapSettingsAll && mapSettingsAll.length)
			{
				let x = mapSettingsAll.filter((y : any) => y.id === this.props.mapId);
				if (x.length)
					mapSettings = x[0];
			}
			
			this.lastMapSettings = mapSettings;
			
			if (mapSettings && (!mapSettings.image || 
				typeof mapSettings.n === 'undefined' || 
				typeof mapSettings.e === 'undefined' || 
				typeof mapSettings.s === 'undefined' ||
				typeof mapSettings.w === 'undefined'))
				mapSettings = null;

			const image = mapSettings ? '/branding/' + mapSettings.image : MapLight;
			
			let mapStyle = { opacity : 0, transform : "", display: "inline-block" };
			if (this.state.drawnOnce || true) // FIXME
				mapStyle.opacity = 1;
			//let mapScale = 1;
			//if (mapSettings.rotation || (mapSettings.scale && mapSettings.scale != 1)) {
				//let sc = mapSettings.scale;
				//mapScale = (this.state.rotScale * sc);
				//if (sc > 20) sc = sc / 100; // user probably typed percentages instead of floating point.
				mapStyle.transform = "rotate(" + (mapSettings?.rotation || 0) + "deg) scale(" + this.realScale() + ")";
			//}
	
			return <CustomThemeContext.Consumer>{theme => (<span className="mapWrapperPar"><span className="mapWrapper"><span className="mapWrapperSub" style={mapStyle}>				
			<Box sx={{
				maxWidth: "100%",
				background: ({ palette }) => { 
					this.themedGradientFrom = theme.currentTheme.mode === "light" ? palette.primary.main : "#fff";
					this.themedGradientTo = theme.currentTheme.mode === "light" ? palette.secondary.main : "#fff";
					return "linear-gradient(-90deg, " + this.themedGradientFrom + " 0%, " + this.themedGradientTo + " 100%)"; },
				maskImage: `url(${image})`,
				maskSize: "contain",
				maskRepeat: "no-repeat",
				maskPosition: "center",
				lineHeight: 0, // See lineHeight commend bellow.
				visibility: "hidden" // This is obsolete, we now draw it in canvas
			}}>
				{ /* This image stretches the area to max width and height: */ }
				<img src={image} alt="map fill" style={{
					visibility: "hidden",
					width: "100%",
					maxHeight: "calc(100vh - 223px)"					
				}} id="map-image-original" />
			</Box>
			
			<div style={{
				position: "absolute",
				top: "0px",
				width: "100%",
				textAlign: "center",
				height: "100%",
				lineHeight: 0 // See lineHeight commend bellow.
			}}>
			<div style={{
				display: "inline-block",
				position: "relative",
				height: "100%",
				lineHeight: 0 // See lineHeight commend bellow.
			}}>
			<img src={image} alt="map" style={{
				visibility: "hidden",
				height: "100%",
				maxWidth: "100%",
				objectFit: "contain"
			}} onLoad={() => { this.drawBackgroundImageMap(); setTimeout(this.drawBackgroundImageMap, 100); return; } } />
			
			{
				this.props.kiosks && (				
				this.props.kiosks.map((kiosk: IKiosk) => {
				
					let pos = this.relativePositionOfKiosk(kiosk, mapSettings);
					let x = pos.x;
					let y = pos.y;
					
					return (<span className={`kioskDot ${StatusParser.getKioskState(kiosk.status)}`}
						data-name={kiosk.name}
						key={kiosk.identifier} style={{
						top: y + "%",
						right: x + "%",
						transform: "scale(" + (1 / this.realScale()) + ")"
					}}></span>)
				}))
			}
			{
				this.props.selectedKiosk && (
					<span className="mapCursor" style={{ 
						top: this.relativePositionOfKiosk(this.props.selectedKiosk, mapSettings).y + "%",
						right: this.relativePositionOfKiosk(this.props.selectedKiosk, mapSettings).x + "%" 
					}}></span>
				)
			}
			
			<canvas id="map-background">				
			</canvas>
			<canvas id="map-background-mask">
			</canvas>

			<canvas id="map-hover-canvas">
				Your browser does not support HTML Canvas.
			</canvas>
			
			{
				mapSettings && mapSettings.areas && mapSettings.areas.length && mapSettings.areas.map((area : any, index : number) => {										
					
					let canv = <canvas style={{					
						position: "absolute",
						top: 0, left: 0, opacity: 0
					}} className="map-area-canvas" id={"map-area-" + index} data-goto={area.goto} data-color={area.color}
					/>;
					
					let img = new Image();
					img.src = "/branding/" + area.mask; 
					img.onload=() => {
						//this.updateDimensions();
						let canvas = document.getElementById("map-area-" + index) as HTMLCanvasElement; //canvRef.current; //e.target! as HTMLCanvasElement;
						let ctx = canvas?.getContext('2d', {willReadFrequently: true})!;						
						ctx?.drawImage(img, 0, 0, canvas!.width, canvas!.height);
					}
					
					let box = (<Box key={index} sx={{
						maxWidth: "100%",
						/*background: ({ palette }) => theme.currentTheme.mode === "light" ?
					`linear-gradient(-90deg, ${palette.primary.main} 0%, ${palette.secondary.main} 100%)` : "#fff",
						maskImage: "url('/branding/" + area.mask + "')",
						maskSize: "contain",
						maskRepeat: "no-repeat",
						maskPosition: "center",*/
						width: "100%",
						height: "100%",
						position: "absolute",
						lineHeight: 0, // Othervise there is an extra space but only sometimes. It's weirdd AF, I spend an hour trying to debug it. Don't.
						top: 0, left: 0,
						opacity: 0
					}}
					onClick={(e) => {
						let canvas = this.findCanvasFromMouseEvent(e);						
						if (canvas)
						{
							this.goto(canvas.getAttribute("data-goto")!);
						}
					}}
					
					onMouseMove={(e) => {
						let canvas = this.findCanvasFromMouseEvent(e);
						if (canvas === this.lastHoveredCanvas)
							return;
						/*if (this.lastHoveredCanvas)
							this.lastHoveredCanvas!.parentElement!.style.opacity = "0";*/
						if (canvas)
						{
							canvas!.parentElement!.parentElement!.style.cursor = "pointer";
							//canvas!.parentElement!.style.opacity = "0.33";
							this.maskCanvasHover(canvas, canvas!.getAttribute("data-color"), theme);
						}
						else {
							this.lastHoveredCanvas!.parentElement!.parentElement!.style.cursor = "initial";
							this.maskCanvasHover(null, null, null);
						}
						this.lastHoveredCanvas = canvas;							
					}}
					
					onMouseLeave={(e) => {
						if (this.lastHoveredCanvas)
						{
							//this.lastHoveredCanvas!.parentElement!.style.opacity = "0";						
							this.lastHoveredCanvas!.parentElement!.parentElement!.style.cursor = "initial";
						}
						this.lastHoveredCanvas = null;
						this.maskCanvasHover(null, null, null);
					}}
					
					>
						<img alt="area mask" src={"/branding/" + area.mask} style={{
							visibility: "hidden",
							width: "100%",
							height: "100%",
							position: "absolute",
							top: 0, left: 0
						}}
						/>
						{ canv }
						
					</Box>)
					
					return box;
				})
			}	

			{
				mapSettings && mapSettings.buttons && mapSettings.buttons.length &&
				mapSettings.buttons.map((btn : any, index : number) => {
					return (<Button key={index} style={{
						position: "absolute",
						top: (typeof btn.top !== "undefined")? btn.top : "unset",
						left: (typeof btn.left !== "undefined")? btn.left : "unset",
						right: (typeof btn.right !== "undefined")? btn.right : "unset",
						bottom: (typeof btn.bottom !== "undefined")? btn.bottom : "unset",
						width: (typeof btn.width !== "undefined")? btn.width : "unset",
						height: (typeof btn.height !== "undefined")? btn.height : "unset",
						transform: "scale(" + (1 / this.realScale()) + ")"
					}} onClick = {() => { 
						this.goto(btn.goto);
					}} >
						{ btn["desc["+ i18n.language +"]"] || btn["desc"] || btn["desc[en]"] || btn.goto }
					</Button>);
				})
			}
			
			</div></div>
		
		</span></span></span>)}</CustomThemeContext.Consumer>
		}}</BrandingContext.Consumer>);
	}

}

export default withTranslation()(KioskMap);