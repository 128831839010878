export interface IUserContextController {
	key : string,
	name : string,
	author : string,
	version : string,
	deployment : string
}

export interface IUserContextPermission {
	module : string,
	name : string,
	description : string,
	permission : string
}

export interface IUserContext {
	name : string,
	id : number,
	controllers : IUserContextController[],
	permissions : IUserContextPermission[],
}

export class UserContext implements IUserContext {
	name = "??";
	id = -1;
	controllers :  IUserContextController[] = [];
	permissions : IUserContextPermission[] = [];
}

export const permissions = {
	auth: {
		modifyPermissions: "MODIFY_PERMISSIONS",
	},
	board: {
		create: "CREATE_BOARD",
		read: "READ_BOARD",
		update: "UPDATE_BOARD",
		delete: "DELETE_BOARD",
	},
	configuration: {
		read: "READ_CONFIGURATION",
		write: "WRITE_CONFIGURATION",
	},
	content: {
		read: "READ_CONTENT",
		write: "WRITE_CONTENT",
	},
	kiosk: {
		create: "CREATE_KIOSK",
		read: "READ_KIOSK",
		update: "UPDATE_KIOSK",
		delete: "DELETE_KIOSK",
		modifyTags: "MODIFY_KIOSK_TAGS",
		modifyButtons: "MODIFY_KIOSK_BUTTONS",
		modifyUptimes: "MODIFY_UPTIME_SCHEDULE",
		modifyAudio: "MODIFY_AUDIO_SCHEDULE",
		reboot: "REBOOT_KIOSK",
		resync: "RESYNC_KIOSK",
		restart: "RESTART_KIOSK",
	},
	media: {
		read: "READ_MEDIA",
		write: "WRITE_MEDIA",
	},
	playlist: {
		create: "CREATE_PLAYLIST",
		read: "READ_PLAYLIST",
		update: "UPDATE_PLAYLIST",
		delete: "DELETE_PLAYLIST",
		manageArea: "MANAGE_AREA_"
	},
	shared: {
		read: "READ_SHARED",
		write: "WRITE_SHARED"
	},
	tag: {
		create: "CREATE_TAG",
		read: "READ_TAG",
		update: "UPDATE_TAG",
		delete: "DELETE_TAG",
	},
	user: {
		create: "CREATE_USER",
		read: "READ_USER",
		update: "UPDATE_USER",
		delete: "DELETE_USER",
		setPassword: "SET_USER_PASSWORD",
	},
	statistics: {
    	read: "READ_STATISTICS",
    	delete: "DELETE_STATISTICS",
	}
};

export interface ICrudPermissions {
	create: string,
	read: string,
	update: string,
	delete: string
}

export function isAuthorized(userContext: IUserContext, permission: string) {
	return userContext.permissions.some(x => x.permission === permission);
}

export const controllerKeys = {
	admin: "v1/admin",
	auth: "v1/auth",
	board: "v1/board",
	bulk: "v1/bulk",
	configuration: "v1/configuration",
	content: "v1/content",
	file: "v1/file",
	kiosk: "v1/kiosk",
	media: "v1/media",
	playlist: "v1/playlist",
	shared: "v1/shared",
	tag: "v1/tag",
	user: "v1/user",
	statistics: "v1/statistics"
};

export enum ControllerDeployment {
	Common = 'common', Global = 'global', Local = 'local'
}

export function hasController(userContext: IUserContext, controllerKey: string, deployment?: ControllerDeployment) {
	if (deployment)
		return userContext.controllers.some(x => x.key === controllerKey && x.deployment === deployment);
	return userContext.controllers.some(x => x.key === controllerKey);
}