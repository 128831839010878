import http from "../../system/Communicator";
import { FileArray, FileData } from 'chonky';
import { IFileEntries, ISearchResult, IFileRecord, recordToData } from './Files.model'
import fileDownload from 'js-file-download';

// This is not an ICrudService because it is way more specific and doesn't really use "our" model
// - instead it works with FileArray from the Chonky library.
class FileService {

	async getAll(directory : string, path? : string) : Promise<FileArray> {
		const sub = path ? `?subdirectory=${path}` : '';		
		let obj = (await http.get<IFileEntries>(`/file/${directory}${sub}`)).data;				
		if (obj === null || obj.entries === null)
			return [];
		
		let arr : FileArray = [];
		for (let i = 0; i < obj.entries.length; i++)
			arr.push(recordToData(obj.entries[i]));
		
		return arr;
	}

	async getItem(directory : string, path : string) : Promise<FileData> {
		let obj = (await http.get<IFileRecord>(`/file/${directory}${this.encodePath(path)}`)).data;
		return recordToData(obj);
	}

	async createFolder(directory : string, path: string) {
		const sub = path ? `?subdirectory=${path}` : '';
		await http.post(`/file/${directory}${sub}`);
	}
	
	async deleteItem(directory : string, path : string) {
		await http.delete(`/file/${directory}${this.encodePath(path)}`);
	}

	private encodePath(path: string) {
		// It makes no sense since the URL will look/like%2Fthis with just the FIRST
		// slash normal and then encoded slashes for the rest but the API requires it
		// like this and I'm too tired to argue with Vlasta so whatever.
		return "/" + encodeURIComponent(path.substr(1));
	}
	
	async moveItems(directory : string, source : string, destination : string) {
		await http.post(`/file/${directory}/move`, JSON.stringify({
			source : source,
			destination : destination
		}));
	}
	
	async copyItems(directory : string, source : string, destination : string) {
		await http.post(`/file/${directory}/copy`, JSON.stringify({
			source : source,
			destination : destination
		}));
	}
	
	async search(directory : string, query : string): Promise<ISearchResult[]> {
		const results = await http.get<ISearchResult[]>(`/file/${directory}/search/${query}`);
		return results?.data ?? [];
	}

	async existsInDirectory(directory : string, recordName : string): Promise<boolean> {
		const x = recordName.lastIndexOf("/");
		const dir = recordName.substring(0, x);
		const file = recordName.substring(x+1);
		/*const sub = dir ? `?subdirectory=${dir}` : '';
		const results = (await http.get<IFileRecord[]>(`/file/${directory}${sub}`)).data;*/
		
		const all = await this.getAll(directory, dir);		
		return all.some((record) => record!.name === file);
		
		/*let results = await this.search(directory, recordName);
		return results.some((record) => record.name === recordName);*/
	}
	
	async getBlob(url : string) {
		return await http.get<Blob>(url, {
			responseType: 'blob',
			setToken : () => {}
		});
	}
	
	async setContent(directory : string, path : string, content : string) {
		let sub = path.substring(0,path.lastIndexOf("/"));
		let file = path.substring(path.lastIndexOf("/")+1);
		
		let formdata = new FormData();		
		let blob = new Blob([content], {type : 'text/plain'});
		formdata.append("file", blob, file);
		
		await http.post(`/file/${directory}?overwrite=true&subdirectory=${sub}`, formdata);
	}
	
	async createFile(directory : string, path: string) {
		let sub = path.substring(0,path.lastIndexOf("/"));
		let file = path.substring(path.lastIndexOf("/")+1);
		
		let formdata = new FormData();		
		let blob = new Blob([""], {type : 'text/plain'});
		formdata.append("file", blob, file);
		
		await http.post(`/file/${directory}?overwrite=true&subdirectory=${sub}`, formdata);
	}
	
	async download(url : string, fileName : string) {
		let res = await this.getBlob(url);
		fileDownload(res.data, fileName);
	}
	
	async getThumbnail(url : string) : Promise<string> {
		let res : any = await this.getBlob(url);
		var imageUrl = URL.createObjectURL(res.data);
		return imageUrl;
	}
}

export default new FileService();