import React, { Fragment } from 'react';
import TabletMacIcon from '@mui/icons-material/TabletMac';
import { Typography } from '@mui/material';
import KiosksService from './Kiosks.service';
import { withTranslation, WithTranslation } from 'react-i18next'
import DataTable, { Attribute } from '../../system/DataTable'
import { IKiosk, StatusParser } from './Kiosks.model'
import hoistStatics from 'hoist-non-react-statics';
import { hideTagsIfCannotModify, kioskAttributes } from './KioskAttributes.model';
import {IEntry, ISearchResult} from "../../system/SearchResult.model"
import IModule, { ModuleState } from "../../system/IModule"
import { setPageTitle } from '../../App'
import { ControllerDeployment, controllerKeys, hasController, isAuthorized, IUserContext, permissions } from '../../system/User.model'
import { AuthContext } from '../../system/Base';
import { CRUDOperation } from '../../system/CRUDOperation';
import CloneConfig from './CloneConfig';
import { Modal } from '../../system/Modal';

interface IState {
	kiosks: IKiosk[]
	cloneConfigFor: IKiosk | null
}
interface IProps extends WithTranslation { }

@IModule
class Kiosks extends React.Component<IProps, IState, WithTranslation> {

	constructor(props: IProps) {		
		super(props);
		this.state = {
			kiosks: [],
			cloneConfigFor: null
		};

		this.onBeforeSubmit = this.onBeforeSubmit.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}
	
	public static getLocale() { return "module.kiosks";	}
	
	componentDidMount()
	{
		setPageTitle(this.props.t( Kiosks.getLocale()+".title", { ns: Kiosks.getLocale() }));
	}
	
	public static menu(t: ((x:string, y:any)=>string)) {
		return {
			title: (t && t(this.getLocale()+".title", {ns:this.getLocale()})) || "???",
			route : "/panels",
			icon : <TabletMacIcon />,
			weight : 10
		};
	}
	
	public static isEnabled(auth: IUserContext) { 
		if (!hasController(auth, controllerKeys.kiosk, ControllerDeployment.Global))
			return ModuleState.DISABLED;
		if (isAuthorized(auth, permissions.kiosk.read))
			return ModuleState.ENABLED;
			
		return ModuleState.NO_PERMISSIONS;
	}	
	
	public static async search(input : string): Promise<ISearchResult[]> {
		let res = await KiosksService.search(input);		
		return res.map((r: IEntry<IKiosk>) : ISearchResult => {
			let status = StatusParser.getKioskState(r.entity.status).toString();
			return {
				title : r.entity.name,
				description : r.entity.description,
				icon : <span className={`kiosk-search-result-icon ${status}`}><TabletMacIcon /></span>,
				link : "/panels?search-result-index=" + r.index
			}});
	}
	
	onBeforeSubmit(auth: IUserContext, kiosk: IKiosk, _operation: CRUDOperation) {
		if (!isAuthorized(auth, permissions.kiosk.modifyTags)) {
			// A bit of a hack: Remove tags to avoid modifying them in kiosk service
			delete kiosk.tags;
		}
	}

	onSubmit(auth: IUserContext, kiosk: IKiosk, operation: CRUDOperation) {
		if (operation === CRUDOperation.Create && kiosk.id && CloneConfig.isEnabled(auth))
			this.setState({ cloneConfigFor: kiosk });
	}

	render() {
	
		const t = this.props.t;
	
		return (<Fragment>
		<Typography variant="h2">
			{ t("module.kiosks.title", {ns:"module.kiosks"}) }
		</Typography>
		
		<AuthContext.Consumer>{(auth) => (
			<DataTable service={KiosksService} entityType='panels' entityName={t("module.kiosks.entity", {ns:'module.kiosks'})} 
				beforeSubmit={(kiosk, operation) => this.onBeforeSubmit(auth, kiosk, operation)}
				onChange={(kiosk, operation) => this.onSubmit(auth, kiosk, operation)}
				permissions={permissions.kiosk} create={true} edit={true} delete={true}>
			
				{hideTagsIfCannotModify(auth, kioskAttributes).map((attribute) => <Attribute key={attribute.name} {...attribute} />)}
			</DataTable>
		)}
		</AuthContext.Consumer>

		{this.state.cloneConfigFor &&
            <Modal title={t(`module.kiosks.clone-config`, {ns: 'module.kiosks'})} isOpen={this.state.cloneConfigFor !== null} onClose={() => this.setState({ cloneConfigFor: null })}>
                <CloneConfig kiosk={this.state.cloneConfigFor} onSubmit={() => this.setState({ cloneConfigFor: null })} />
            </Modal>}
		
		</Fragment>);
	}
}

export default hoistStatics(withTranslation()(Kiosks), Kiosks)