import http from "../../system/Communicator";
import { IKiosk } from "./Kiosks.model"
import { ITag } from "../tags/Tags.model";
import { IButton } from "../dashboard/Button.model";
import { IAudioSchedule, IUptimeSchedule } from "../dashboard/Schedule.model";
import { IKioskConfigService } from "./Kiosks.service";

interface ILocalKioskService extends IKioskConfigService {
	getLocal(withRelatedData?: boolean): Promise<IKiosk>;
} 

class LocalKioskService implements ILocalKioskService {

	async getLocal(withRelatedData: boolean = true): Promise<IKiosk> {
		let kiosk = (await http.get<IKiosk>(`/kiosk?enrich=${withRelatedData}`)).data;
		return kiosk;
	}
		
	async edit(entity: IKiosk): Promise<IKiosk> {
		throw new Error("Kiosk service missmatch: expected global, got local. (edit)");
	}

	async getTags(entity: IKiosk): Promise<ITag[]> {
		throw new Error("Kiosk service missmatch: expected global, got local. (getTags)");
	}

	async setTags(entity: IKiosk, tags: ITag[]): Promise<void> {
		throw new Error("Kiosk service missmatch: expected global, got local. (setTags)");
	}

	async getAudioSchedule(entity: IKiosk): Promise<IAudioSchedule> {
		return (await http.get<IAudioSchedule>("/kiosk/audio-schedule")).data;
	}

	async setAudioSchedule(entity: IKiosk, schedule: IAudioSchedule): Promise<void> {
		return (await http.post<void>("/kiosk/audio-schedule", schedule)).data;
	}

	async getUptimeSchedule(entity: IKiosk): Promise<IUptimeSchedule> {
		return (await http.get<IUptimeSchedule>("/kiosk/uptime-schedule")).data;
	}

	async setUptimeSchedule(entity: IKiosk, schedule: IUptimeSchedule): Promise<void> {
		return (await http.post<void>("/kiosk/uptime-schedule", schedule)).data;
	}

	async reboot(entity: IKiosk): Promise<void> {
		await http.post<void>("/kiosk/reboot");
	}

	async resync(entity: IKiosk): Promise<void> {
		throw new Error("Kiosk service missmatch: expected global, got local. (resync)");
	}

	async restartApp(entity: IKiosk): Promise<void> {
		await http.post<void>("/kiosk/restart");
	}

	async getButtons(entity: IKiosk): Promise<IButton[]> {
		return (await http.get<IButton[]>("/kiosk/buttons")).data;
	}
	
	async setButtons(entity: IKiosk, buttons: IButton[]): Promise<void> {
		return (await http.post<void>("/kiosk/buttons", buttons)).data;
	}
}
export default new LocalKioskService();
export type { ILocalKioskService }