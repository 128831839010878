export const encodeAsParams = <T extends Object>(obj: T, defaultObj: T) => {
    let result = '';
  
    for (const [key, value] of Object.entries(obj)) {
      if (defaultObj.hasOwnProperty(key) && value !== undefined && value !== null && value !== '') {
        let valueStr = encodeURIComponent(String(value)).trim();
  
        if (Array.isArray(value) && typeof value !== 'string') {
            for (let i = 0; i < value.length; ++i)
              result += `${key}=${encodeURIComponent(String(value[i])).trim()}&`;
            continue;
        }
  
        result += String(key) + '=' + valueStr + '&';
      }
    }
  
    if (result === '')
      return '';
    return result.slice(0, result.length - 1); // remove trailing &
};
  
export const combineQueries = (...queries: string[]) => queries.filter((q) => q).join('&');