import React, { Fragment } from 'react';
import hoistStatics from 'hoist-non-react-statics';
import { withTranslation, WithTranslation } from 'react-i18next';
import RuleIcon from '@mui/icons-material/Rule';
import { Typography } from '@mui/material';
import IModule, { ModuleState } from "../../system/IModule"
import { ControllerDeployment, controllerKeys, hasController, isAuthorized, IUserContext, permissions } from '../../system/User.model'
import FileEditor from '../files/FileEditor'
import { setPageTitle } from '../../App'
import { AuthContext } from '../../system/Base';

interface IState { }
interface IProps extends WithTranslation { }

@IModule
class Whitelist extends React.Component<IProps, IState, WithTranslation> {

	public static menu(t: ((x:string, y:any)=>string)) {
		return {
			title: (t && t(Whitelist.getLocale()+".title", {ns:Whitelist.getLocale()})) || "???",
			route : "/whitelist",
			icon : <RuleIcon />,
			weight : 30
		};
	}
	
	public static getLocale() { return "module.whitelist"; }
	
	componentDidMount()
	{
		setPageTitle(this.props.t( Whitelist.getLocale()+".title", { ns: Whitelist.getLocale() }));
	}
	
	public static search(input : string) {
		return null;
	}
	
	public static isEnabled(auth: IUserContext) {
		// A bit of a hack: Hide whitelist (as a separate page) in local admin
		if (hasController(auth, controllerKeys.kiosk, ControllerDeployment.Local))
			return ModuleState.DISABLED;
		if (!hasController(auth, controllerKeys.configuration))
			return ModuleState.DISABLED;
		if (isAuthorized(auth, permissions.configuration.read))
			return ModuleState.ENABLED;
			
		return ModuleState.NO_PERMISSIONS;
	}
	
	render() {
		return (<Fragment>
		<Typography variant="h2">
			{ this.props.t(Whitelist.getLocale()+".title", {ns:Whitelist.getLocale()}) }
		</Typography>
		
		<AuthContext.Consumer>{auth => {
			const readonly = !isAuthorized(auth, permissions.configuration.write);
			return <>
				<FileEditor directory="CONFIGURATION" path="/whitelist.txt" highlightLanguage="ini" readonly={readonly} />
				<style>{`.file-editor-area { height: calc(100vh - 285px); overflow: auto; }`}</style>
			</>
		}}
		</AuthContext.Consumer>
		</Fragment>);
	}

}

export default hoistStatics(withTranslation()(Whitelist), Whitelist)