import React, { Fragment } from 'react';
import { Box, Button, CircularProgress, IconButton, Tab, Tabs, Typography } from '@mui/material';
import { withTranslation, WithTranslation } from 'react-i18next'
import hoistStatics from 'hoist-non-react-statics';
import IModule, { ModuleState } from "../../system/IModule"
import { setPageTitle } from '../../App'
import { controllerKeys, hasController, IUserContext } from '../../system/User.model'
import { IEnvVariable, createDefaultEnvVariable } from './Admin.model';
import AdminService from './Admin.service';

import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import RestoreIcon from '@mui/icons-material/Restore';

import FileExplorer from '../files/FileExplorer';
import { Field, FieldArray, Form, Formik, FormikProps } from 'formik';
import MuiField from '../../system/MuiField';
import { DateTime } from 'luxon';
import { advancedTextFilter } from '../files/Files.model';


interface IState {
    info: any,
    formValues: {
        variables: IEnvVariable[],
    },
    currentTab: number,

    isSaving: boolean,
    lastSave?: DateTime,
}
interface IProps extends WithTranslation { }

@IModule
class Admin extends React.Component<IProps, IState, WithTranslation> {

    service = AdminService;

    creatableFiles = Array.from(new Set(advancedTextFilter.map(ext => ext.toLowerCase().replace('*', ''))));

	constructor(props: IProps) {		
		super(props);
		this.state = {
            info: {},
            formValues: { variables: [] },
            currentTab: 0,

            isSaving : false,
		};

        this.refresh = this.refresh.bind(this);
        this.changeVariables = this.changeVariables.bind(this);
        this.variableForm = this.variableForm.bind(this);
        this.renderInfo = this.renderInfo.bind(this);
	}
	
	public static getLocale() { return "module.admin";	}
	
	async componentDidMount() {
		setPageTitle(this.props.t( Admin.getLocale()+".title", { ns: Admin.getLocale() }));
        await this.refresh();
    }

    async refresh() {
        const infoP = this.service.getInfo();
        const variablesP = this.service.getEnvVariables();

        const [ info, variables ] = await Promise.all([ infoP, variablesP ]);
        this.setState({ info, formValues: { variables }});
	}
	
	public static menu(t: ((x:string, y:any)=>string)) {
		return {
			title: (t && t(this.getLocale()+".title", {ns:this.getLocale()})) || "???",
			route : "/admin",
			icon : <AdminPanelSettingsIcon />,
			weight : 1000
		};
	}
	
	public static isEnabled(auth: IUserContext) { 
		if (!hasController(auth, controllerKeys.admin) || auth.id !== 1)
			return ModuleState.DISABLED;
        return ModuleState.ENABLED;
	}	
	
	public static async search(input : string) {
		return null;
	}

    async changeVariables(values: IState['formValues']): Promise<void> {
		this.setState({ isSaving: true }, async () => {
            await this.service.setEnvVariables(values.variables);
            await this.refresh();
            this.setState({ isSaving: false, lastSave: DateTime.now() });
		})
    }

    variableForm(props: FormikProps<IState['formValues']>) {
        const t = this.props.t;
        const ns = Admin.getLocale();

        const submitIcon = this.state.lastSave && DateTime.now() < this.state.lastSave.plus({ seconds: 2 })
            ? <CheckIcon /> : <SaveIcon />;

        return <Form className='modal-form '>
                    <FieldArray name="variables" render={arrayHelpers => (
                        <Box>
                            {props.values.variables.map((variable, index, array) =>
                                <Box key={index} sx={{
                                    display: "flex",
                                    flexWrap: { xs: "wrap", sm: "nowrap" },
                                    alignItems: "baseline",
                                    justifyContent: "space-between",
                                    columnGap: "8px",
                                    mb: { xs: "24px", sm: 0 },
                                }}>
                                    <Field name={`variables.${index}.name`} labelKey='variable-name' namespace={ns} fullWidth={false} component={MuiField} />
                                    <Field name={`variables.${index}.value`} labelKey='variable-value' namespace={ns} component={MuiField} />
                                    <IconButton onClick={() => arrayHelpers.remove(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            )}
                            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'center', columnGap: '1rem', rowGap: '1rem' }}>
                                <Button variant="contained"
                                    startIcon={<AddIcon />}
                                    onClick={() => arrayHelpers.push(createDefaultEnvVariable())}>
                                    {t('common.add')}
                                </Button>
                                <Button variant="contained" onClick={props.submitForm}
                                    startIcon={
                                        this.state.isSaving ? <span style={{zoom : 0.5}}><CircularProgress /></span>
	                					: submitIcon
                                    }>
                                    {t('common.save')}
                                </Button>
                                <Button variant="contained" onClick={() => props.resetForm()} startIcon={<RestoreIcon />}>
                                    {t('common.restore')}
                                </Button>
                            </Box>
                    </Box>
                    )} />
            </Form>
    }

    renderInfo(info: any) {
        if (typeof info === 'object')
            return <table><tbody>
                {Object.keys(info).map((key) => 
                    <tr key={key}>
                        <td key='key'>{key}</td>
                        <td key='value'>{this.renderInfo(info[key])}</td>
                    </tr>
                )}
            </tbody></table>;
        
        if (Array.isArray(info))
            return <table><tbody>
                    {info.map((value, index) => 
                        <tr key={index}>
                            <td key='index'>{index}</td>
                            <td key='value'>{this.renderInfo(value)}</td>
                        </tr>
                    )}
                </tbody></table>;
        
        return String(info);
    }
	
	render() {
		const t = this.props.t;
        const ns = Admin.getLocale();
	
		return (<Fragment>
		<Typography variant="h2">
			{ t("module.admin.title", {ns}) }
		</Typography>

        <Box sx={{ mt: 3, border: 1, borderColor: 'divider', boxShadow: 4 }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={this.state.currentTab} variant='scrollable' scrollButtons='auto' allowScrollButtonsMobile
                    onChange={(_event, value) => this.setState({ currentTab: value })}>
                    <Tab label={t("module.admin.info", {ns})} />
                    <Tab label={t("module.admin.variables", {ns})} />
                    <Tab label={t("module.admin.files", {ns})} />
                </Tabs>
            </Box>
            <TabPanel value={this.state.currentTab} index={0}>
                <Box sx={{
                    overflow: "auto",
                    "& table": {
                        width: "100%",
                        borderCollapse: "collapse",
                        borderColor: "divider",
                        borderLeftWidth: "1px",
                        borderLeftStyle: "solid",
                    },
                    "& > table": {
                        boxShadow: 4,
                    },
                    "& tr": {
                        borderBottomWidth: "1px",
                        borderBottomStyle: "solid",
                    },
                    "& tr:last-of-type": {
                        borderBottom: "none",
                    },
                    "& td": {
                        padding: ".1em 1em",
                        whiteSpace: "nowrap",
                    },
                    "& td:last-of-type": {
                        width: "99%",               
                    },
                }}>
                    {this.renderInfo(this.state.info)}
                </Box>
            </TabPanel>

            <TabPanel value={this.state.currentTab} index={1}>
                <Formik enableReinitialize initialValues={this.state.formValues} onSubmit={this.changeVariables} component={this.variableForm} />
            </TabPanel>

            <TabPanel value={this.state.currentTab} index={2}>
                <FileExplorer folder="admin" mode="full" allowCreate={this.creatableFiles} allowOpen={advancedTextFilter} />
            </TabPanel>
        </Box>

		</Fragment>);
	}
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        <Box sx={{ p: 3, display: value === index ? 'inherit' : 'none' }}>
        <Typography component='div'>{children}</Typography>
        </Box>
      </div>
    );
  }

export default hoistStatics(withTranslation()(Admin), Admin)