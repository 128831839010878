import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import UserService from './Users.service';
import Checkbox from '@mui/material/Checkbox';
import { IPermission } from './Users.model';
import { FieldProps } from 'formik';
import { Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { triggerFormChange } from '../../system/Formik.model';


interface IState { 
	allPermissions : IPermission[][],
	expandedModule: string,
	selected: IPermission[]
}

interface IProps extends FieldProps<IPermission[]> {
}

class PermissionSelect extends React.Component<IProps, IState> {

	constructor(props : IProps) {
		super(props);

		this.state = {
			allPermissions: [],
			expandedModule: '',
			selected: this.props.field.value ?? []
		}

		this.hasPermission = this.hasPermission.bind(this);
		this.togglePermission = this.togglePermission.bind(this);
	}
	
	async componentDidMount() {
		const allPerms = UserService.groupByModule(await UserService.getAllPermissions());
		this.setState({
			allPermissions: allPerms
		});
	}

	hasPermissions(module: IPermission[]): boolean | undefined {
		const all = module.every(p => this.hasPermission(p));
		const any = module.some(p => this.hasPermission(p));
		if (all) return true;
		if (!any) return false;
		return undefined;
	}

	hasPermission(permission: IPermission): boolean {
		return this.state.selected.filter(p => p.permission === permission.permission).length > 0 ?? false;
	}

	togglePermissions(module: IPermission[]) {
		const hasPerms: boolean | undefined = this.hasPermissions(module);

		const without = this.state.selected.filter((p) => p.module !== module[0].module) ?? [];
		if (hasPerms === true) {
			this.setSelected(without);
		} else {
			const selected = without.concat(module);
			this.setSelected(selected);
		}
	}

	togglePermission(permission: IPermission) {
		if (!this.hasPermission(permission)) {
			const selected = this.state.selected !== undefined
				? [...this.state.selected, permission]
				: [ permission ];
			this.setSelected(selected);
		} else {
			const selected = this.state.selected.filter((p) => p.permission !== permission.permission);
			this.setSelected(selected);
		}
	}

	toggleModuleOpen(module: string) {
		this.setState({ expandedModule: this.state.expandedModule === module ? '' : module });
	}

	setSelected(permissions: IPermission[]) {
		this.setState({ selected: permissions });
		triggerFormChange(this.props.form, this.props.field.name, permissions, 'permissionSelect');
	}

	render() {
		const hasPermissions = this.state.allPermissions?.map((module) => this.hasPermissions(module));

		return <>
		{ !this.state.allPermissions && (<Box sx={{ display: 'flex' }}>
			<CircularProgress />
		</Box>) }
		{ this.state.allPermissions && (
			<List sx={{ p: 0 }}>
				{this.state.allPermissions &&
				this.state.allPermissions.map((module, mindex) => (
					<div key={module[0].module}>
					<ListItem
						disablePadding
						secondaryAction={
						<Checkbox
							edge="end"
							checked={Boolean(hasPermissions[mindex])}
							indeterminate={hasPermissions[mindex] === undefined}
							tabIndex={-1}
							disableRipple
							onChange={() => this.togglePermissions(module)}
						/>
					}>
					<ListItemButton onClick={() => this.toggleModuleOpen(module[0].module)} dense>
							<ListItemIcon>
								{this.state.expandedModule === module[0].module ? <ExpandLess /> : <ExpandMore />}
						    </ListItemIcon>
						<ListItemText primary={module[0].module} />
					</ListItemButton>
				  </ListItem>

					<Collapse in={this.state.expandedModule === module[0].module} timeout="auto" unmountOnExit>
						<List component="div" disablePadding>

						{module.map((permission) => (
							<ListItemButton dense
								key={permission.permission}
								sx={{ height: '36px'}}
								onClick={() => this.togglePermission(permission) }>
								<ListItemIcon>
									<Checkbox
										size="small"
										checked={this.hasPermission(permission)}
										tabIndex={-1}
										disableRipple
									/>
						    	</ListItemIcon>
								<ListItemText primary={permission.name} />
							</ListItemButton>
						))}
				  	</List>
				  </Collapse>
				  </div>
				))}
			</List>
			)}
		</>
	}
}

const PermissionSelectF = (props: FieldProps<IPermission[]>) => {
	return <PermissionSelect {...props}></PermissionSelect>
}

export { PermissionSelectF }
export default PermissionSelect