import React from 'react';
import UserService, { IUserService } from './Users.service';
import { withTranslation, WithTranslation } from 'react-i18next'
import { IPermissionChange } from './Users.model';
import SaveIcon from '@mui/icons-material/Save';
import { Field, Form, Formik } from 'formik';
import { Button } from '@mui/material';
import hoistStatics from 'hoist-non-react-statics';
import { PermissionSelectF } from './PermissionSelect';

interface IState {
	entity: IPermissionChange,
    initialized: boolean // FIX ME?
}
interface IProps extends WithTranslation {
    userId: number,
    onDone: () => void
}

class PermissionChange extends React.Component<IProps, IState, WithTranslation> {
	
	userService: IUserService;

	public constructor(props: IProps) {
		super(props);

		this.state = {
			entity: {
                id: this.props.userId,
                permissions: []
            },
            initialized: false
		};

		this.userService = UserService;
        this.changePermissions = this.changePermissions.bind(this);
	}

    async componentDidMount() {
		const userPerms = await UserService.getUserPermissions(this.props.userId);
        const entity = {...this.state.entity, permissions: userPerms};
		this.setState({
            entity: entity,
            initialized: true
		});
	}

	async changePermissions(permissionChange: IPermissionChange) {
		await this.userService.setUserPermissions(permissionChange);
        this.props.onDone();
	}
	
	render() {
		const t = this.props.t;

        return this.state.initialized && (<>
                <Formik
                    initialValues={this.state.entity}
                    onSubmit={async (p: IPermissionChange) => await this.changePermissions(p)}>
                    <Form className="modal-form">
                        <Field name="permissions" component={PermissionSelectF} />
                        <Button sx={{ mt: '2rem' }} variant="contained" type="submit" startIcon={<SaveIcon />}>
                            {t("common.save", {ns:'common'})}
                            </Button>
                    </Form>
                </Formik>
            </>);
	}
}

export default hoistStatics(withTranslation()(PermissionChange), PermissionChange)