import React from "react"
import { IAttribute } from "./CRUDAttribute"
import { CRUDForm, IEntity, IFormConfig } from "./CRUDForm"
import { Modal } from "./Modal"
import { CRUDButton } from "./CRUDButton"
import { IIdentifiable } from "./Identifiable.model"
import { CRUDOperation } from "./CRUDOperation"
import { IPagedResponse, PageQuery, Pagination } from "./Pagination.model"
import { Box, Pagination as MUIPagination, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
/**
 * TODO: fix access to translations.
 * Using i18next directly is probably ugly. However:
 * - withTranslation() breaks type parameter checking
 * - useTranslation() can be used only in functional components
 * - <Trans> and <Translation> return Element, not string
 */
 import i18next from "i18next";
import { ICrudPermissions, isAuthorized } from "./User.model"
import { AuthContext } from "./Base"

export class Sorting<TEntity extends IEntity> {
    field: keyof TEntity;
    direction: 'ascending' | 'descending';
  
    constructor(attribute: keyof TEntity, ascending: boolean) {
      this.field = attribute;
      this.direction = ascending ? 'ascending' : 'descending';
    }
  
    toString(): string {
      return `sort=${this.direction === 'ascending' ? '' : '-'}${String(this.field)}`;
    }
}

interface ITableConfig<TEntity extends IEntity> {
    sort: Sorting<TEntity>
    page: PageQuery
}

interface IState<TEntity extends IEntity> {
	entities: TEntity[],
    attributes: IAttribute<TEntity>[],
    sort: Sorting<TEntity>,
    pagination: Pagination,
    createShown: boolean,
    updateShown: boolean,
    deleteShown: boolean,
    currentEntity: TEntity | null,
    selectedPageEntry?: number,
}

interface IProps<TEntity extends IEntity> {
	fetchItems: (_: ITableConfig<TEntity>) => Promise<IPagedResponse<TEntity>>,
    createDefaultEntity: () => TEntity,
    createForm: IFormConfig<TEntity> | null,
    updateForm: IFormConfig<TEntity> | null,
    deleteForm: IFormConfig<TEntity> | null,
    permissions: ICrudPermissions,
	children: React.ReactElement<IAttribute<TEntity>>[],
}

export type { ITableConfig }

export class CRUDTable<TEntity extends IIdentifiable & IEntity> extends React.Component<IProps<TEntity>, IState<TEntity>> {
	
    isRefreshing: boolean = false;
    selectedEntryRef: React.RefObject<any>;

    constructor(props: IProps<TEntity>) {		
		super(props);

        const attrs = this.props.children.map(child => child.props);
		this.state = {
            entities: [],
            attributes: attrs,
            sort: {field: 'id', direction: 'ascending'},
            pagination: new Pagination(0, null, 20),
            createShown: false,
            updateShown: false,
            deleteShown: false,
            currentEntity: null,
		};

        this.sort = this.sort.bind(this);
        this.changePage = this.changePage.bind(this);
        this.showCreate = this.showCreate.bind(this);
        this.showUpdate = this.showUpdate.bind(this);
        this.showDelete = this.showDelete.bind(this);
        this.onCreate = this.onCreate.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.selectEntry = this.selectEntry.bind(this);

        this.selectedEntryRef = React.createRef();
	}

    async componentDidMount() {
        await this.refresh();
    }

    async selectEntry(entry: number) {
        if (this.isRefreshing) {
            setTimeout(() => this.selectEntry(entry), 100);
            return;
        }
            
        const { page, position } = this.state.pagination.getPagePosition(entry);
            
        this.setState(() => ({ selectedPageEntry: position }), async () => {
            await this.refresh({ field: 'id', direction: 'ascending' }, page);
        });
    }

    async refresh(sort?: Sorting<TEntity>, page?: PageQuery) {
        this.isRefreshing = true;

        const p = page ?? this.state.pagination.getQuery();
        const newSort = sort ?? this.state.sort;
        const pagedResponse = await this.props.fetchItems({sort: newSort, page: p});

        this.setState(() => ({
            entities: pagedResponse.data,
            sort: newSort,
            pagination: new Pagination(pagedResponse.total, pagedResponse.current, p.limit)
        }), () => {
            this.isRefreshing = false;
        });
    }

    async changePage(_event: React.ChangeEvent<unknown>, newPage: number) {
        this.setState(() => ({selectedPageEntry: undefined}));
        await this.refresh(undefined, new PageQuery(newPage, this.state.pagination.perPage));
    }

    async sort(attribute: IAttribute<TEntity>) {
        const sameAsc = this.state.sort.field === attribute.name && this.state.sort.direction === 'ascending';
        const newSort = new Sorting<TEntity>(attribute.name, !sameAsc);

        this.setState(() => ({selectedPageEntry: undefined}));
        await this.refresh(newSort);
    }

    showCreate(entity: TEntity | null) {
        this.setState(() => ({
            currentEntity: entity,
            createShown: Boolean(entity)
        }));
    }

    showUpdate(entity: TEntity | null) {
        this.setState(() => ({
            currentEntity: entity,
            updateShown: Boolean(entity)
        }));
    }

    showDelete(entity: TEntity | null) {
        this.setState(() => ({
            currentEntity: entity,
            deleteShown: Boolean(entity)
        }));
    }

    async onCreate(entity: TEntity) {
        await this.props.createForm?.onSubmit(entity);
        this.showCreate(null);
        await this.refresh();
    }

    async onUpdate(entity: TEntity) {
        await this.props.updateForm?.onSubmit(entity);
        this.showUpdate(null);
        await this.refresh();
    }

    async onDelete(entity: TEntity) {
        await this.props.deleteForm?.onSubmit(entity);
        this.showDelete(null);
        await this.refresh();
    }

	render() {
        const borderColorStyle = {borderColor: 'divider'};

        let header = <TableHead sx={{backgroundColor: 'divider', opacity: 0.6}}><TableRow sx={borderColorStyle}>
            {this.state.attributes.filter(a => !a.hideInTable).map(a =>
                <TableCell key={a.name.toString()} sx={borderColorStyle}
                    className={a.sortable ? 'sortable' : ''} onClick={async () => a.sortable && await this.sort(a)}>

                    {i18next.t(`${a.namespace}.${a.labelKey}`, {ns: a.namespace}) + ''}
                    {this.state.sort.field === a.name &&
                        <span>{this.state.sort.direction === 'ascending' ? '▲' : '▼'}</span>}
                </TableCell>)}
            {this.props.updateForm && <TableCell key="actions" sx={borderColorStyle}>
                {i18next.t('crud.actions') + ''}
            </TableCell>}
        </TableRow></TableHead>;
        
        let body = <TableBody>
            {this.state.entities.map((e, i) => 
                <TableRow key={e.id} selected={i === this.state.selectedPageEntry} sx={borderColorStyle}
                    ref={i === this.state.selectedPageEntry ? this.selectedEntryRef : undefined}>
                    {
                        this.state.attributes.filter(attr => !attr.hideInTable).map((attr, i) =>
                        <TableCell key={attr.name.toString()} sx={borderColorStyle}>
                            {attr.tableValueResolver ? attr.tableValueResolver(e, attr.name) : String(e[attr.name])}
                        </TableCell>)
                    }
                    {(this.props.updateForm || this.props.deleteForm) &&
                        <AuthContext.Consumer>{auth => (<TableCell key="actions" sx={borderColorStyle}>
                            {this.props.updateForm &&
                                <CRUDButton variant="outlined" role="update" disabled={!isAuthorized(auth, this.props.permissions.update)}
                                    onClick={() => this.showUpdate(e)} />}
                            {this.props.deleteForm &&
                                <CRUDButton variant="outlined" role="delete" disabled={!isAuthorized(auth, this.props.permissions.delete)}
                                    onClick={() => this.showDelete(e)} />}
                        </TableCell>)}
                        </AuthContext.Consumer>}
                </TableRow>
            )}
        </TableBody>;

        if (this.state.selectedPageEntry !== undefined && this.selectedEntryRef.current)
            this.selectedEntryRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

        return <>
            <AuthContext.Consumer>{auth => (<>
                {this.props.createForm && <CRUDButton variant="contained" role="create"
                    disabled={!isAuthorized(auth, this.props.permissions.create)} onClick={() => this.showCreate(this.props.createDefaultEntity())} />}
            
                {this.props.createForm && this.state.createShown && this.state.currentEntity &&
                <Modal title={this.props.createForm.title} isOpen={this.state.createShown} onClose={() => this.showCreate(null)}>
                    <CRUDForm {...this.props.createForm}
                        onSubmit={this.onCreate}
                        entity={this.state.currentEntity}
                        role={CRUDOperation.Create}
                        attributes={this.state.attributes} />
                </Modal>}

                <MUIPagination className="crud-pagination"
                    count={this.state.pagination.totalPages}
                    page={this.state.pagination.currentPage}
                    onChange={this.changePage} />

                {isAuthorized(auth, this.props.permissions.read) && <Box sx={{ overflow: 'auto', clear: 'both', border: 1, borderColor: 'divider', boxShadow: 4 }}>
                    <Table className="crud-table" stickyHeader sx={{ border: 'none' }}>
                        {header}
                        {body}
                    </Table>
                </Box>}

                {this.props.updateForm && this.state.updateShown && this.state.currentEntity &&
                    <Modal title={this.props.updateForm.title} isOpen={this.state.updateShown} onClose={() => this.showUpdate(null)}>
                        <CRUDForm {...this.props.updateForm}
                            onSubmit={this.onUpdate}
                            entity={this.state.currentEntity}
                            role={CRUDOperation.Update}
                            attributes={this.state.attributes} />
                    </Modal>}
                
                {this.props.deleteForm && this.state.deleteShown && this.state.currentEntity &&
                <Modal title={this.props.deleteForm.title} isOpen={this.state.deleteShown} onClose={() => this.showDelete(null)}>
                    <CRUDForm {...this.props.deleteForm}
                        onSubmit={this.onDelete}
                        entity={this.state.currentEntity}
                        role={CRUDOperation.Delete}
                        attributes={this.state.attributes} />
                </Modal>}

                {isAuthorized(auth, this.props.permissions.read) && <MUIPagination className="crud-pagination"
                    count={this.state.pagination.totalPages}
                    page={this.state.pagination.currentPage}
                    disabled={!isAuthorized(auth, this.props.permissions.read)}
                    onChange={this.changePage} />}
            
            </>)}
            </AuthContext.Consumer>
        </>;
    }
}