import React, { Fragment } from 'react';
import hoistStatics from 'hoist-non-react-statics';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Modal } from '../../system/Modal';
import { CRUDButton } from '../../system/CRUDButton';
import { CustomThemeContext, ICustomThemeContext }  from '../../system/CustomThemeProvider';
import { TextField, Button, IconButton, Select, MenuItem, FormControl, FormControlLabel, Radio } from '@mui/material';
import { FileArray,
	FileNavbar,
	FileToolbar,
	FileList,
	FileData,
	FileContextMenu,
	FileBrowser,
	ChonkyActions,
	defineFileAction,
	ChonkyIconName,
	MapFileActionsToData,
	ChonkyActionUnion,
	FileAction,
	FileBrowserHandle,
	} from 'chonky';
import FileService from './Files.service';
import snackNotifications from "../../system/SnackBarUtils";
import 'react-dropzone-uploader/dist/styles.css'
import './file-upload.css'
import Dropzone, { IDropzoneProps, IFileWithMeta } from 'react-dropzone-uploader'
import { API_URL, BE_ROOT } from "../../system/Communicator";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddIcon from '@mui/icons-material/Add';
import { Navigate } from 'react-router-dom';
import { filterToExtension, hasCorrectExtension, isImage, isVideo, textFilter } from './Files.model';
import FileEditor from './FileEditor';
import PlaylistService from '../playlists/Playlist.service';
import { save } from '../../App';
import { canEditSlides, IAddFilesRequest } from '../playlists/Playlists.model';
import { DateTime } from 'luxon';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { isAuthorized, IUserContext, permissions } from '../../system/User.model';
import { AuthContext } from '../../system/Base';
import "./file-explorer.css";
import { WithBreakpointProps, withBreakpoints } from '../../system/Responsive';

/*import { ChonkyIconFA } from 'chonky-icon-fontawesome';*/

interface IState {
	files : FileArray,
	selectedFiles : FileData[],
	path : FileData[],
	creatingFolder : boolean,
	makingPlaylist : boolean,
	deletingFiles : boolean,
	showingUpload : boolean,
	renamingItem : boolean,
	newFolderName : string,
	newFileName : string,
	newPlaylistName : string,
	newFileExtension : string,
	renameOldName : string,
	renameName : string,
	forDeletion : FileData[],
	lastDropZoneEvent : Date,
	isLoading : boolean,
	playlistIsNew : boolean,
	existingPlaylists : string[],
	selectedPlaylist : string,
	copiedPaths : string[],
	
	inputDialogOpened : boolean,
	value : string[],
	filter : string[],
	redirectingTo : string,
	filesForPlaylist : string[],
	
	digDownFromSearchResult : string[],
	highlightAfterDigDown : string,
	highlightActive : boolean,
	highlightedElement : HTMLElement | null,
	
	realTimeEditingText : string | null,
	creatingFile : boolean,	
}
interface IProps extends WithTranslation, WithBreakpointProps {
	folder : string,
	mode : "full" | "input",
	//files? : string[] // no idea why this was here
	value? : string | string[],
	onChange? : (values : string | string[] | undefined) => void,
	filter? : string[],
	output? : "singleFile" | "multipleFiles" | "folder" | "fileOrFolder",
	allowCreate? : string[],
	allowOpen? : string[],
	allowPlaylistMaking?: boolean,
	readonly? : boolean,
}

class FileExplorer extends React.Component<IProps, IState, WithTranslation> {

	static defaultProps: Partial<IProps> = { allowOpen: textFilter };

	static contextType: React.Context<IUserContext> = AuthContext;
	context!: React.ContextType<React.Context<IUserContext>>;

	systemSelectionChanging : boolean
	dialogRef : React.RefObject<FileBrowserHandle> // The whole component of Chonky
	boxRef : React.RefObject<HTMLSpanElement>
	renameFieldRef : any // For autoselect of part of the filename (without extension)
	newFolderFieldRef : any // For autoselect of the input of new folder
	newFileFieldRef : any // For autoselect of the input of new folder
	playlistNameRef : any
	
	highlightRefresher : any // timeinterval for highlighting
	
	arrayize(obj : string | string[] | undefined) : string[] {		
		if (!obj) return [] as string[];
		if (Array.isArray(obj))
			return obj as string[];
		else
			return [obj] as string[];
	}

	constructor(props: IProps) { 
		super(props);
		this.state = {
			files: [],
			selectedFiles : [],
			path: [this.rootFolder],
			creatingFolder : false,
			makingPlaylist : false,
			newFolderName : "",
			newPlaylistName : "",
			newFileName : "",
			newFileExtension : (this.props.allowCreate && this.props.allowCreate.length) ? this.props.allowCreate[0] : "",
			deletingFiles : false,
			renamingItem : false,
			renameOldName : "",
			renameName : "",
			showingUpload : false,
			forDeletion : [],
			filesForPlaylist : [],
			lastDropZoneEvent : new Date(),
			isLoading : false,
			playlistIsNew : false,
			existingPlaylists : [],
			selectedPlaylist : "",
			copiedPaths : [],
			
			inputDialogOpened : false,
			value : this.arrayize(this.props.value || []),
			filter : filterToExtension(this.props.filter ?? []),
			
			redirectingTo : "",
			digDownFromSearchResult : [],
			highlightAfterDigDown : "",
			highlightActive : false,
			highlightedElement : null,
			
			realTimeEditingText : null,
			
			creatingFile : false,
		};
		
		this.dialogRef = React.createRef<FileBrowserHandle>()
		this.boxRef = React.createRef<HTMLSpanElement>()
		this.renameFieldRef = React.createRef()
		this.newFolderFieldRef = React.createRef()
		this.newFileFieldRef = React.createRef()
		this.playlistNameRef = React.createRef()
		this.highlightRefresh = this.highlightRefresh.bind(this);
		this.systemSelectionChanging = false
	}
	
	getPathString = () => {
		// We ignore the first hardcoded record and start from 2nd:
		let p = "";
		for (var i = 1; i < this.state.path.length; i++)
			p += "/" + this.state.path[i].name;
		return p;
	}
	
	mapOfIds : { [id : string] : string } = { "**root**" : "/" }
	
	isCorrectExtension = (file : FileData) => {
		if (file.isDir) return true;
		return hasCorrectExtension(file.name, this.state.filter);
	}
	
	async reloadFiles()	
	{		
		this.setState({isLoading : true});
		let p = this.getPathString();
		//if (this.state.files.length > 0) return;
		let x = await FileService.getAll(this.props.folder, p);
		
		let f = x.filter(x => { return x && this.isCorrectExtension(x)});
		// Map the IDs of all items to their path and name:		
		for (let i = 0; i < f.length; i++) {
			this.mapOfIds[f[i]!.id] = this.getPathString() + "/" + f[i]!.name;
			
			if (f[i]!.name === this.state.highlightAfterDigDown)
				this.highlightFile(f[i]!.name);
				/*f[i]!.color = "yellow";*/
		}

		this.setState((state) => { return { files : f, isLoading : false }}, this.digDownIfSearchResult);
	}
	
	async reloadPlaylists()
	{
		if (!isAuthorized(this.context, permissions.playlist.read))
			return;
		
		let x = await PlaylistService.getAll();
		let arr = [];
		for (var i = 0; i < x.data.length; i++) {
			let y = x.data[i].name;
			arr.push(y);
		}
		this.setState({ existingPlaylists : arr, selectedPlaylist : (arr.length > 0)?arr[0]:"" });
	}
	
	highlightFile(f : string)
	{
		/* TODO: So far it looks like there is no easy way to implement this in Chonky.
			Let's just hack the DOM and do this ourselves. */
		
		let a = document.querySelector("div.chonky-fileListWrapper div>div>span[title='" + f + "']");
		if (a)
		{
			let elem = a.parentElement!.parentElement!;			
			elem.classList.add("search-highlight-file");
			if (this.state.highlightedElement !== elem)
				this.setState((_) => { return { highlightedElement : elem } });
		} else {
			setTimeout(() => { this.highlightFile(f) }, 10 );
		}
	}
	
	componentDidMount()
	{
		this.reloadFiles();
		this.highlightRefresher = setInterval(this.highlightRefresh, 1000);
		this.reloadPlaylists();
	}
	
	componentWillUnmount()
	{
		if (this.highlightRefresher)
		{
			clearInterval(this.highlightRefresher)
			this.highlightRefresher = null;
		}
	}
	
	highlightRefresh()
	{
		if (this.state.highlightAfterDigDown) {
			this.highlightFile(this.state.highlightAfterDigDown);
		}
	}
	
	rootFolder = {
		id : "**root**",
		name : this.props.folder,
		isDir : true
	};
	
	findFolderInPath(id : string)
	{
		for (var i = 0; i < this.state.path.length; i++) {
			if (this.state.path[i].id === id)
				return this.state.path[i];
		}
		return null;
	}
	
	goDown(newFolder : FileData)
	{
		let arr = this.state.digDownFromSearchResult;
		if (arr.length > 0 && arr[0] === newFolder.name)
			arr.shift();
	
		this.setState({ path: [...this.state.path, newFolder], digDownFromSearchResult : arr}, 
			this.reloadFiles
		);
	}
	
	goUp(oldFolder : FileData)
	{
		let arr : FileData[] = [];
		for (var i = 0; i < this.state.path.length; i++) {
			arr.push(this.state.path[i]);
			if (this.state.path[i].id === oldFolder.id) {
				this.setState({ path: arr }, 
					this.reloadFiles
				);
				return;
			}
		}
	}
	
	nameExistsHere(folderName : string) : boolean {
		for (var i = 0; i < this.state.files.length; i++) {
			if (this.state.files[i]!.name === folderName /*&& this.state.files[i]!.isDir*/)
				return true;
		}
		return false;
	}
	
	makePlaylist() {
		const obj = {
			files : this.state.filesForPlaylist,
			playlist : this.state.playlistIsNew ? this.state.newPlaylistName : this.state.selectedPlaylist,
			isNew : this.state.playlistIsNew,
			requested : DateTime.now().toISO()
		} as IAddFilesRequest;
		save('use-files-in-playlist', JSON.stringify(obj));
		
		this.setState({ redirectingTo : "/playlists" });
	}
	
	async createFolder(folderName : string)
	{
		if (this.nameExistsHere(folderName)) {
			snackNotifications.error(this.props.t("module.files.folder-already-exists", {ns : "module.files"}));
		} else {
			let path = this.getPathString() + "/" + folderName;
			await FileService.createFolder(this.props.folder, path);
			this.setState({ creatingFolder: false }, this.reloadFiles);
		}
	} 
	
	async createFile(fileName : string)
	{
		if (this.nameExistsHere(fileName)) {
			snackNotifications.error(this.props.t("module.files.file-already-exists", {ns : "module.files"}));
		} else {
			let path = this.getPathString() + "/" + fileName;
			await FileService.createFile(this.props.folder, path);
			this.setState({ creatingFile: false }, async ()=>{
				await this.reloadFiles();
				this.openFile(fileName, 1, 1, "");
			});
		}
	} 
	
	async renameItem(oldName : string, newName : string)
	{ 
		await FileService.moveItems(this.props.folder,
			this.getPathString() + "/" + oldName,
			this.getPathString() + "/" + newName
		);
		this.setState({ renamingItem: false }, this.reloadFiles);
	}
	
	async deleteItems(forDeletion : FileData[])
	{
		for (var i = 0; i < forDeletion.length; i++) {
			let path = this.getPathString() + "/" + forDeletion[i].name;
			await FileService.deleteItem(this.props.folder, path);
		}
		this.setState({ deletingFiles: false }, this.reloadFiles);
	}
	
	async thumbnailGenerator(file : FileData) : Promise<string> {
		if (file.thumbnailUrl)
			return await FileService.getThumbnail(file.thumbnailUrl || "");
		else
			return "";
	}
	
	renameAction = defineFileAction({
		id : "rename_files",
		requiresSelection: true,
		button : {
			toolbar : true,
			group: "Actions",
			contextMenu : true,
			icon : ChonkyIconName.folderChainSeparator,
			name : "Rename item",
			tooltip : "Rename item"
		},
		hotkeys : ["F2"]
	});
	
	copyAction = defineFileAction({
		id : "copy_files",
		requiresSelection: true,
		button : {
			toolbar : true,
			group: "Actions",
			contextMenu : true,
			icon : ChonkyIconName.copy,
			name : "Copy items",
			tooltip : "Copy items"
		},
		hotkeys : ["ctrl+c"]
	});
	
	pasteAction = defineFileAction({
		id : "paste_files",
		requiresSelection: false,
		button : {
			toolbar : true,
			group: "Actions",
			contextMenu : true,
			icon : ChonkyIconName.copy,
			name : "Paste items",
			tooltip : "Paste items"
		},
		hotkeys : ["ctrl+v"]
	});
	
	createAction = defineFileAction({
		id : "create_file",
		requiresSelection: false,
		button : {
			toolbar : true,
			contextMenu : true,
			icon : ChonkyIconName.text,
			name : "Create file",
			tooltip : "Create file"
		}		
	});
	
	makePlaylistAction = defineFileAction({
		id : "make_playlist",
		requiresSelection: true,
		button : {
			toolbar : false,
			contextMenu : true,
			icon: ChonkyIconName.video,
			name : "Use in playlist",
			tooltip : "Use in playlist"
		},
		fileFilter : (file: FileData | null) : boolean => {
			return file ? !file.isDir : false;
		}
	});
	
	inputAddFile = (arr : FileData[]) => {
		// If we are in select folder mode, do nothing.
		if (this.props.output === "folder")
			return;
			
		arr = arr.filter(x => x); // No undefineds and other bs.
		if (arr.length === 0)
			return;
	
		let files = [];
		for (let i = 0; i < arr.length; i++) {
			let f = "/" + this.props.folder + this.getPathString() + "/" + arr[i].name;
			files.push(f);
		}
		if (!this.props.output || this.props.output === "singleFile" || this.props.output === "fileOrFolder")
			this.setState({ value : [files[files.length - 1]] });
		else
			this.setState({ value : Array.from(new Set([...this.state.value, ...files])) });
	}
	
	inputRemoveFile = (filePath : string) => {
		if (this.props.output === "folder")
			this.setState({ value : [] }, this.dialogFolderKo);
		else
			this.setState({ value : this.state.value.filter(x => x !== filePath) }, this.dialogOk);
	}
	
	dialogOk = () => {
		// If we are in select folder mode, do nothing.
		if (this.props.output === "folder")
			return;
			
		this.setState({ inputDialogOpened : false }, () => {
			if (this.props.onChange) {
				if (!this.props.output || this.props.output === "singleFile")
				{
					if (this.state.value.length > 0)
						this.props.onChange(this.state.value[this.state.value.length - 1]);
					else
						this.props.onChange(undefined);
				}
				else
					this.props.onChange(this.state.value);
			}
		});
	}
	
	diggingDown = false;	
	digDownIfSearchResult() {
		if (this.diggingDown) { return; }
		this.diggingDown = true;
		if (!this.state) {
			// Component not ready yet.
			setTimeout(this.digDownIfSearchResult, 10);
			this.diggingDown = false;
			return;
		}
		if (this.state.digDownFromSearchResult.length === 0) {
			this.startHighlighting();
			this.diggingDown = false;
			return;			
		}
		let next = this.state.digDownFromSearchResult[0];
		let f = this.state.files.find(x => x!.isDir && x!.name === next);
		if (f)
		{
			setTimeout(() => { this.diggingDown = false; this.goDown(f!); }, 10);			
			// reload will call this procedure again.
		} else {
			setTimeout(() => { this.diggingDown = false; this.digDownIfSearchResult(); }, 10);
		}
	}
	
	startHighlighting()
	{
		if (this.state.highlightAfterDigDown) {
			this.setState(state => { 
				return { highlightActive : true }
			});
		}
	}
	
	openRecordFromSearch(path: string) {
		let p = path.split('/');
		let arr : string[] = [];
		for (let i = 1; i < p.length - 1; i++) {
			let name = p[i];
			arr.push(name);
		}
		
		// Race condition if using object-based setState, needs to be like this:
		this.setState((prevState) => {
			return { digDownFromSearchResult : arr, path : [ this.rootFolder ], highlightAfterDigDown : p[p.length-1] }
		}, async () => { await this.reloadFiles(); this.digDownIfSearchResult(); });
	}
	
	async copyPaste()
	{
		let to = this.getPathString() + "/";
		for (let i = 0; i < this.state.copiedPaths.length; i++) {
			let fr = this.state.copiedPaths[i];
			var filename = fr.replace(/^.*[\\\/]/, '')
			await FileService.copyItems(this.props.folder, fr, to + filename);
		}		
		this.reloadFiles();
		// this.setState({ copiedPaths : [] });
	}
	
	componentDidUpdate(prevProps : IProps, prevState : IState) {
		
		if (prevProps.allowCreate !== this.props.allowCreate)
			this.setState({ newFileExtension : (this.props.allowCreate && this.props.allowCreate.length) ? this.props.allowCreate[0] : "" });
		if (prevProps.value !== this.props.value)
			this.setState({ value : this.arrayize(this.props.value || []) });
		if (prevProps.filter !== this.props.filter)
			this.setState({ filter : filterToExtension(this.props.filter ?? []) });
	
		const urlParams = new URLSearchParams(window.location.search);
		const openPath = urlParams.get('search-file-path');
		if (!this.state.redirectingTo && openPath && openPath.split('/')[0] === this.props.folder) {
			urlParams.delete('search-file-path');
			let cleanUrl = window.location.pathname + urlParams.toString();
			
			// We are using a bit of a hack. We set the new url as a new state and let it render
			// which triggers the Navigation component. Then we remove new URL in the callback
			// of the first set state, which happens AFTER the first Navigation is triggered,
			// and it also prevents further navigation looping. So these lines are basically
			// just a redirect and nothing else. And in the second callback, finally open the record.
			this.setState((state) => {return { redirectingTo : cleanUrl }}, () => {
				this.setState((state) => {return { redirectingTo : "" }}, () => {
					this.openRecordFromSearch(openPath);
				});
			});
		}
	}	
	
	dialogFolderKo = () => {
		this.setState({ inputDialogOpened : false }, () => {
			if (this.props.onChange) {
				this.props.onChange(undefined);
			}
		});
	}
	
	dialogFolderOk = (selected : boolean) => {
		let tail = "";
		if (selected)
			tail = "/" + this.state.selectedFiles[0].name;
		let path = "/" + this.props.folder + this.getPathString() + tail;
		this.setState({ inputDialogOpened : false, value : [path] }, () => {
			if (this.props.onChange) {				
				this.props.onChange(path);
			}
		});
	}
	
	clearedOfColor(f : FileData)
	{
		if (!f.color)
			return f;
			
		let x = { 
			id : f.id,
			name : f.name,
			isDir : f.isDir,
			isHidden : f.isHidden,
			isSymlink : f.isSymlink,
			isEncrypted : f.isEncrypted,
			openable : f.openable,
			selectable : f.selectable,
			draggable : f.draggable,
			droppable : f.droppable,
			size : f.size,
			modDate : f.modDate,
			childrenCount : f.childrenCount,
			icon : f.icon,
			thumbnailUrl : f.thumbnailUrl,
			// but no color.
		}
		return x;
	}
	
	handleAction = (data: MapFileActionsToData<ChonkyActionUnion>, openFileAction: (files: FileData[]) => void, afterOpeningFiles: () => void) => {

		if (data.id === ChonkyActions.ChangeSelection.id) {
			// We don't really care that much about selection, but we need to save it in case
			// this component is used as a dialog (input) in which case there is a button to
			// confirm our selection. And we need to know what is selected.
			if (this.state.highlightActive)
			{
				if (this.state.highlightedElement)
					this.state.highlightedElement.classList.remove("search-highlight-file");
				this.setState({selectedFiles: data.state.selectedFiles, highlightAfterDigDown : "", highlightActive : false, highlightedElement : null});
			} else
				this.setState({selectedFiles: data.state.selectedFiles });
			
			// Also, if the output is Single file (or null, undefined...), we need to set
			// the selection to only one file, the last one in the selection.
			if (((!this.props.output || this.props.output === "singleFile" || this.props.output === "folder" || this.props.output === "fileOrFolder") && this.dialogRef.current) && this.props.mode === "input" ) {
				if (!this.systemSelectionChanging) {
					this.systemSelectionChanging = true; // no setState; just atomic memory set
					const ids = new Set<string>();
					if (data.state.selectedFiles.length > 0) {
						const selectedFiles = data.state.selectedFiles[data.state.selectedFiles.length - 1];
						if (selectedFiles)
							ids.add(selectedFiles.id);
					}
					this.dialogRef.current.setFileSelection(ids);
				} else
					this.systemSelectionChanging = false;
			}
		}
		
		if (data.id === this.copyAction.id) {
			let arr = [];
			for (let i = 0; i < data.state.selectedFilesForAction.length; i++ ) {			
				arr.push(this.getPathString() + "/" + data.state.selectedFilesForAction[i].name);
			}
			this.setState({ copiedPaths : arr });
		}
		
		if (data.id === this.pasteAction.id) {
			this.copyPaste();
		}
		
		if (data.id === ChonkyActions.OpenFiles.id)
		{
			if (data.payload.files.length === 1 && data.payload.files[0].isDir)
			{
				// Open folder
				// Beware! It can mean opening a folder where we are or going down,
				// or it can also mean going back up!
				let f = this.findFolderInPath(data.payload.files[0].id);
				if (f === null) {
					// We are going down to a new unopened folder.
					this.goDown(data.payload.files[0]);
				} else {
					// We are going back up.
					this.goUp(data.payload.files[0]);
				}
				
				// Clearing the filter. This is a hack - we are emulating react events on the "input" component.
				// The Chonky library does not allow us to do it in a proper way so this is the only choice.
				// #TODO #FIXME
				var input = (this.boxRef.current as HTMLSpanElement)!.querySelector("input.chonky-searchFieldInputInner");				
				var nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value")!.set;
				nativeInputValueSetter!.call(input, ''); // Clear the value of input.				
				var ev2 = new Event('input', { bubbles: true});
				input!.dispatchEvent(ev2); // Call the actual change event.
				
			} else {
				// Open file or some random mixture
				let arr = data.payload.files;
				openFileAction(arr.filter(x => !x.isDir ));				
				afterOpeningFiles();
			}
		}
		
		if (data.id === ChonkyActions.CreateFolder.id)
		{
			this.setState({creatingFolder : true, newFolderName : ""}, ()=>{
				setTimeout(() => {
					let input = this.newFolderFieldRef.current;
					input.focus();
				}, 10);
			});
		}
		
		if (data.id === ChonkyActions.DeleteFiles.id) {		
			let arr = data.state.selectedFilesForAction;
			this.setState({deletingFiles : true, forDeletion : arr});
		}
		
		if (data.id === ChonkyActions.UploadFiles.id )
		{
			this.setState({showingUpload : !this.state.showingUpload});
		}
		
		if (data.id === ChonkyActions.MoveFiles.id )
		{
			let srcFolder = data.payload.source ? this.mapOfIds[data.payload.source.id] : null;
			let dstFolder = this.mapOfIds[data.payload.destination.id];
			if (srcFolder === null || dstFolder === null)
				snackNotifications.error(this.props.t("module.files.browser-move-error", {ns : "module.files"}));		
			else {
				(async () => {
					for (var i = 0; i < data.payload.files.length; i++) {
						let f = data.payload.files[i].name;
						await FileService.moveItems(this.props.folder, srcFolder + "/" + f, dstFolder + "/" + f);
					}
					this.reloadFiles();
				})();
			}
		}
		
		if (data.id === ChonkyActions.DownloadFiles.id)
		{
			let arr = data.state.selectedFilesForAction;
			for (var i = 0; i < arr.length; i++) {
				if (arr[i].url)
				FileService.download(arr[i].url, arr[i].name);
			}
		}
		
		if (data.id === this.renameAction.id)
		{
			// There will always be at least one file for this action, othervise it is disabled.
			var f = data.state.selectedFilesForAction[0];
			this.setState({renamingItem : true, renameOldName : f.name, renameName : f.name}, ()=>{
				setTimeout(() => {
					let input = this.renameFieldRef.current;
					let end = input.value.lastIndexOf('.');
					if (end < 1)
						end = input.value.length;
					if( input.createTextRange ) {
						let selRange = input.createTextRange();
						selRange.collapse(true);
						selRange.moveStart('character', 0);
						selRange.moveEnd('character', end);
						selRange.select();
					} else if( input.setSelectionRange ) {
						input.setSelectionRange(0, end);
					} else if( input.selectionStart ) {
						input.selectionStart = 0;
						input.selectionEnd = end;
					}
					input.focus();				
				}, 10);
			});
		}
		
		if (data.id === this.createAction.id)
		{
			// Show a dialog for file name and extension selection.
			this.setState({ creatingFile : true, newFileName : "" }, ()=>{
				setTimeout(() => {
					let input = this.newFileFieldRef.current;
					input.focus();
				}, 10);
			});
		}
		
		if (data.id === this.makePlaylistAction.id)
		{
			let arr = data.state.selectedFilesForAction;
			let files = [];
			for (let i = 0; i < arr.length; i++) {
				if (!arr[i].isDir) {
					let f = "/" + this.props.folder + this.getPathString() + "/" + arr[i].name;
					files.push(f);
				}
			}
			if (files.length > 0) {
				this.setState({ 
					makingPlaylist : true,
					newPlaylistName : "",
					filesForPlaylist : files, 
					playlistIsNew : isAuthorized(this.context, permissions.playlist.create),
				}, ()=>{
					setTimeout(() => {
						let input = this.playlistNameRef.current;
						input.focus();
					}, 10);
				});
			}
		}
		
		/*console.log(data);*/
	};
	
	openFile(url : string, num : number, cnt : number, physicalPath : string)
	{
		// TODO: We could have some mechanic to edit multiple files in a batch
		// or one after the other, or something. That's why there's num and cnt.
		
		var re = /(?:\.([^.]+))?$/;
		var ext = re.exec(url)![1] || "*";
		
		if (cnt > 1 || !this.props.allowOpen?.some(allowed => allowed.endsWith("." + ext)))
			window.open(physicalPath, '_blank')!.focus();
		else
		{
			this.setState({ realTimeEditingText : url });
		}
	}
       	
	render() {
	
		const t = (x : string) => { return this.props.t("module.files." + x, {ns : "module.files"}); };
		const b = (x : string) => { return this.props.t("module.files.browser-" + x, {ns : "module.files"}); };
		
		const messages = {
			'chonky.toolbar.searchPlaceholder': b('search'),
			'chonky.toolbar.visibleFileCount': b('file-count'),
			'chonky.toolbar.selectedFileCount': b('selected-count'),
			'chonky.toolbar.hiddenFileCount': b('hidden-count'),			
			'chonky.fileList.nothingToShow': b('nothing'),
			'chonky.contextMenu.browserMenuShortcut': b('normal-menu'),
			[`chonky.actionGroups.Actions`]: b('actions'),
			[`chonky.actionGroups.Options`]: b('options'),
			[`chonky.actions.${ChonkyActions.OpenParentFolder.id}.button.name`]: b('open-parent-folder'),
			[`chonky.actions.${ChonkyActions.CreateFolder.id}.button.name`]: b('create-folder'),
			[`chonky.actions.${ChonkyActions.CreateFolder.id}.button.tooltip`]: b('create-folder'),
			[`chonky.actions.${ChonkyActions.DeleteFiles.id}.button.name`]: b('delete-files'),
			[`chonky.actions.${ChonkyActions.OpenSelection.id}.button.name`]: b('open-selection'),
			[`chonky.actions.${ChonkyActions.SelectAllFiles.id}.button.name`]: b('select-all-files'),
			[`chonky.actions.${ChonkyActions.ClearSelection.id}.button.name`]: b('clear-selection'),
			[`chonky.actions.${ChonkyActions.EnableListView.id}.button.name`]: b('enable-list-view'),
			[`chonky.actions.${ChonkyActions.EnableGridView.id}.button.name`]: b('enable-grid-view'),
			[`chonky.actions.${ChonkyActions.SortFilesByName.id}.button.name`]: b('sort-files-by-name'),
			[`chonky.actions.${ChonkyActions.SortFilesBySize.id}.button.name`]: b('sort-files-by-size'),
			[`chonky.actions.${ChonkyActions.SortFilesByDate.id}.button.name`]: b('sort-files-by-date'),
			[`chonky.actions.${ChonkyActions.ToggleHiddenFiles.id}.button.name`]: b('toggle-hidden-files'),
			[`chonky.actions.${ChonkyActions.ToggleShowFoldersFirst.id}.button.name`]: b('toggle-show-folders-first'),
			[`chonky.actions.${ChonkyActions.UploadFiles.id}.button.name`]: b('upload-files'),
			[`chonky.actions.${ChonkyActions.UploadFiles.id}.button.tooltip`]: b('upload-files'),
			[`chonky.actions.${ChonkyActions.DownloadFiles.id}.button.name`]: b('download-files'),
			[`chonky.actions.rename_files.button.name`]: t('rename-item'),
			[`chonky.actions.copy_files.button.name`]: t('copy-items'),
			[`chonky.actions.paste_files.button.name`]: t('paste-items'),
			[`chonky.actions.copy_files.button.tooltip`]: t('copy-items'),
			[`chonky.actions.paste_files.button.tooltip`]: t('paste-items'),
			[`chonky.actions.rename_files.button.tooltip`]: t('rename-item'),
			[`chonky.actions.create_file.button.name`]: t('new-file'),
			[`chonky.actions.create_file.button.tooltip`]: t('new-file'),
			[`chonky.actions.make_playlist.button.name`]: t('making-playlist'),
			[`chonky.actions.make_playlist.button.tooltip`]: t('making-playlist')
		}
		const chonkyI18N = {
			locale : this.props.i18n.language,
			messages : messages
		}
		
		const getUploadParams: IDropzoneProps['getUploadParams'] = (fileWithMeta : IFileWithMeta) => {
			this.setState({ lastDropZoneEvent : new Date() }); // Force refresh			
			return { 
			url: API_URL + "/file/" + this.props.folder + "?subdirectory=" + this.getPathString(),				
			headers : {
				'Authorization' : sessionStorage.getItem('dp2_token') || ""
			}
			};
		};
		
		const handleChangeStatus = () => {
			this.setState({ lastDropZoneEvent : new Date() }); // Force refresh
		}
		
		const handleSubmit: IDropzoneProps['onSubmit'] = (files, allFiles) => {
			allFiles.forEach(f => f.remove());
			this.setState({showingUpload : false}, this.reloadFiles);
		}
	
		let fileActions : FileAction[] = [];
		
		let createFolderActionWithContextMenu = Object.create(ChonkyActions.CreateFolder);
		
		createFolderActionWithContextMenu.button.contextMenu = true;

		if (this.props.mode === "full") {
			if (this.props.readonly)
				fileActions = [ ChonkyActions.DownloadFiles ];
			else
				fileActions = [
					ChonkyActions.UploadFiles,
					createFolderActionWithContextMenu,
					ChonkyActions.DownloadFiles,
					this.copyAction,
					this.pasteAction,
					this.renameAction,
					ChonkyActions.DeleteFiles,
				];
		}
			
		if (!this.props.readonly && this.props.allowCreate && this.props.allowCreate.length) {
			fileActions.splice(1, 0, this.createAction);
		}
		
		const canCreatePlaylist = isAuthorized(this.context, permissions.playlist.create);
		const canAddToPlaylist = isAuthorized(this.context, permissions.playlist.read) && canEditSlides(this.context);

		if (this.props.allowPlaylistMaking && canAddToPlaylist) {
			fileActions.splice(2, 0, this.makePlaylistAction);
		}
		const smallDevice = this.props.breakpoints.xs || this.props.breakpoints.sm;

		const mainContent = (theme : ICustomThemeContext, openFileAction : (files: FileData[]) => void, afterOpeningFiles : () => void) => <span ref={this.boxRef}><FileBrowser
				i18n={chonkyI18N}
				darkMode={theme.currentTheme.mode === "dark"}
				files={ this.state.isLoading ? [ null ] : this.state.files}
				fileActions={fileActions}
				onFileAction={ (data : MapFileActionsToData<ChonkyActionUnion>) => { this.handleAction(data, openFileAction, afterOpeningFiles) }}
				folderChain={this.state.path}
				thumbnailGenerator={this.thumbnailGenerator}
				defaultFileViewActionId={ this.props.mode === "full" && !smallDevice ? ChonkyActions.EnableGridView.id : ChonkyActions.EnableListView.id}
				ref={this.dialogRef}
				>
			<FileToolbar />
            <FileNavbar />
			{ this.state.copiedPaths.length > 0 && (<>
			<div className="chonky-navbarCopyinfo">
            <div className="chonky-navbarContainer">
                <div className="chonky-toolbarLeft">
					<ContentCopyIcon style={{height: "22px", marginRight: "10px"}} />
                    {t('copied')}{ this.state.copiedPaths.length === 1? this.state.copiedPaths[0] : this.state.copiedPaths.length + t('copied-total') }
                </div>
				<div className="chonky-toolbarRight">
				<Button className="chonky-baseButton" onClick={ ()=>{ this.copyPaste(); } }>
					{t('paste-here')}
				</Button>
				<Button className="chonky-baseButton" onClick={ ()=>{this.setState({ copiedPaths : [] });} }>
					{t('paste-cancel')}
				</Button>
				</div>
            </div>
			</div>
			</>)}
            <FileList />
            <FileContextMenu />
        </FileBrowser></span>;
		
		/*if (this.state.highlightFile) {		
			this.state.highlightedElement.classList.add("search-highlight-file");
		}*/
		
		//this.highlightRefresh();
		
	
		return (<CustomThemeContext.Consumer>{(theme: ICustomThemeContext) => (<Fragment>
		
		{ this.state.redirectingTo && <Navigate to={this.state.redirectingTo} /> }
		
		{ /* Main file browser in case of big component */ }
		{ this.props.mode === "full" && mainContent(theme, (x: FileData[]) => { 
			for (let i = 0; i < x.length; i++) {
				//window.open(x[i].url, '_blank')!.focus();
				console.log(x);
				this.openFile(x[i].name, i, x.length, x[i].url)
			}}, () => {}) }
				
		{ /* Smaller file browser for dialog in case of file selection component */ }
		<Modal isOpen={this.state.inputDialogOpened} width={{ xs: '90vw', sm: '70vw' }} height='90vh'
			title={
				((!this.props.output || this.props.output === "singleFile") && t("choose-file")) ||
				( this.props.output === "folder" && t("choose-folder")) ||
				( this.props.output === "fileOrFolder" && t("choose-item")) ||
				t("choose-files")
			}
			onClose={()=>{this.setState({ inputDialogOpened : false })}}>{ 
			<div className="file-explorer-dialog">
				{ mainContent(theme, this.inputAddFile, this.dialogOk ) }

				<div className="dialog-buttons">
					<Button className="crud-button" variant="contained" onClick={() => {
						if (this.props.output !== "folder" && this.props.output !== "fileOrFolder") {
							this.inputAddFile(this.state.selectedFiles.filter(x => !x.isDir ));
							this.dialogOk();
						} else { this.dialogFolderOk(false); }
						}
					}>
						{ ((this.props.output === "folder" || this.props.output === "fileOrFolder") && t("confirm-folder")) || t("confirm") }
					</Button>
					
					{ ((this.props.output === "folder" && (this.state.selectedFiles.filter(x => x.isDir ).length === 1)) ||
						(this.props.output === "fileOrFolder" && this.state.selectedFiles.length === 1)) &&
					<Button className="crud-button" variant="contained" onClick={() => {
						this.dialogFolderOk(true);
					}}>
						{ (this.state.selectedFiles.filter(x => x.isDir ).length === 1) && (t("confirm-folder-selected") || t("confirm-file-selected")) }
					</Button>}
				</div>
			</div>
		}</Modal>
		
		{
			this.props.mode === "input" && this.props.value && this.arrayize(this.props.value).map((file, i) =>
				(<div key={file} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
					<IconButton aria-label="remove file" onClick={ ()=>{ this.inputRemoveFile(file); } }>
						<RemoveCircleOutlineIcon />
					</IconButton >
					{((!this.props.output || this.props.output === "singleFile" || this.props.output === "folder" || this.props.output === "fileOrFolder") && 
						<Button sx={{pt: 0, pb: 0, textTransform: 'none', wordBreak: 'break-word'}} onClick={ ()=>{this.setState({ inputDialogOpened : true, selectedFiles : [] })} }>{file}</Button>
					) || 
						file
					}
				</div>)
			)
			
		}{
		
			this.props.mode === "input" && (this.state.value.length === 0 || this.props.output === "multipleFiles") && (
				<div style={{textAlign:"center"}}>
				<Button startIcon={<AddIcon />} onClick={ ()=>{this.setState({ inputDialogOpened : true, selectedFiles : [] })} }>
					{	((!this.props.output || this.props.output === "singleFile") && t("add-file")) ||
						(this.props.output === "folder" && t("add-folder")) ||
						(this.props.output === "fileOrFolder" && t("add-item")) ||
						t("add-files") }
				</Button>
				</div>
			)
		}{
			this.props.mode === 'input' &&  this.state.value.length === 1 && this.props.output !== 'folder' && (
				<div className='checker-board' style={{ display: 'flex', maxWidth: '100%', maxHeight: '100%' }}>
					
					{isImage(this.state.value[0]) && <img src={BE_ROOT + this.state.value[0]} alt='preview'
						style={{ width: '100%', maxHeight: '150px' }} />}
					
					{isVideo(this.state.value[0]) && <video controls preload='false'
						src={BE_ROOT + this.state.value[0]} style={{ width: '100%', maxHeight: '150px' }}>
					</video>}
				</div>
			)
		}
		
		{ /* Other modal windows */ }
		
		<Modal title={ t("upload-items") } isOpen={this.state.showingUpload} onClose={()=>{ handleSubmit([], []); } }>		
			<div className="modal-file-upload"><Dropzone
			getUploadParams={getUploadParams}
			onSubmit={handleSubmit}
			onChangeStatus={handleChangeStatus}
			submitButtonContent = {t("upload-done")}
			inputWithFilesContent = {t("upload-more")}
			inputContent = {t("upload-input")}
			/></div>
			
		</Modal>
		
		
		<Modal title={ t("new-folder") } isOpen={this.state.creatingFolder} onClose={()=>{ this.setState({creatingFolder : false});}}>
			<div key="new-folder" className="field-container">
				<TextField inputRef={this.newFolderFieldRef} value={this.state.newFolderName} id="files-new-folder-name" label={t("new-folders-name")} variant="outlined" onChange={(e) => {this.setState({newFolderName : e.target.value})}} />
			</div>
			<CRUDButton variant="contained" role="create" onClick={()=>{ this.createFolder(this.state.newFolderName); }} />
		</Modal>
		
		<Modal title={ t("new-file") } isOpen={this.state.creatingFile} onClose={()=>{ this.setState({creatingFile : false});}}>
			<div key="new-file" className="field-container">
				<TextField inputRef={this.newFileFieldRef} value={this.state.newFileName} id="files-new-file-name" label={t("new-file-name")} variant="outlined" onChange={(e) => {this.setState({newFileName : e.target.value})}} />
				<Select style={{width: "80px"}} value={this.state.newFileExtension}
					onChange={(e)=>{this.setState({newFileExtension : e.target.value})}}>
					{ this.props.allowCreate && this.props.allowCreate.length && this.props.allowCreate!.map(x => <MenuItem key={x}
						value={x}>{x}
					</MenuItem>)}
				</Select>
			</div>
			<CRUDButton variant="contained" role="create" onClick={()=>{ this.createFile(this.state.newFileName + this.state.newFileExtension); }} />
		</Modal>
		
		<Modal title={ t("rename") + " (" + this.state.renameOldName + ")"}
			isOpen={this.state.renamingItem} onClose={()=>{ this.setState({renamingItem : false});}}>		
			<div key="rename-item" className="field-container">
				<TextField inputRef={this.renameFieldRef} value={this.state.renameName} id="files-new-rename-name" label={t("new-rename-name")}
					variant="outlined" fullWidth onChange={(e) => {this.setState({renameName : e.target.value})}} />
			</div>
			<CRUDButton variant="contained" role="update" onClick={()=>{ this.renameItem(this.state.renameOldName, this.state.renameName); }} />
		</Modal>
		
		<Modal title={ t("delete-items") + ` (${this.state.forDeletion.length})` }
			isOpen={this.state.deletingFiles} onClose={()=>{ this.setState({deletingFiles : false});}}>
			<p>{t("delete-are-you-sure")}</p>
			<CRUDButton variant="contained" role="delete" onClick={()=>{ this.deleteItems(this.state.forDeletion); }} />
		</Modal>
		
		<Modal title={ this.state.realTimeEditingText || "" } width={{ xs: '90vw', md: '70vw' }} height={{ xs: '90vh', md: '80vh' }}
			isOpen={this.state.realTimeEditingText !== null} onClose={()=>{
				this.setState({ realTimeEditingText : null })
			}}>			
			<FileEditor directory={this.props.folder} path={this.getPathString() + "/" +this.state.realTimeEditingText} readonly={this.props.readonly}
				onSubmit={() => this.setState({ realTimeEditingText: null })} />		
		</Modal>
		
		<Modal title={ t("making-playlist") + " (" + this.state.filesForPlaylist.length + ")"}
			isOpen={this.state.makingPlaylist} onClose={()=>{ this.setState({makingPlaylist : false});}}>		
			<div key="make-playlist-item" className="field-container">
				<FormControl sx={{ width: '100%' }}>
					<FormControlLabel value="new" disabled={!canCreatePlaylist} control={<Radio checked={this.state.playlistIsNew}
						onChange={(e)=>{ this.setState({playlistIsNew : e.target.value === 'new'}) }}/>} label={t('radio-new-playlist')} />
					<TextField disabled={!this.state.playlistIsNew} inputRef={this.playlistNameRef} value={this.state.newPlaylistName}
						id="new-playlist-name" label={t("new-playlist-name")} variant="outlined" fullWidth onChange={(e) => {
							this.setState({newPlaylistName : e.target.value});
						}}
					/>
					<FormControlLabel value="existing" control={<Radio checked={!this.state.playlistIsNew}
						onChange={(e)=>{ this.setState({playlistIsNew : e.target.value === 'new'}) }} />} label={t('radio-existing-playlist')} />
					<Select disabled={this.state.playlistIsNew} value={this.state.selectedPlaylist} fullWidth onChange={(e)=>{
							this.setState({selectedPlaylist : e.target.value});
					}}>
						{
							this.state.existingPlaylists.map(pl => <MenuItem key={pl} value={pl}>{pl}</MenuItem> )
						}
					</Select>
				</FormControl>
			</div>
			<CRUDButton variant="contained" role={this.state.playlistIsNew?"create":"update"} onClick={()=>{ this.makePlaylist(); }} />
		</Modal>
		
		</Fragment>)}</CustomThemeContext.Consumer>);
	}
}

export default hoistStatics(withTranslation()(withBreakpoints(FileExplorer)), FileExplorer)