import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import GridViewIcon from '@mui/icons-material/GridView';
import hoistStatics from "hoist-non-react-statics";
import { Box, Theme, Typography } from "@mui/material";
import DataTable, { Attribute } from "../../system/DataTable";
import PlaylistService from "./Boards.service";
import IModule, { ModuleState } from "../../system/IModule"
import { setPageTitle } from '../../App'
import { controllerKeys, hasController, isAuthorized, IUserContext, permissions } from '../../system/User.model'
import { IEntry, ISearchResult } from "../../system/SearchResult.model";
import { IBoard } from "./Boards.model";
import BoardsService from "./Boards.service";
import { CRUDButton } from "../../system/CRUDButton";
import { AuthContext } from "../../system/Base";
import { Modal } from "../../system/Modal";
import { CRUDForm } from "../../system/CRUDForm";
import { CRUDOperation } from "../../system/CRUDOperation";
import BoardChange from "./BoardChange";

interface IState {
	currentEntity: IBoard | null
	createShown: boolean,
    updateShown: boolean,
    deleteShown: boolean,
}
interface IProps extends WithTranslation { }

interface IBoardExt extends IBoard {
	actions: any // TODO
}

@IModule
class Boards extends React.Component<IProps, IState, WithTranslation> {

	dataTableRef: React.RefObject<DataTable<IBoard>>;
	boardService = BoardsService;

	constructor(props: IProps) {		
		super(props);
		this.state = {
			currentEntity: null,
			createShown: false,
			updateShown: false,
			deleteShown: false
		};

		this.triggerRefresh = this.triggerRefresh.bind(this);
		this.showCreate = this.showCreate.bind(this);
        this.showUpdate = this.showUpdate.bind(this);
        this.showDelete = this.showDelete.bind(this);

		this.colorView = this.colorView.bind(this);

		this.dataTableRef = React.createRef<DataTable<IBoard>>();
	} 
	
	public static getLocale() { return "module.boards"; }
	
	componentDidMount()
	{
		setPageTitle(this.props.t( Boards.getLocale()+".module-title", { ns: Boards.getLocale() }));
	}
	
	public static menu(t: ((x:string, y:any)=>string)) {
		return {
			title: (t && t(Boards.getLocale()+".module-title", {ns:Boards.getLocale()})) || "???",
			route : "/boards",
			icon : <GridViewIcon />,
			weight : 80
		};
	}
	
	public static async search(input : string) {
		const res = await PlaylistService.search(input);		
		return res.map((r: IEntry<IBoard>) : ISearchResult => { return {
			title : r.entity.title,
			description : '', // there's not much else to use for description...
			icon : <GridViewIcon />,
			link : "/boards?search-result-index=" + r.index
		}});
	}
	
	public static isEnabled (auth: IUserContext) {
		if (!hasController(auth, controllerKeys.board))
			return ModuleState.DISABLED;
		if (isAuthorized(auth, permissions.board.read))
			return ModuleState.ENABLED;
			
		return ModuleState.NO_PERMISSIONS;
	}

	async triggerRefresh() {
		await this.dataTableRef.current?.refresh();
	}

    async showCreate(entity: IBoard | null) {
        this.setState(() => ({
            currentEntity: entity,
            createShown: Boolean(entity)
        }),
        async () => {
            if (!entity) await this.triggerRefresh();
        });
    }

    async showUpdate(entity: IBoard | null) {
        this.setState(() => ({
            currentEntity: entity,
            updateShown: Boolean(entity)
        }), 
        async () => {
            if (!entity) await this.triggerRefresh();
        });
    }

    async showDelete(entity: IBoard | null) {
		if (!entity) await this.triggerRefresh();

        this.setState(() => ({
            currentEntity: entity,
            deleteShown: Boolean(entity)
        }));
    }
	
	colorView(color: string): JSX.Element {
		return <Box sx={(theme: Theme) => ({ color: theme.palette.getContrastText(color), backgroundColor: color, p: '.3rem', borderRadius: '4px'})}>
			{color}
		</Box>;
	}

	render() {
		const t = this.props.t;
		const ns = Boards.getLocale();
		const modalWidth = { xs: 'min(90vw, 360px)', md: 'auto' };
		const modalHeight = '90vh';

		const CrudActions = (b: IBoardExt) => <AuthContext.Consumer>
			{auth => <>
				<CRUDButton variant="outlined" role="update" disabled={!isAuthorized(auth, permissions.board.update) || !isAuthorized(auth, permissions.media.read)}
					onClick={() => this.showUpdate(b)} />
				<CRUDButton variant="outlined" role="delete" disabled={!isAuthorized(auth, permissions.board.delete)}
					onClick={() => this.showDelete(b)} />
			</>}
		</AuthContext.Consumer>;

		return (<>
			<Typography variant="h2">
				{ t(Boards.getLocale()+".module-title", {ns:Boards.getLocale()}) }
			</Typography>
			
			<AuthContext.Consumer>{(auth) =>
				<CRUDButton role="create" variant="contained" disabled={!isAuthorized(auth, permissions.board.create)}
					onClick={() => this.showCreate(this.boardService.createDefaultEntity())} />
			}
			</AuthContext.Consumer>
			<DataTable ref={this.dataTableRef} service={this.boardService} entityName={t(`${ns}.entity`, {ns: ns})} entityType='boards' 
				permissions={permissions.board} create={false} edit={false} delete={false}>
				<Attribute name="id" type="number" labelKey="id" namespace={ns} hideInCreateForm hideInUpdateForm hideInTable />
				<Attribute name="title" labelKey="title" namespace={ns} />
				<Attribute name="image" labelKey="image" namespace={ns} />
				<Attribute name="color" labelKey="color" namespace={ns}
					tableValueResolver={(entity) => this.colorView(entity.color)} />
				<Attribute name="actions" labelKey="actions" namespace={ns} sortable={false} tableValueResolver={CrudActions} />
			</DataTable>

			{this.state.createShown && this.state.currentEntity && this.dataTableRef.current &&
                <Modal title={this.dataTableRef.current.getCreateFormConfig().title} isOpen={this.state.createShown}
					width={modalWidth} height={modalHeight} onClose={async () => await this.showCreate(null)}>
                    
					<BoardChange entity={this.state.currentEntity} role='create' onSubmit={async (board) => {
						await this.dataTableRef.current?.getCreateFormConfig().onSubmit(board);
						await this.showCreate(null);
					}} />
                </Modal>}

			{this.state.updateShown && this.state.currentEntity && this.dataTableRef.current &&
                <Modal title={this.dataTableRef.current.getUpdateFormConfig().title + ' ' + this.state.currentEntity.title}
					isOpen={this.state.updateShown} width={modalWidth} height={modalHeight} onClose={async () => await this.showUpdate(null)}>
                    
					<BoardChange entity={this.state.currentEntity} role='update' onSubmit={async (board) => {
						await this.dataTableRef.current?.getUpdateFormConfig().onSubmit(board);
						await this.showUpdate(null);
					}} />
                </Modal>}

			{this.state.deleteShown && this.state.currentEntity && this.dataTableRef.current &&
                <Modal title={this.dataTableRef.current.getDeleteFormConfig().title + ' ' + this.state.currentEntity.title}
					isOpen={this.state.deleteShown} onClose={() => this.showDelete(null)}>
                    
					<CRUDForm {...this.dataTableRef.current?.getDeleteFormConfig()}
                        entity={this.state.currentEntity}
                        role={CRUDOperation.Delete}
						attributes={[]}
						onSubmit={async (board) => {
							await this.dataTableRef.current?.getDeleteFormConfig().onSubmit(board);
							await this.showDelete(null);
						}} />
                </Modal>}
			</>);
    }
}

export default hoistStatics(withTranslation()(Boards), Boards)