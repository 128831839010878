import { OptionsObject, SnackbarKey, useSnackbar, WithSnackbarProps } from "notistack"
import React from "react"
import { Fragment } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from "@mui/material";

// Must be imported at least once in the app to initialize the ref
let snackbarRef: WithSnackbarProps
export const SnackbarUtilsConfigurator: React.FC = () => {
  snackbarRef = useSnackbar()
  return null
}

const snackNotifications = {
  success(msg: string, options: OptionsObject = {}): void {
    this.toast(msg, { ...options, variant: "success", 
            autoHideDuration: 10000,
            action: (key) => (
                <Fragment>				
                    <IconButton className="notify-close-button" onClick={() => snackbarRef.closeSnackbar(key)}>
                        <CloseIcon />
                    </IconButton>
                </Fragment>
            ) })
  },
  warning(msg: string, options: OptionsObject = {}): void {
    this.toast(msg, { ...options, variant: "warning",
            autoHideDuration: 10000,
            action: (key) => (
                <Fragment>				
                    <IconButton className="notify-close-button" onClick={() => snackbarRef.closeSnackbar(key)}>
                        <CloseIcon />
                    </IconButton>
                </Fragment>
            ) })
  },
  info(msg: string, options: OptionsObject = {}): void {
    this.toast(msg, { ...options, variant: "info",
            autoHideDuration: 10000,
            action: (key) => (
                <Fragment>				
                    <IconButton className="notify-close-button" onClick={() => snackbarRef.closeSnackbar(key)}>
                        <CloseIcon />
                    </IconButton>
                </Fragment>
            ) })
  },
  error(msg: string, options: OptionsObject = {}): void {
    this.toast(msg, { ...options, variant: "error",
            autoHideDuration: 10000,
            action: (key) => (
            <Fragment>				
                <IconButton className="notify-close-button" onClick={() => snackbarRef.closeSnackbar(key)}>
                    <CloseIcon />
                </IconButton>
            </Fragment>
        )});
  },
  toast(msg: string, options: OptionsObject = {}): SnackbarKey {
    return snackbarRef.enqueueSnackbar(msg, options)
  },
}
// Export no-named default so consumer can name as desired/required
export default snackNotifications